import { EProducerService } from "../../api/eProducerService";
import { TagService } from "../../api/tagService";
import { EProducerAccount } from "../../api/types/model/eProducerAccount";
import { SearchType } from "../../api/types/searchType";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import CustomerListItem from "../../states/agentSpecific/customers/customerListItem";
import CustomerTableQueryOptions from "../../states/agentSpecific/customers/customerTableQueryOptions";
import { SearchControl } from "../../utilities/searchControl";
import { QueryBuilderTable } from "../../utilities/tables/queryBuilderTable";
import { CustomerSearchResult } from "../customerSearchDropdown/customerSearchResult";

class CustomerListFilterDropdownController {
    
    public static $inject = [
        Injectables.TagService,
        Injectables.EProducerService
    ];

    constructor(
            private readonly tagService: TagService,
            private readonly eProducerService: EProducerService
    ) {
        this.modifiedFilters = {} as CustomerTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount: number = 0;
    public modifiedFilters: CustomerTableQueryOptions;
    public tagOptions: SelectOption<string>[];
    public accountSearchResults: EProducerAccount[];
    public selectedBroker: EProducerAccount;
    public parentCustomerSearchControl: SearchControl<CustomerSearchResult>;
    
    public table: QueryBuilderTable<CustomerListItem>;

    public setSearchType = (searchType: SearchType) => {
        this.modifiedFilters.searchType = searchType;
    }

    public clearFilters() {
        this.selectedBroker = null;
        this.parentCustomerSearchControl.clear();
        
        this.table.clearFilters();
        
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.setModifiedFilters();
    }

    public applyFilters() {
        this.isOpen = false;

        if (this.selectedBroker) {
            this.modifiedFilters.basicFilters.broker = this.selectedBroker;
        } else { 
            this.modifiedFilters.basicFilters.broker = null;
        }

        if (this.parentCustomerSearchControl.selected) {
            this.modifiedFilters.basicFilters.parentCustomer = this.parentCustomerSearchControl.selected;
        } else {
            this.modifiedFilters.basicFilters.parentCustomer = null;
        }

        this.table.applyFilters(this.modifiedFilters.searchType, this.modifiedFilters.basicFilters, this.modifiedFilters.conditionSet);
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = this.table.getFilterCount();
    }

    private async loadTagOptions() {
        this.tagOptions = await this.tagService.loadTagOptions();
    }

    public searchBrokers(searchPhrase: string) {
        this.eProducerService.searchBrokers(searchPhrase)
            .then((results) => {
                this.accountSearchResults = results;
            });
    } 

    public clearBrokerselection() {
        this.modifiedFilters.basicFilters.brokerId = null;
        this.selectedBroker = null;

        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters = this.table.getQueryOptions();

        if (this.modifiedFilters.basicFilters.broker?.id) {
            this.selectedBroker = this.modifiedFilters.basicFilters.broker;
        } else {
            this.selectedBroker = null;
        }

        if (this.parentCustomerSearchControl) {
            if (this.modifiedFilters.basicFilters.parentCustomer?.id) {
                this.parentCustomerSearchControl.selected = this.modifiedFilters.basicFilters.parentCustomer;
            } else {
                this.parentCustomerSearchControl.selected = null;
            }
        }

        this.setFilterCount();
    }

    public $onInit() {
        this.setModifiedFilters();
        this.table.onFilterChange = this.setModifiedFilters;
        this.loadTagOptions();
    }
}

const customerListFilterDropdownComponent = {
    bindings: {
        table: '<'
    },
    controller: CustomerListFilterDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/customerListFilterDropdown/customerListFilterDropdown.html'
};

app.component('customerListFilterDropdown', customerListFilterDropdownComponent);