import { PaymentMethod } from "../../model/paymentTransaction";
import { PaymentStatus } from "../paymentStatus";
import { BillingEntryPaymentAssignment } from "./billingEntryPaymentAssignment";
import { OpenBillingEntries } from "./openBillingEntries";
import { PaymentToPaymentDisplayListItem } from "./paymentToPaymentDisplayListItem";
 

export interface PaymentForReassignment {
    paymentTransactionId: number;
    customerId?: number;
    customerLookupCode: string;
    customerName: string;
    paymentDescription: string;
    paymentStatus: PaymentStatus;
    paymentMethod: PaymentMethod;
    amount: number;
    comments: string;
    createdDateTime: Date;
    name: string;
    phone: string;
    email: string;
    message: string;
    transactionId: string;
    brokerLookupCode: string;
    brokerName: string;
    eProducerAccountId: number;
    openBillingEntries: OpenBillingEntries[];
    billingEntryPaymentAssignments: BillingEntryPaymentAssignment[];
    openPaymentEntries: PaymentToPaymentDisplayListItem[];
    paymentTransactionAssignments: PaymentToPaymentDisplayListItem[];
    companyName: string;
}