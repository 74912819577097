import { IWindowService } from "angular";
import { AuthDataLocalStorage } from "../../api/types/authDataLocalStorage";
import { RedirectDataLocalStorage } from "../../api/types/redirectDataLocalStorage";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { DocumentLibrarySettings } from "../../states/common/documentLibrary/DocumentLibrarySettings";

export class LocalStorageService {

    public static $inject = [
        Injectables.$window
    ];

    private readonly AUTHENTICATION_DATA_KEY: string = 'a3AuthorizationData';
    private readonly DOCUMENT_LIBRARY_SETTINGS_KEY: string = 'documentLibrarySettings';
    private readonly REDIRECT_KEY: string = 'a3RedirectData';
    private readonly CACHE_KEY: string = 'a3Cache_';

    constructor (
        private readonly $window: IWindowService
    ) {}

    private getData = (key: string) => {
        const dataString = this.$window.localStorage.getItem(key);

        if (!dataString) {
            return null;
        }

        const item = JSON.parse(dataString);

        if (item.expires) {
            const now = new Date();
            
            if (now.getTime() > item.expires) {
                this.removeData(key);
                return null;
            }
        }
        
        return item?.value;
    }

    private setData = (key: string, obj: object, timeToLive: number = null) => {
        const item = {
            value: obj,
            expires: null
        };

        if (timeToLive) {
            const now = new Date();
            item.expires = now.getTime() + timeToLive;
        }

        const dataString = JSON.stringify(item);

        this.$window.localStorage.setItem(key, dataString);
    }

    private removeData = (key: string) => {
        try {
            this.$window.localStorage.removeItem(key);
        } catch (err) {
            console.log(err);
        }
    }

    public removeAuthenticationData = () => {
        this.removeData(this.AUTHENTICATION_DATA_KEY);
    }

    public setAuthenticationData = (authenticationData: AuthDataLocalStorage) => {
        this.setData(this.AUTHENTICATION_DATA_KEY, authenticationData);
    }

    public getAuthenticationData = (): AuthDataLocalStorage => {
        return this.getData(this.AUTHENTICATION_DATA_KEY);
    }

    public removeDocumentLibrarySettings = () => {
        this.removeData(this.DOCUMENT_LIBRARY_SETTINGS_KEY);
    }

    public setDocumentLibrarySettings = (documentLibrarySettings: DocumentLibrarySettings) => {
        this.setData(this.DOCUMENT_LIBRARY_SETTINGS_KEY, documentLibrarySettings);
    }

    public getDocumentLibrarySettings = (): DocumentLibrarySettings => {
        return this.getData(this.DOCUMENT_LIBRARY_SETTINGS_KEY);
    }

    public setA3RedirectData = (a3RedirectData: RedirectDataLocalStorage) => {
        this.setData(this.REDIRECT_KEY, a3RedirectData);
    }

    public getA3RedirectData = (): RedirectDataLocalStorage => {
        return this.getData(this.REDIRECT_KEY);
    }

    public removeA3RedirectData = () => {
        this.removeData(this.REDIRECT_KEY)
    }

    public setCachedData = (url: string, obj: Object, timeToLive: number = null) => {
        this.setData(this.CACHE_KEY + url, obj, timeToLive);
    }

    public getCachedData = (url: string) => {
        return this.getData(this.CACHE_KEY + url);
    }

    public removeCachedData = (url: string) => {
        return this.removeData(this.CACHE_KEY + url);
    }

    public getAllCacheData = () => {
        let cacheData = [];
        const keys = Object.keys(this.$window.localStorage);

        for (let i = 0; i < keys.length; i++) {
            if (keys[i].includes(this.CACHE_KEY)) {
                const item = this.getData(keys[i]);
                
                if (item) {
                    cacheData.push({
                        key: keys[i].replace(this.CACHE_KEY, ''),
                        data: item
                    });
                }
            }            
        }

        return cacheData;
    }
}

app.service(Injectables.LocalStorageService, LocalStorageService);
