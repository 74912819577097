import { CustomersService } from "../../api/customerService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { EpicCustomerConnectionModalOptions } from "./epicCustomerConnectionModalOptions";
import { EpicCustomerConnectionModalResult } from "./EpicCustomerConnectionModalResult";
import app from "../../main";
import { IPromise } from "angular";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class EpicCustomerConnectionModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.CustomersService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<EpicCustomerConnectionModalResult>,
        private readonly modalOptions: EpicCustomerConnectionModalOptions,
        private readonly customerService: CustomersService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
    }

    public isLoaded: boolean;
    public lookupCode: string;
    public lookupCodeSearch: string;
    public busyIndicator: BusyIndicator;
    public customerId: number;
    public noMatchesFound: boolean;
    
    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
    
    public processsEpicCustomerConnectionRequest()
    {
        if (!this.lookupCodeSearch) {
            return;
        }

        this.busyIndicator.promise = this.customerService.processEpicCustomerConnectionRequest(this.lookupCodeSearch, this.customerId)
            .then(() => {    
                this.$uibModalInstance.close({} as EpicCustomerConnectionModalResult);
                this.toastMessageCreator.createSuccessMessage('Customer connected with Epic client');    
            })
            .catch((error) => { 
                if ((error?.data?.errorType == "EpicCustomerLookupCodeInUse" || error?.data?.errorType == "EpicCustomerLookupCodeNotFound") && error?.data?.message) {
                    this.toastMessageCreator.createErrorMessage(error.data.message);
                } else {
                    this.toastMessageCreator.createErrorMessage("An error trying to find the Epic client record that matches the lookup code you provided");
                }
            });
    }

    public exportToEpic() {            
        this.busyIndicator.promise = this.customerService.exportToEpic(this.customerId)
            .then((lookupCode) => {
                this.$uibModalInstance.close({} as EpicCustomerConnectionModalResult);
                this.toastMessageCreator.createSuccessMessage('Customer record successfully exported to Epic');
            })
            .catch((err) => {
                if (typeof err.data == "string"){
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to export the customer to epic. Check the Epic integration logs for more details');
                } else {
                    this.toastMessageCreator.createErrorMessage(err.data.message);
                }
            });
    }

    public loadCustomer(): IPromise<void> {
        return this.customerService.getCustomersLookupCode(this.customerId)
            .then((lookupCode) => {
                this.lookupCode = lookupCode;
                this.isLoaded = true;
            });
    }

    public disconnectFromEpic() {
        this.busyIndicator.promise = this.customerService.disconnectFromEpic(this.customerId)
            .then(() => {
                this.$uibModalInstance.close({} as EpicCustomerConnectionModalResult);
                this.toastMessageCreator.createSuccessMessage('Customer has been disconnected from Epic');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to disconnect the customer from epic');
            });
    }

    public $onInit(): void {
        this.customerId = this.modalOptions.customerId;
        this.busyIndicator = {
            promise: this.loadCustomer()
        }
    }
}

app.controller('EpicCustomerConnectionModalController', EpicCustomerConnectionModalController);
