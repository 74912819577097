import app from "../../main";
import { QueryBuilderTable } from "../../utilities/tables/queryBuilderTable";
import DataView from "../dataViews/dataView";

class ViewDropdownMenuController {
    
    public static $inject = [];

    constructor() {}

    public table: QueryBuilderTable<any>;

    public shareButtonClicked ($event, view: DataView) {
        $event.stopPropagation();
        this.table.shareView(view)
    }

    public $onInit() {
    }
}

const component = {
    bindings: {
        table: '<'
    },
    controller: ViewDropdownMenuController,
    controllerAs: 'vm',
    templateUrl: 'app/components/viewDropdownMenu/viewDropdownMenu.html'
};

app.component('viewDropdownMenu', component);
