import { EpicIntegrationService } from "../../../api/epicIntegrationService";
import { UpdateSentToBillingTeamRequest } from "../../../api/types/updateSentToBillingTeamRequest";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { EpicBillingReportItem } from "./epicBillingReportItem";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";

export class ReportEpicBillingController {

    public static $inject = [
        Injectables.EpicIntegrationService,
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator
    ];

    public reportEntries: EpicBillingReportItem[];
    public busyIndicator: BusyIndicator;
    public allSelected: boolean;
    public startDate: Date;
    public endDate: Date;
    public reportHasRan: boolean;
    public onlyNotEnteredInEpic: boolean;
    public onlyUnsentItems: boolean;
    
    constructor(
        private readonly epicIntegrationService: EpicIntegrationService,
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }
    
    private get selectedEntries(): EpicBillingReportItem[] {
        return this.reportEntries.filter((reportEntry) => reportEntry.$selected === true);
    }       

    public selectItemsReadyToSend() {
        this.reportEntries
            .map((reportEntry) => reportEntry.$selected = !reportEntry.includedInEpicBillingReport && !this.isIncomplete(reportEntry));

        this.setAllSelected();
    }

    public showSendToBillingTeam() {
        const billingEntryIds: number[] = [];
        const bondTransactionIds: number[] = [];

        for(var i = 0; i < this.selectedEntries.length; i++) {
            if (this.selectedEntries[i].includedInEpicBillingReport == true) {
                continue;
            }

            if (this.selectedEntries[i].id) {
                billingEntryIds.push(this.selectedEntries[i].id);
            } else {
                bondTransactionIds.push(this.selectedEntries[i].bondTransactionId);
            }
        }
            
        if (!billingEntryIds.length && !bondTransactionIds.length) {
            if (this.selectedEntries.length) {
                this.toastMessageCreator.createErrorMessage('All selected entries have already been sent to the billing team');
            } else {
                this.toastMessageCreator.createErrorMessage('No entries are selected');
            }

            return;
        }
        
        const hasIncompleteEntries = !this.selectedEntries.every((selectedEntry) => !this.isIncomplete(selectedEntry));
        const hasDuplicateEntries = !this.selectedEntries.every((selectedEntry) => selectedEntry.includedInEpicBillingReport !== true);

        this.modalOpener.showSendBillingReportModal(billingEntryIds, bondTransactionIds, hasIncompleteEntries, hasDuplicateEntries)
            .result
            .then(() => this.runReport())
            .catch(() => {});
    }

    public toggleSentToBillingTeam() {
        const requests: UpdateSentToBillingTeamRequest[] = this.selectedEntries.map((reportEntry) => {
            return {
                billingEntryId: reportEntry.id,
                includedInEpicBillingReport: reportEntry.includedInEpicBillingReport !== true
            };
        });
        
        if (!requests.length) {
            this.toastMessageCreator.createErrorMessage('No entries are selected');
            return;
        }

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.epicIntegrationService.updateSentToBillingTeam(requests)
            .then(() => this.runReport());
    }

    public buildIncludedInEpicBillingReportTooltip(reportEntry: EpicBillingReportItem) {
        if (reportEntry.includedInEpicBillingReport) {
            return 'Sent to billing team';
        } else {
            return 'Not sent to billing team';
        }
    }

    public buildIncompleteTooltipTemplate(reportEntry: EpicBillingReportItem) {
        let tooltipTemplate = '';
        
        if (reportEntry.isIncomplete) {
            tooltipTemplate += 'Missing information';
        } 

        if (reportEntry.isIncomplete && !reportEntry.enteredInEpic) {
            tooltipTemplate += '<br/><br/>';
        }

        if (!reportEntry.enteredInEpic) {
            tooltipTemplate += 'Not entered into Epic';
        }
        
        return tooltipTemplate;
    }

    public isIncomplete(reportEntry: EpicBillingReportItem) {
        return reportEntry.isIncomplete || !reportEntry.enteredInEpic;
    }

    public selectionChanged() {
        this.setAllSelected();
    }

    private setAllSelected() {
        this.allSelected = this.reportEntries
            .every((reportEntry) => reportEntry.$selected);
    }

    public allSelectedChanged() {
        this.reportEntries
            .map((reportEntry) => reportEntry.$selected = this.allSelected)
    }

    public buildIncludeInEpicHelpToolTip() {
        return 'Indicates that the action has also <br/> been completed in Epic. (Auto-Saves)';
    }

    public enteredInEpicChanged(reportEntry: EpicBillingReportItem, index: number) {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.epicIntegrationService.enteredInEpicChanged(reportEntry.bondTransactionId, reportEntry.enteredInEpic)
            .then(() => {
                // automatically set EnteredInEpic for rows before and after selected row if it is the same bondTransactionId
                let currentIndex = index - 1;
                while(currentIndex >= 0) {
                    if (this.reportEntries[currentIndex].bondTransactionId !== reportEntry.bondTransactionId) {
                        break;
                    }

                    this.reportEntries[currentIndex].enteredInEpic = reportEntry.enteredInEpic;

                    currentIndex--;
                }

                currentIndex = index + 1;
                while(currentIndex < this.reportEntries.length) {
                    if (this.reportEntries[currentIndex].bondTransactionId !== reportEntry.bondTransactionId) {
                        break;
                    }

                    this.reportEntries[currentIndex].enteredInEpic = reportEntry.enteredInEpic;

                    currentIndex ++;
                }
            });
    }

    public downloadReport() {
        this.busyIndicator.message = 'Preparing Download...';

        const billingEntryIds: number[] = [];
        const bondTransactionIds: number[] = [];

        for(var i = 0; i < this.selectedEntries.length; i++) {
            if (this.selectedEntries[i].id) {
                billingEntryIds.push(this.selectedEntries[i].id);
            } else {
                bondTransactionIds.push(this.selectedEntries[i].bondTransactionId);
            }
        }
            
        if (!billingEntryIds.length && !bondTransactionIds.length) {
            this.toastMessageCreator.createErrorMessage('No entries are selected');
            return;
        }

       this.busyIndicator.promise = this.epicIntegrationService.downloadExcelBillingReport(billingEntryIds, bondTransactionIds);
    }

    public runReport() {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.epicIntegrationService.getBillingReportEntries(this.startDate, this.endDate, this.onlyNotEnteredInEpic, this.onlyUnsentItems)
            .then((reportEntries) => {
                this.reportEntries = reportEntries.items;
                this.reportHasRan = true;
                this.allSelected = false;
            })
            .catch((err) => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to run the report');
            });
    }

    public $onInit(): void {
        const now = new Date();
        this.busyIndicator = {};

        this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }
}