import app from "../../main";
import { JQueryProvider } from "../../utilities/jquery/jQueryProvider";
import { Injectables } from "../injectables";
import billToType from "./fieldGroupStrategies/billToType";
import bondAccountStatus from "./fieldGroupStrategies/bondAccountStatus";
import check from "./fieldGroupStrategies/check";
import commissionCalculationType from "./fieldGroupStrategies/commissionCalculationType";
import date from "./fieldGroupStrategies/date";
import dateString from "./fieldGroupStrategies/dateString";
import email from "./fieldGroupStrategies/email";
import fein from "./fieldGroupStrategies/fein";
import float from "./fieldGroupStrategies/float";
import floatSelect from "./fieldGroupStrategies/floatSelect";
import gender from "./fieldGroupStrategies/gender";
import groupSelect from "./fieldGroupStrategies/groupSelect";
import hours from "./fieldGroupStrategies/hours";
import integer from "./fieldGroupStrategies/integer";
import maritalStatus from "./fieldGroupStrategies/maritalStatus";
import money from "./fieldGroupStrategies/money";
import moneyNegative from "./fieldGroupStrategies/moneyNegative";
import moneyPositiveOrNegative from './fieldGroupStrategies/moneyPositiveOrNegative';
import password from "./fieldGroupStrategies/password";
import percent from "./fieldGroupStrategies/percent";
import phone from "./fieldGroupStrategies/phone";
import radio from "./fieldGroupStrategies/radio";
import residenceType from "./fieldGroupStrategies/residenceType";
import search from "./fieldGroupStrategies/search";
import select from "./fieldGroupStrategies/select";
import ssn from "./fieldGroupStrategies/ssn";
import state from "./fieldGroupStrategies/state";
import stateWithFederal from "./fieldGroupStrategies/stateWithFederal";
import styledCheck from "./fieldGroupStrategies/styledCheck";
import styledRadio from "./fieldGroupStrategies/styledRadio";
import text from "./fieldGroupStrategies/text";
import time from "./fieldGroupStrategies/time";
import typeAhead from "./fieldGroupStrategies/typeAhead";
import url from "./fieldGroupStrategies/url";
import zip from "./fieldGroupStrategies/zip";
import checkBoxLabel from "./labelStrategies/checkBoxLabel";
import defaultEProducerLabel from "./labelStrategies/defaultEProducerLabel";
import defaultLabel from "./labelStrategies/defaultLabel";
import inlineLabel from "./labelStrategies/inlineLabel";
import noLabel from "./labelStrategies/noLabel";
import placeholderOnlyLabel from "./labelStrategies/placeholderOnlyLabel";

class AngularAgilityProviderCustom {

    public static $inject = [
        Injectables.AngularAgilityFormsExtensionsProvider,
        Injectables.JQueryProvider
    ];

    constructor(
        private readonly aaFormExtensionsProvider: any,
        private readonly jQueryProvider: JQueryProvider
    ) {
        this.provider = {};
    }

    private provider: any;

    public $get = () => {
        return this.provider;
    }

    public initProvider = () => {
        this.aaFormExtensionsProvider.defaultOnNavigateAwayStrategy = "None";
        this.aaFormExtensionsProvider.defaultFieldGroupStrategy = "text";
        this.aaFormExtensionsProvider.defaultLabelStrategy = "defaultLabelStrategy";

        this.aaFormExtensionsProvider.labelStrategies.defaultEProducerLabelStrategy = defaultEProducerLabel;
        this.aaFormExtensionsProvider.labelStrategies.defaultLabelStrategy = (
            element: JQuery, 
            labelText: string, 
            isRequired: boolean
        ) => {
            return defaultLabel(element, labelText, isRequired, this.jQueryProvider.$get());
        }

        this.aaFormExtensionsProvider.labelStrategies.inline = inlineLabel;
        this.aaFormExtensionsProvider.labelStrategies.checkBoxLabel = checkBoxLabel;
        this.aaFormExtensionsProvider.labelStrategies.placeholderOnly = placeholderOnlyLabel;
        this.aaFormExtensionsProvider.labelStrategies.none = noLabel;
        
        this.aaFormExtensionsProvider.fieldGroupStrategies.floatSelect = (
            element: JQuery
        ) => floatSelect(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.typeAhead = typeAhead;
        
        this.aaFormExtensionsProvider.fieldGroupStrategies.billToType = (
            element: JQuery
        ) => billToType(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.commissionCalculationType = (
            element: JQuery
        ) => commissionCalculationType(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.bondAccountStatus = (
            element: JQuery
        ) => bondAccountStatus(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.maritalStatus = (
            element: JQuery
        ) => maritalStatus(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.hours = (
            element: JQuery
        ) => hours(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.residenceType = (
            element: JQuery
        ) => residenceType(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.integer = (
            element: JQuery
        ) => integer(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.float = (
            element: JQuery
        ) => float(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.money = (
            element: JQuery
        ) => money(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.moneyNegative = (
            element: JQuery
        ) => moneyNegative(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.moneyPositiveOrNegative = (
            element: JQuery
        ) => moneyPositiveOrNegative(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.ssn = (
            element: JQuery
        ) => ssn(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.fein = (
            element: JQuery
        ) => fein(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.password = (
            element: JQuery
        ) => password(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.zip = (
            element: JQuery
        ) => zip(element, this.jQueryProvider.$get());
        
        this.aaFormExtensionsProvider.fieldGroupStrategies.dateString = (
            element: JQuery
        ) => dateString(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.date = (
            element: JQuery
        ) => date(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.time = (
            element: JQuery
        ) => time(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.stateWithFederal = (
            element: JQuery
        ) => stateWithFederal(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.state = (
            element: JQuery
        ) => state(element, this.jQueryProvider.$get());        

        this.aaFormExtensionsProvider.fieldGroupStrategies.gender = (
            element: JQuery
        ) => gender(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.styledCheck = (
            element: JQuery
        ) => styledCheck(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.text = (
            element: JQuery
        ) => text(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.search = (
            element: JQuery
        ) => search(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.percent = (
            element: JQuery
        ) => percent(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.url = (
            element: JQuery
        ) => url(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.phone = (
            element: JQuery
        ) => phone(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.email = (
            element: JQuery
        ) => email(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.groupSelect = (
            element: JQuery
        ) => groupSelect(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.select = (
            element: JQuery
        ) => select(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.styledRadio = (
            element: JQuery
        ) => styledRadio(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.radio = (
            element: JQuery
        ) => radio(element, this.jQueryProvider.$get());

        this.aaFormExtensionsProvider.fieldGroupStrategies.check = (
            element: JQuery
        ) => check(element, this.jQueryProvider.$get());        
    }
}

app.provider('AngularAgilityProviderCustom', AngularAgilityProviderCustom);
