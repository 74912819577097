import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { UserService } from "../../api/userService";
import DataViewType from "../../components/dataViews/dataViewType";
import SharedDataViewOption from "../../components/dataViews/sharedDataViewOption";

export class SharedViewsModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.UserService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: SharedViewModalOptions,
        private readonly $uibModalInstance: Modal<SharedViewModalResult>,
        private readonly userService: UserService,
        private readonly toastMessageCreator: ToastMessageCreator
    ){}

    public busyIndicator: BusyIndicator = {};
    public views: SharedDataViewOption[];
    public isLoading: boolean = true;

    public copyView(view: SharedDataViewOption): void {
        this.busyIndicator.message = 'Copying...';
        this.busyIndicator.promise = this.userService.copySharedView(view.id)
            .then((results) => {
                this.$uibModalInstance.close({view: view} as SharedViewModalResult);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred creating a copy of this view');
            });
    }

    public loadViews(): void {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.userService.loadSharedViews(this.options.dataViewType)
            .then((views) => {
                this.views = views;
                this.isLoading = false;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying load views');
            })
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit() {
        this.loadViews();
    }
}

app.controller('SharedViewsModalController', SharedViewsModalController);

export interface SharedViewModalOptions {
    dataViewType: DataViewType;
}

export interface SharedViewModalResult {
    view: SharedDataViewOption;
}