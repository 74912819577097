import { TableQueryOptions } from "../../api/types/tableQuery";
import { TablePager } from "../../components/tablePager/tablePager";
import { TableSorter } from "../../components/tableSorter/tableSorter";
import { Injectables } from "../../configuration/injectables";
import { IDebounceDelayer } from "../debounceDelayer/iDebouceDelayer";
import { TableRow } from "./tableRow";

export class Table<T, K extends TableQueryOptions> {

    public static $inject = [
        Injectables.IDebouceDelayer
    ];
    
    constructor(
        private readonly debouceDelayer: IDebounceDelayer
    ) {
        this.sorter = new TableSorter();
        this.pager = new TablePager();
        
        this.sorter.onSortChanged = this.notifyOfChange;
        this.pager.onRecordsPerPageChanged = this.notifyOfChange;
        this.pager.onPageChange = this.notifyOfChange;

        this._queryOptions = {
            orderBy: this.sorter.sortBy,
            pageNumber: this.pager.currentPage,
            recordsPerPage: this.pager.recordsPerPage
        } as K;

        this._rows = [];
    }

    public readonly sorter: TableSorter;
    public readonly pager: TablePager;
    
    public onChange: () => void;
    
    private _allSelected: boolean;
    public get allSelected(): boolean {
        return this._allSelected;
    }
    public set allSelected(value: boolean) {
        this._allSelected = value;

        for(let i=0; i<this._rows.length; i++) {
            this._rows[i].isSelected = this._allSelected;

            if (this._rows[i].isSelectionDisabled) {
                this._rows[i].isSelected = false;
            }
        }
    }

    private _data : T[];
    public get data() : T[] {
        return this._data;
    }
    
    private _queryOptions : K;
    public get queryOptions() : K {
        return this._queryOptions;
    }

    private _rows: TableRow[];
    public get rows(): TableRow[] {
        return this._rows;
    }

    public refresh = () => {
        this.notifyOfChange();
    }

    public applyFilters = (filters: TableQueryOptions) => {
        for (const property of Object.getOwnPropertyNames(filters)) {
            this.queryOptions[property] = filters[property];
        }

        this.notifyOfChange();
    }

    public toggleIsExpanded = (index: number) => {
        this.rows[index].isExpanded = this.rows[index].isExpanded !== true;
    }

    public getSelectedRecords = (): T[] => {
        if (!this._data) {
            return [];
        }

        return this._data.filter((record, index) => this._rows[index].isSelected);
    }

    public setData(data: T[], totalRecordCount: number) {
        this._data = data;
        
        this._rows = this._data.map(() => { 
            return { 
                isSelected: false, 
                isExpanded: false,
                isSelectionDisabled: false
            };
        });

        this.pager.totalRecordCount = totalRecordCount;
    }

    public get isVisible(): boolean {
        return !!this._data && !!this._data.length;
    }

    public get isEmptyStateVisible(): boolean {
        return !this._data || !this._data.length;
    }

    public searchPhraseChanged = () => {
        this.debouceDelayer.delay(this.notifyOfChange, 400);
    }

    private notifyOfChange = () => {
        this._queryOptions.orderBy = this.sorter.sortBy;
        this._queryOptions.pageNumber = this.pager.currentPage;
        this._queryOptions.recordsPerPage = this.pager.recordsPerPage;

        if (this.onChange instanceof Function) {
            this.onChange();
        }
    }
}