import { CustomersService } from "../../../api/customerService";
import { SearchType } from "../../../api/types/searchType";
import { UserService } from "../../../api/userService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import DataView from "../../../components/dataViews/dataView";
import DataViewType from "../../../components/dataViews/dataViewType";
import TableColumn from "../../../components/dataViews/tableColumn";
import QueryBuilderLogicType from "../../../components/queryBuilder/queryBuilderLogicType";
import QueryConditionPropertyOption from "../../../components/queryBuilder/queryConditionPropertyOption";
import QueryConditionPropertyType from "../../../components/queryBuilder/queryConditionPropertyType";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import deepCopy from "../../../utilities/immutable/deepCopy";
import { QueryBuilderTable } from "../../../utilities/tables/queryBuilderTable";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import CustomerListItem from "./customerListItem";

export class CustomerGridController {
    
    public static $inject = [
        Injectables.ModalOpener,
        Injectables.CustomersService,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator,
        Injectables.UserService
    ];

    constructor(
        private readonly modalOpener: ModalOpener,
        private readonly customersService: CustomersService,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator,
        userService: UserService
    ) {
        const columnOptions: TableColumn<any>[] = [{
            name: 'Id',
            heading: 'Id',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: true,
            sortExpression: 'Customers.Id',
            index: 0
        },{
            name: 'Name',
            heading: 'Name',
            headingClass: '',
            cellClass: 'nowrap',
            enabled: true,
            sortExpression: 'Customers.Name',
            locked: true,
            index: 1
        },{
            name: 'LookupCode',
            heading: 'Lookup Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: true,
            sortExpression: 'Customers.LookupCode',
            index: 2
        },{
            name: 'Address',
            heading: 'Address',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            enabled: false,
            index: 3
        },{
            name: 'Balance',
            heading: 'Balance',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: false,
            sortExpression: 'Customers.Balance',
            index: 4
        },{
            name: 'CreatedDateTime',
            heading: 'Created Date Time',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: false,
            sortExpression: 'Customers.CreatedDateTime',
            index: 6
        },{
            name: 'BrokerName',
            heading: 'Broker',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            enabled: false,
            sortExpression: 'BrokerName',
            index: 7
        },{
            name: 'BrokerLookupCode',
            heading: 'Broker Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content nowrap',
            enabled: false,
            sortExpression: 'BrokerLookupCode',
            index: 8
        },{
            name: 'Producer',
            heading: 'Producer',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            enabled: false,
            sortExpression: 'Producer.FullName',
            index: 9
        },{
            name: 'ClientServiceExecutive',
            heading: 'CSE',
            tooltip: 'Client Service Executive',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            enabled: false,
            sortExpression: 'ClientServiceExecutive.FullName',
            index: 10
        },{
            name: 'ClientServiceManager',
            heading: 'CSM',
            tooltip: 'Client Service Manager',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            enabled: false,
            sortExpression: 'ClientServiceManager.FullName',
            index: 11
        },{
            name: 'ClientServiceAssociate',
            heading: 'CSA',
            tooltip: 'Client Service Associate',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            enabled: false,
            sortExpression: 'ClientServiceAssociate.FullName',
            index: 12
        },{
            name: 'UnappliedPaymentTotal',
            heading: 'Unapplied Payments',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: false,
            sortExpression: 'Customers.UnappliedPaymentTotal',
            index: 14
        },{
            name: 'DebitTotal',
            heading: 'Outstanding Debits',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: false,
            sortExpression: 'Customers.DebitTotal',
            index: 15
        },{
            name: 'CreditTotal',
            heading: 'Outstanding Credits',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: false,
            sortExpression: 'Customers.CreditTotal',
            index: 16
        },{
            name: 'NotInvoicedTotal',
            heading: 'Not Invoiced Total',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: false,
            sortExpression: 'Customers.NotInvoicedTotal',
            index: 17
        },{
            name: 'InvoicedBalance',
            heading: 'Invoiced Balance',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            enabled: false,
            sortExpression: 'Customers.InvoicedBalance',
            index: 18
        },];

        const queryConditionPropertyOptions: QueryConditionPropertyOption[] = [
            { label: 'Balance', value: 'Customers.Balance', type: QueryConditionPropertyType.Money },
            { label: 'Broker Lookup Code', value: 'Broker.LookupCode', type: QueryConditionPropertyType.String},
            { label: 'Broker Name', value: 'Broker.Name', type: QueryConditionPropertyType.String},
            { label: 'Unapplied Payments', value: 'Customers.UnappliedPaymentTotal', type: QueryConditionPropertyType.Money },
            { label: 'Created Date', value: 'Customers.CreatedDateTime', type: QueryConditionPropertyType.Date},
            { label: 'CSA', value: 'Customers.ClientServiceAgentUserId', type: QueryConditionPropertyType.ClientServiceAssociate},
            { label: 'CSE', value: 'Customers.ClientServiceExecutiveUserId', type: QueryConditionPropertyType.ClientServiceExecutive},
            { label: 'CSM', value: 'Customers.ClientServiceManagerUserId', type: QueryConditionPropertyType.ClientServiceManager},
            { label: 'Customer Name', value: 'Customers.Name', type: QueryConditionPropertyType.String},
            { label: 'Id', value: 'Customers.Id', type: QueryConditionPropertyType.Integer},
            { label: 'Is Active', value: 'Customers.IsActive', type: QueryConditionPropertyType.Boolean },
            { label: 'Lookup Code', value: 'Customers.LookupCode', type: QueryConditionPropertyType.String},
            { label: 'Producer', value: 'Customers.ProducerUserId', type: QueryConditionPropertyType.Producer},
            { label: 'Debit Total', value: 'Customers.DebitTotal', type: QueryConditionPropertyType.Money },
            { label: 'Credit Total', value: 'Customers.CreditTotal', type: QueryConditionPropertyType.Money },
            { label: 'Not Invoiced Total', value: 'Customers.NotInvoicedTotal', type: QueryConditionPropertyType.Money },
            { label: 'Invoiced Balance', value: 'Customers.InvoicedBalance', type: QueryConditionPropertyType.Money },
        ];

        const defaultViews: DataView[] = [{
            isDefault: true,
            name: 'Default View',
            dataViewType: DataViewType.Customers,
            recordsPerPage: 10,
            sortExpression: 'Customers.Name',
            columns: deepCopy(columnOptions),
            index: 0,
            conditionSet: {
                logicType: QueryBuilderLogicType.And,
                conditions: [],
                conditionSets: []
            },
            basicFilters: {
                isActive: true
            },
            searchType: SearchType.Basic
        }];

        this.table = new QueryBuilderTable(
            this.loadCustomers,
            debouceDelayer, 
            DataViewType.Customers, 
            queryConditionPropertyOptions, 
            columnOptions, 
            defaultViews,
            userService,
            toastMessageCreator,
            modalOpener
        );
    }

    public readonly table: QueryBuilderTable<CustomerListItem>;
    public busyIndicator: BusyIndicator = {};
    
    public exportCustomerList() {
        this.busyIndicator = {
            message: "Building Export...",
            promise: this.customersService.downloadCustomerEntriesExcelReport(this.table.getQueryOptions(), this.table.getSelectedColumnNames())
        };        
    }
    
    public showNewCustomerModal = () => {
        this.modalOpener.showNewCustomerModal()
            .result
            .then(function(){})
            .catch(function(){});
    }

    private loadCustomers = () => {
        return this.customersService.getCustomerListDataWithQueryBuilder(this.table.getQueryOptions())
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load customer information');
            });
    }
    
    public $onInit(): void {
        this.table.load();
    }
}