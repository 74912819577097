import { BaseEntity } from "./baseEntity";
import { BondType } from "./bondType";

export interface EProducerAccountFavoriteBondTypeConfiguration extends BaseEntity {
    favoriteBondTypeFilteringEnabled: boolean;
    favoriteBondTypes: EProducerAccountFavoriteBondTypes[];
}

export interface EProducerAccountFavoriteBondTypes {
    id?: number;
    bondTypeName: string;
    bondTypeId: number;
    eProducerAccountId: number;
}