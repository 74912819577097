import * as angular from "angular";
import "angular-sanitize";
import "@uirouter/angularjs/release/angular-ui-router.js";
import "angular-gridster/dist/angular-gridster.min.js";
import "angular-agility/dist/angular-agility.js";

/* Workaround to allow text angular to work using ES6 modules */
(window as any).rangy = require("rangy/lib/rangy-core");
require("rangy/lib/rangy-selectionsaverestore");
const angularSanitize = require("angular-sanitize");
require("textangular/dist/textAngular-sanitize.min");
(window as any).taTools = {};
require("textangular/dist/textAngularSetup");
require("textangular/dist/textAngular");
import "textangular/dist/textAngular.css";

import angularXeditable from "angular-xeditable";
import "angular-cache/dist/angular-cache.min.js";
import "ng-toast";
import "angular-ui-bootstrap";
import "./components/uiSelect/select.js";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "ui-cropper/compile/minified/ui-cropper.css";
import "ui-cropper/compile/minified/ui-cropper.js";

angular.module("a3TemplateCache", []);

const app = angular.module("app", [
    "a3TemplateCache",
    "ng",
    "ui.router",
    "ui.bootstrap",
    "ui.select",
    "ngToast",
    "gridster",
    "aa.notify",
    "aa.formExternalConfiguration",
    "aa.formExtensions",
    "textAngular",
    "angular-cache",
    angularXeditable,
    "uiCropper"
]);

/* 
    Upgrade Patches
    ----------------------------------
    The following are workaround solutions
    to allow for an easier transition to 
    newer versions of AngularJs.
*/

/* 
    1.6 defaults the hashPrefix with "!".
    The following clears that default to 
    ensure "!" is not included in the URL
*/
app.config([
    "$locationProvider",
    function ($locationProvider) {
        $locationProvider.hashPrefix("");
    }
]);

/*
    1.7 removes angular.lowercase
    We add it back to satisify the textAngular library
*/
app.config(function () {
    (angular as any)["lowercase"] = (angular as any).$$lowercase;
});

/*
    1.5 and legacy versions of UI router cause an error to be thrown for each state change.
    This mutes the error from being sent to the console in this case
*/
// app.config(['$provide', function($provide) {
//     $provide.decorator("$exceptionHandler", ["$delegate", function ($delegate) {
//         return function(exception, cause) {
//             if (!cause) {
//                 return; //do nothing (ignore) exception in this case === 'Possibly unhandled rejection: undefined'
//             }

//             $delegate(exception, cause);
//         };
//     }]);
// }]);

export default app;
