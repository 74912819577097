import { ApplicationService } from "../../../api/applicationService";
import { BondTypeService } from "../../../api/bondTypeService";
import { ObligeeService } from "../../../api/obligeeService";
import { ODataFactory, ODataEndpoint } from "../../../api/odata";
import { SystemAccountService } from "../../../api/systemAccountService";
import { A3WebApplicationForm } from "../../../api/types/a3WebApplicationForm";
import { UiApplication } from "../../../api/types/uiApplication";
import { CustomerSources } from "../../../api/types/customerSources";
import { ApplicationType, ApplicationStatus, PrincipalType, Application } from "../../../api/types/model/application";
import { ApplicationQuestionResponse } from "../../../api/types/model/applicationQuestionResponse";
import { BondType, BondAmountTypes } from "../../../api/types/model/bondType";
import { Company } from "../../../api/types/model/company";
import { Customer, CustomerType } from "../../../api/types/model/customer";
import { EProducerAccount, EProducerAccountType } from "../../../api/types/model/eProducerAccount";
import { Obligee } from "../../../api/types/model/obligee";
import { Person } from "../../../api/types/model/person";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { ApplicationTestDataCreator, TestApplication } from "./applicationTestDataCreator";
import A3ApiResponse from "../../../api/types/a3ApiResponse";
import * as angular from "angular";
import { IHttpService, IQService, IFilterService, IDeferred, IPromise, IFormController } from "angular";
import * as moment from "moment";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { BondTypeForApplication } from "../../../api/types/bondTypeForApplication";
import DropdownOption from "../../../api/types/dropdownOption";
import { EProducerService } from "../../../api/eProducerService";
import AngularAgilityNotify from "../../../configuration/angularAgility/angularAgilityNotify";
import { UserService } from "../../../api/userService";
import { BondTypeSelectionModalResult } from "../../../modals/bondTypeSelectionModal/bondTypeSelectionModal";

export class ApplicationController {

    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.ODataFactory,
        Injectables.CurrentUserResolver,
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.$q,
        Injectables.$filter,
        Injectables.ModalOpener,
        Injectables.SystemAccountService,
        Injectables.ApplicationService,
        Injectables.BondTypeService,
        Injectables.ApplicationTestDataCreator,
        Injectables.ObligeeService,
        Injectables.ToastMessageCreator,
        Injectables.EProducerService,
        Injectables.aaNotify,
        Injectables.UserService
    ];

    constructor(
        private readonly stateService: State,
        private readonly stateParams: ApplicationStateParams,
        private readonly odataService: ODataFactory,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly modalOpener: ModalOpener,
        private readonly systemAccountService: SystemAccountService,
        private readonly applicationService: ApplicationService,
        private readonly bondTypeService: BondTypeService,
        private readonly applicationTestDataCreator: ApplicationTestDataCreator,
        private readonly obligeeService: ObligeeService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly eProducerService: EProducerService,
        private readonly aaNotify: AngularAgilityNotify,
        private readonly userService: UserService
    ) { }
    
    public actionDropdownOptions: DropdownOption[];
    public formController: IFormController;
    public brokerSearchResults: EProducerAccount[];

    public isCompanyPrincipalType: boolean;
    public isProduction: boolean;
    public testApplicants: SelectOption<number>[];
    public testApplicant: TestApplication;
    public selectedTestApplicantId: number;

    public formOptions: A3WebApplicationForm;
    public application: UiApplication;
    public bondType: BondTypeForApplication;
    public bondTypeVariableBondAmounts: SelectOption[];

    public bondNumber: string;
    public goToCustomerProfileOnSave: boolean;

    public submitting: boolean;
    public formSubmitted: boolean;
    public saving: boolean;
    public uploadComplete: boolean;

    public loadingMaskDefer:IDeferred<void>;
    public busyIndicator: BusyIndicator;

    public saveDeferred:IDeferred<void>;

    public aifOptions: SelectOption[] = [];
    public nameOnBondOptions: SelectOption<string>[];

    public selectedNameOnBond: string;
    public submissionSignature: string;

    public accountRequestBondTypes:  BondType[] = [];

    public isEProducerBrokerReferral: boolean;
    public referringEProducerBrokerAccount:  EProducerAccount;

    public questionResponses:  ApplicationQuestionResponse[];

    public get isRenewal(): boolean {
        return this.application && this.application.applicationType ===  ApplicationType.SingleBondRenewal;
    }

    public $onInit(): void {

        if (!this.stateParams.bondTypeId && !this.stateParams.applicationId) {
            this.stateService.go('main.bondTypeSelection');
            return;
        }

        this.busyIndicator = {};
        this.questionResponses = [];
        this.testApplicants = this.applicationTestDataCreator.equifaxApplicantOptions;
        this.isProduction = this.systemSettings.environment === 'Prod';  

        if (this.stateParams.applicationId) {
            this.busyIndicator.message = "Loading Application...";
            this.busyIndicator.promise = this.loadApplication(this.stateParams.applicationId);
        } else {
            this.busyIndicator.message = "Preparing New Application..."
            this.busyIndicator.promise = this.initializeNewApplicationObject(
                this.stateParams.bondTypeId, 
                this.stateParams.customerId, 
                this.stateParams.appType,
                this.stateParams.eProducerAccountId
            );
        }
    }

    public initializeActionsDropdown = () => {
        this.actionDropdownOptions = [
            {
                label: 'Customer Detail',
                onClick: () => { this.returnToCustomerDetail() },
                isHidden: !this.application.customer,
            },
            {
                label: 'Email Application',
                onClick: () => { this.showEmailAccessModal() }
            },
            {
                label: 'Load Existing Customer',
                onClick: () => { this.showLoadCustomerModal() },
                isHidden: !!this.application.customer,
            },
        ];
    }

    public initializeNewApplicationObject(
        bondTypeId:  number, 
        customerId: number, 
        appType:  ApplicationType, 
        eProducerAccountId: number) 
    {
        this.application = new UiApplication();
        this.application.eProducerAccountId = eProducerAccountId;
        
        this.application.obligee = {
            name: '',
            isGeneric: true
        } as Obligee;

        if (this.application.eProducerAccountId) {
            this.isEProducerBrokerReferral = true;
        }

        if (this.isEProducerBrokerReferral && 
            this.application.eProducerAccount && 
            this.application.eProducerAccount.eProducerAccountType === EProducerAccountType.Broker) {

            this.referringEProducerBrokerAccount = this.application.eProducerAccount;
            delete this.application.eProducerAccount;
        }

        if (customerId) {
            this.application.customerId = customerId;
        }

        return this.loadBondType(bondTypeId)
            .then(() => {

                const promises: IPromise<void>[] = [];

                switch (appType) {
                    case  ApplicationType.BondAccount:
                        this.application.applicationType =  ApplicationType.BondAccount;
                        break;
                    case  ApplicationType.SingleBondRenewal:
                        this.application.applicationType =  ApplicationType.SingleBondRenewal;
                        this.application.bondId = this.stateParams.bondId;
                        break;
                    default:
                        this.application.applicationType =  ApplicationType.SingleBond;
                }
        
                if (!this.stateParams.applicationId && this.application.customerId) {
                    promises.push(this.loadCustomer());
                }
        
                if (!this.stateParams.applicationId && this.application.eProducerAccountId) {
                    promises.push(this.loadEProducerAccount());
                }

                if (!this.stateParams.applicationId || !this.bondType.isGenericObligee) {
                    promises.push(this.loadObligee());
                }

                promises.push(this.loadFormOptions());
                promises.push(this.loadAttorneyInFactOptions());
        
                return this.$q.all(promises)
                    .then(() => {
                        this.initializeNameOnBondOptions();
                        this.initializeActionsDropdown();
                    });
            });
    }

    public showBondTypeSelectionModal = () => {
        if (this.application.status !== ApplicationStatus.NotSubmitted) {
            return;
        }
        
        this.modalOpener.showBondTypeSelectionModal()
            .result
            .then((result: BondTypeSelectionModalResult) => {

                this.application.bondTypeId = result.bondTypeId;

                this.busyIndicator = {
                    message: 'Updating Bond Type...',
                    promise: this.loadBondType(result.bondTypeId)
                        .then(() => this.loadObligee())
                        .then(() => this.loadFormOptions())
                        .then(() => {
                            this.setUnderwritingQuestionResponses();
                            this.initializeNameOnBondOptions();
                        })
                }
            })
    }
    
    public initializeNameOnBondOptions() {
        if (this.application.people && this.application.people[0]) {
            if (!!this.application.people[0].firstName) {
                this.setNameOnBondOptions(null, 'name');
            }    
        }

        if (this.application.companies && this.application.companies[0]) {
            if (!!this.application.companies[0].name) {
                this.setNameOnBondOptions(null, 'name');
            }
        }
    }

    public loadEProducerAccount(): IPromise<void> {
        return this.applicationService.getEProducerBrokerForApplication(this.application.eProducerAccountId)
            .then((eProducerAccount) => {
                this.referringEProducerBrokerAccount = eProducerAccount;
            });
    }

    public loadApplication(applicationId: number): IPromise<void> {
        return this.applicationService.getForEditing(applicationId)
            .then((application) => {

                this.application = application;
                this.questionResponses = application.applicationQuestionResponses;

                if (this.application.eProducerAccount && this.application.eProducerAccount.eProducerAccountType ===  EProducerAccountType.Broker) {
                    this.referringEProducerBrokerAccount = this.application.eProducerAccount;
                    this.isEProducerBrokerReferral = true;

                    delete this.application.eProducerAccount;
                }

                if (this.application.status !==  ApplicationStatus.Submitted && this.application.status !==  ApplicationStatus.NotSubmitted) {
                    this.stateService.go('main.customerDetail', { customerId: this.application.customerId });
                    this.toastMessageCreator.createErrorMessage('Application is not open for editing');

                    return;
                }

                this.isCompanyPrincipalType = this.application.principalType ===  PrincipalType.Company;
            })
            .then(() => this.loadBondType(this.application.bondTypeId))
            .then(() => this.loadObligee())
            .then(() => this.loadFormOptions())
            .then(() => this.loadAttorneyInFactOptions())
            .then(() => { this.setUnderwritingQuestionResponses() })
            .then(() => { this.initializeNameOnBondOptions() })
            .then(() => { this.initializeActionsDropdown() })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred loading the application');
            });
    }

    public loadCustomer(): IPromise<void> {
        const query = this.odataService.getQuery();

        query.top(1);
        query.expand('tags,people($filter=includeOnBonds eq true),companies,eProducerAccount');
        query.filter('id eq ' + this.application.customerId);

        const customerODataService = this.odataService.getService<Customer>(ODataEndpoint.Customer);

        return customerODataService.get(query)
            .then((response) => {
                this.application.customer = response.data.value[0];
                this.application.eProducerAccountId = response.data.value[0].eProducerAccountId;
                // todo set servicing roles from customer

                if (this.application.eProducerAccountId && response.data.value[0].eProducerAccount.eProducerAccountType == EProducerAccountType.Broker) {
                    this.referringEProducerBrokerAccount = response.data.value[0].eProducerAccount;
                    this.isEProducerBrokerReferral = true;
                }
                
                this.application.people = response.data.value[0].people.length ? response.data.value[0].people : [{} as  Person];

                if (this.application.customer.customerType === CustomerType.Company) {
                    this.application.companies = response.data.value[0].companies.length ? response.data.value[0].companies : [{} as  Company];
                    this.application.principalType = PrincipalType.Company;
                    this.isCompanyPrincipalType = true;
                } else {
                    this.application.principalType = PrincipalType.Individual;
                    this.isCompanyPrincipalType = false;
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to load the customer info');
            });
    }

    public loadFormOptions(): IPromise<void> {
        let queryStringParams = '?';

        if (this.bondType){
            queryStringParams += `bondTypeId=${this.bondType.id}`;
        }

        if (this.isRenewal){
            queryStringParams += `&renewingCarrierSystemAccountId=${this.application.bond.carrierSystemAccountId}`;
        }

        return this.$http.get<A3ApiResponse<A3WebApplicationForm>>(this.systemSettings.apiBaseUrl + 'ApplicationActions/GetApplicationForm' + queryStringParams)
            .then((response) => {
                this.formOptions = response.data.value;

                // format min and max effective date to date object
                if (this.formOptions.minimumEffectiveDate && this.formOptions.maximumEffectiveDate) {
                    this.formOptions.minimumEffectiveDate = moment.utc(this.formOptions.minimumEffectiveDate, moment.ISO_8601).toDate();
                    this.formOptions.maximumEffectiveDate = moment.utc(this.formOptions.maximumEffectiveDate, moment.ISO_8601).toDate();
                }

                if (this.application) {
                    this.setNameOnBondOptions();
                }
            });
    }

    public loadObligee(): IPromise<void> {
        
        if (this.application.obligeeId) {
            return;
        } 

        return this.obligeeService.getObligeeById(this.bondType.obligeeId)
            .then((obligee:  Obligee) => {

                if (obligee) {
                    this.application.obligee = {
                        ...obligee,
                        masterObligeeId: obligee.id,
                        id: undefined,
                        isGeneric: this.bondType.isGenericObligee,
                        bondTypes: []
                    };

                    if (this.application.obligee.isGeneric) {
                        this.application.obligee.name = '';
                    }
                } else {
                    this.application.obligee = {
                        name: '',
                        isGeneric: true
                    } as Obligee;
                }
            });
    }

    /**
     * Sets the response when loading an existing application.
     * Questions are displayed through formOptions.underwritingQuestions[] but the
     * application object loaded from the db will store them as applicationQuestionResponses[]
     */
    public setUnderwritingQuestionResponses(): void {
        // parent questions
        for (const question of this.formOptions.underwritingQuestions ) {
            if (question.id) {
                for (const response of this.application.applicationQuestionResponses) {
                    if (response.masterApplicationQuestionId === question.id) {
                        question.selectedValue = response.responseText;
                    }
                }
            }

            // sub questions
            if (!question.subQuestions) {
                continue;
            }

            for (const subQuestion of question.subQuestions) {
                if (subQuestion.id) {
                    for (const response of this.application.applicationQuestionResponses) {
                        if (response.masterApplicationQuestionId === subQuestion.id) {
                            subQuestion.selectedValue = response.responseText;
                        }
                    }
                }
            }
        }
    }

    public async loadAttorneyInFactOptions(): Promise<void> {
        this.aifOptions = await this.userService.getUsersForDropdown({isAttorneyInFact: true});

        let attorneyInFactExists = false;

        for (let i = 0; i < this.aifOptions.length; i++) {
            if (this.aifOptions[i].value === this.application.attorneyInFactUserId) {
                attorneyInFactExists = true;
            }
        }

        // Check if current application's Attorney In Fact is still an option.
        // If not, then clear application.attorneyInFact.
        if (!attorneyInFactExists) {
            this.application.attorneyInFactUserId = undefined;
        }

        if (!this.application.attorneyInFactUserId) {
            this.application.attorneyInFactUserId = this.systemAccountService.getDefaultAttorneyInFactUserId(this.aifOptions);
        }
    }

    public setNameOnBondOptions(object?: any, changedProperty?: string): void {
        if (changedProperty !== 'name' && changedProperty !== 'firstName' && changedProperty !== 'middleName' && changedProperty !== 'lastName') {
            return;
        }

        this.nameOnBondOptions = [];

        let combinedNames = '';

        for (const person of this.application.people) {
            const fullName = person.firstName + ' ' + person.middleName + ' ' + person.lastName;
            const firstLastName = person.firstName + ' ' + person.lastName;

            if (this.bondType.nameOnBondAllowFirstLast) {
                this.nameOnBondOptions.push({ label: firstLastName, value: firstLastName });
            }

            if (this.bondType.nameOnBondAllowFirstMiddleLast && person.middleName) {
                this.nameOnBondOptions.push({ label: fullName, value: fullName });
            }

            if (this.bondType.nameOnBondAllowCombinePrincipals && this.application.people.length > 1) {
                combinedNames += fullName + ' & ';
            }
        }

        if (combinedNames !== '') {
            combinedNames = combinedNames.slice(0, combinedNames.length - 3);
            this.nameOnBondOptions.push({ label: combinedNames, value: combinedNames });
        }

        for (const company of this.application.companies) {
            if (this.bondType.nameOnBondAllowCompanyName && company.name) {
                this.nameOnBondOptions.push({ label: company.name, value: company.name });
            }

            if (this.bondType.nameOnBondAllowCompanyDBA && company.dba) {
                this.nameOnBondOptions.push({ label: company.dba, value: company.dba });
            }
        }

        if (this.bondType.nameOnBondAllowOtherA3) {
            this.nameOnBondOptions.push({ label: 'Other', value: 'Other' });
        }

        this.setSelectedNameOnBond(this.application.nameOnBond);
    }

    public changeApplicant(): void {
        this.applicationTestDataCreator.fillApplication(this);
    }

    public changePrincipalType(): void {
        if (this.isCompanyPrincipalType) {
            this.application.principalType =  PrincipalType.Company;

            if (this.application.companies.length === 0) {
                this.application.companies.push({} as  Company);
            }

            this.formOptions.required.companyName = true;
            this.formOptions.required.companyPhysicalAddress = true;
            this.formOptions.required.companyPhysicalCity = true;
            this.formOptions.required.companyPhysicalState = true;
            this.formOptions.required.companyPhysicalZip = true;
            this.formOptions.required.companyMailAddress = true;
            this.formOptions.required.companyMailCity = true;
            this.formOptions.required.companyMailState = true;
            this.formOptions.required.companyMailZip = true;
        } else {
            this.application.principalType =  PrincipalType.Individual;
            this.formOptions.required.companyName = false;
            this.formOptions.required.companyPhysicalAddress = false;
            this.formOptions.required.companyPhysicalCity = false;
            this.formOptions.required.companyPhysicalState = false;
            this.formOptions.required.companyPhysicalZip = false;
            this.formOptions.required.companyMailAddress = false;
            this.formOptions.required.companyMailCity = false;
            this.formOptions.required.companyMailState = false;
            this.formOptions.required.companyMailZip = false;

        }
    }

    public showLoadCustomerModal(): void {
        this.modalOpener.customerLookupModal()
            .result
            .then((result) => {
                if (result.source === CustomerSources.A3 && result.customer.id) {
                    
                    this.application.customerId = result.customer.id;
                
                    if (!this.stateParams.applicationId && this.application.customerId) {
                        this.busyIndicator = {
                            message: 'Loading Customer...',
                            promise: this.loadCustomer()
                        };
                    }
                }

                this.setNameOnBondOptions(null, 'name');
            })
            .catch(() => {});
    }

    // sets name on bond based on existing application that is loaded
    public setSelectedNameOnBond(nameOnBond: string): void {
        // if set and exists in list then set to that name
        for (const nameOnBondOption of this.nameOnBondOptions) {
            if (nameOnBondOption.value === nameOnBond) {
                this.selectedNameOnBond = nameOnBond;
                return;
            }
        }

        // if set and not exists in list and bondType allows for other then set to other and set other to value
        if (nameOnBond && nameOnBond.length && this.bondType.nameOnBondAllowOtherA3) {
            this.selectedNameOnBond = 'Other';
            return;
        }

        this.selectedNameOnBond = '';
    }

    public loadBondType(bondTypeId:  number): IPromise<void> {
        this.application.bondTypeId = bondTypeId;

        return this.bondTypeService.getBondTypeForApplication(bondTypeId)
            .then((bondType) => {

                this.bondType = bondType;
       
                if (this.bondType.bondAmountType === BondAmountTypes.Fixed) {
                    this.application.bondAmount = this.bondType.bondAmount;
                }
        
                if (this.bondType.bondAmountType === BondAmountTypes.Variable) {
                    this.bondTypeVariableBondAmounts = [];

                    for(let i = 0; i < this.bondType.bondTypeVariableBondAmounts.length; i++) {
                        const bondAmount = this.bondType.bondTypeVariableBondAmounts[i];

                        this.bondTypeVariableBondAmounts.push({ 
                            label: this.$filter('currency')(bondAmount.amount, '$', 2), 
                            value: bondAmount.amount } 
                        );
                    }
                }
            });
    }

    public saveApplication_click(submit: boolean, goToCustomerDetail: boolean, overrideResubmitConfirmModal?: boolean): void {

        if (submit && this.bondType.bondAmountType == BondAmountTypes.UserDefined) {
            if (this.application.bondAmount < this.bondType.minimumBondAmount) {
                this.aaNotify.error(
                    `Bond amount must be more than ${this.$filter('currency')(this.bondType.minimumBondAmount, '$', 2)}`,
                    { ttl: 5000 }
                );

                return;
            } 
            
            if (this.application.bondAmount > this.bondType.maximumBondAmount) {
                this.aaNotify.error(
                    `Bond amount must be less than ${this.$filter('currency')(this.bondType.maximumBondAmount, '$', 2)}`,
                    { ttl: 5000 }
                );
                return;
            }
        }

        this.goToCustomerProfileOnSave = goToCustomerDetail ? goToCustomerDetail : false;

        if (this.submitting && !overrideResubmitConfirmModal) {
            return;
        }

        this.submitting = submit;
        // for manual validation of the underwriting questions
        this.formSubmitted = submit;
        this.saving = !submit;

        // ensure that each company and each person have names
        if (this.saving) {
            if (this.isCompanyPrincipalType) {
                for (let i = 0; i < this.application.companies.length; i++) {
                    if (!this.application.companies[i].name) {
                        this.aaNotify.error(
                            `Company name is required`,
                            { ttl: 10000 }
                        );
                        return;
                    }
                }
            }

            for (let i = 0; i < this.application.people.length; i++) {
                if (!this.application.people[i].firstName || !this.application.people[i].lastName) {
                    this.aaNotify.error(
                        `First and Last name is required`,
                        { ttl: 10000 }
                    );
                    return;
                }
            }

            if(!this.application.attorneyInFactUserId) {
                this.aaNotify.error(
                    'Attorney-In-Fact is required',
                    { ttl: 10000 }
                );
                return;
            }
        }

        // show loading mask
        this.loadingMaskDefer = this.$q.defer();
        this.busyIndicator.message = "Processing...";
        this.busyIndicator.promise = this.loadingMaskDefer.promise;

        // if this is a form resubmission then we want the submit to be triggered from the confirm modal
        // this informs the user that the manual quotes will be reset to pending because of the resubmission
        if (submit && this.application.status !== 'NotSubmitted' && !overrideResubmitConfirmModal) {
            this.modalOpener
                .confirmModal('Resubmit Application', 'Are you sure you want to resubmit this application? This will cause underwriting to be re-evaluated and any manually approved quotes will be placed in pending status.', 'Resubmit', 'Cancel')
                .result
                .then(() => {
                    this.saveApplication_click(true, false, true);
                })
                .catch(() => {
                    this.submitting = false;
                    this.loadingMaskDefer.resolve();
                });

            return;
        }

        this.saveDeferred = this.$q.defer();

        this.saveApplication();
    }

    public saveApplication(): void {
        this.application.applicationQuestionResponses = this.questionResponses;
        this.cleanData();

        if (this.submitting) {
            this.application.status = ApplicationStatus.Submitted;
        }

        if (this.referringEProducerBrokerAccount && this.isEProducerBrokerReferral) {
            this.application.eProducerAccountId = this.referringEProducerBrokerAccount.id;
        } else {
            this.application.eProducerAccountId = null;
        }

        let promise;

        const applicationODataService = this.odataService.getService<Application>(ODataEndpoint.Application);

        if (this.application.id) {
            promise = applicationODataService.put(this.application.id, this.application);
        } else {
            promise = applicationODataService.post(this.application);
        }

        return promise
            .then((response) => {

                if (response.data && response.data.id) {
                    this.application.id = response.data.id;
                }
                
                if (this.submitting) {
                    this.stateService.go(
                        'main.customerDetail',
                        {
                            customerId: response.data.customerId,
                            applicationId: response.data.id,
                            sectionView: 'applications'
                        }
                    );

                    this.toastMessageCreator.createSuccessMessage('The application submitted successfully');

                    this.saveDeferred.resolve();
                    return this.$q.when();
                }

                if (this.goToCustomerProfileOnSave) {
                    this.stateService.go('main.customerDetail', { customerId: this.application.customerId });
                    this.saveDeferred.resolve();

                    return this.$q.when();
                }

                return this.loadApplication(this.application.id)
                    .then(() => {
                        this.toastMessageCreator.createSuccessMessage('The application saved successfully');
                        this.saveDeferred.resolve();
                    });
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred saving the application');
                this.saveDeferred.reject();
            })
            .finally(() => {
                this.submitting = false;
                this.formSubmitted = false;
                this.replaceTempData();

                // hide loading mask
                this.loadingMaskDefer.resolve();
            });
    }

    public cleanData(): void {
        if (this.application.principalType !==  PrincipalType.Company) {
            delete this.application.companies;
        }

        // if name on bond field is set to "other" then keep it because application.nameOnBond is mapped to the "other" textbox
        // if not other then set nameOnBond to the value in the uib-dropdown
        this.application.nameOnBond = this.selectedNameOnBond === 'Other' ? this.application.nameOnBond : this.selectedNameOnBond;

        // because we are expanding so deep in the ODATA request there are
        // additional properties that are included which need to be removed
        // otherwise the server will reject the save request
        this.application['accountRequestBondTypes@odata.context'] = undefined;
        this.submissionSignature = this.application.submissionSignature;

        if (this.application.accountRequestBondTypes) {
            for (let i = 0; i < this.application.accountRequestBondTypes.length; i++) {
                if (this.application.accountRequestBondTypes[i].bondType) {
                    this.application.accountRequestBondTypes[i].bondTypeId = this.application.accountRequestBondTypes[i].bondType.id;
                }

                // we'll take the bondtype and set it asside. Then we'll re-assign after the save occurs
                this.accountRequestBondTypes[i] = this.application.accountRequestBondTypes[i].bondType;
                this.application.accountRequestBondTypes[i].bondType = null;
                this.application.accountRequestBondTypes[i]['bondType@odata.context'] = undefined;
            }
        }

        if (this.application.obligee != null && !this.application.obligee.isGeneric) {
            delete this.application.obligee;
        }

        if (this.application.customer) {
            delete this.application.customer.people;
            delete this.application.customer.companies;
            delete this.application.customer.attachments;
            delete this.application.customer.eProducerAccount;
        }

        if (this.isRenewal) {
            delete this.application.bond.questionResponses;
            delete this.application.bond.amountAsText;
            delete this.application.bond.bondAmountAsText;
            delete this.application.bond.amountAsTextNumbersOnly;
            delete this.application.bond.amount;
            delete this.application.bond.quote;
            delete this.application.bond.obligee;
            delete this.application.bond.attachments;
            delete this.application.bond.memos;
            delete this.application.memos;
            delete this.application.quotes;
            delete this.application.obligee;
        }
    }

    public replaceTempData(): void {
        // replace the some data which was removed to post/put successfully
        // before post/put the data was put in this.temp
        this.application.submissionSignature = this.submissionSignature;

        if (this.accountRequestBondTypes) {
            for (let i = 0; i < this.accountRequestBondTypes.length; i++) {
                this.application.accountRequestBondTypes[i].bondType = this.accountRequestBondTypes[i];
            }
        }
    }
    
    public searchBrokers(searchPhrase: string) {
        this.eProducerService.searchBrokers(searchPhrase)
            .then((results) => {
                this.brokerSearchResults = results;
            });
    } 

    public returnToCustomerDetail(): void {
        if (this.formController.$dirty && this.application.status ===  ApplicationStatus.NotSubmitted) {
            this.modalOpener.confirmModal(
                    'Save Application', 
                    'There are unsaved changes on this application. Do you want to save these changes?', 
                    'Save', 
                    'No'
                )
                .result
                .then(() => {
                    this.saveApplication_click(false, true);
                })
                .catch(() => {
                    this.stateService.go('main.customerDetail', { customerId: this.application.customerId });
                });

            return;
        }

        this.stateService.go('main.customerDetail', { customerId: this.application.customerId });
    }

    public buildQuestionOptions(question): void {
        question.options = [];

        angular.forEach(question.masterApplicationQuestionChoices, (choice) => {
            question.options.push({ label: choice.text, value: choice.text });
        });
    }

    public showEmailAccessModal(): void {
        if (!this.application.id && !this.applicationIsValidToSave(this.application)) {
            this.modalOpener.emailApplicationModal(null, this.bondType.id, this.application.bondAmount)
                .result
                .then(function(){})
                .catch(function(){});
        } else {
            this.saveApplication_click(false, false, true);

            this.saveDeferred.promise.then(() => {
                this.modalOpener.emailApplicationModal(this.application.id)
                    .result
                    .then(function(){})
                    .catch(function(){});
            });
        }
    }

    public applicationIsValidToSave(application: UiApplication): boolean {
        if (application.people.length === 0) {
            return false;
        }

        for (const person of application.people) {
            if (!person.firstName || !person.lastName) {
                return false;
            }
        }

        for (const company of application.companies) {
            if (!company.name) {
                return false;
            }
        }

        return true;
    }
}

export class ApplicationStateParams {
    public bondTypeId: number;
    public applicationId: number;
    public customerId: number;
    public appType:  ApplicationType;
    public eProducerAccountId: number;
    public bondId: number;
}
