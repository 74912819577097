import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { RenameDataViewModalOptions } from "./renameDataViewModalOptions";

export class RenameDataViewModalController {

    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance
    ];

    constructor (
        private readonly options: RenameDataViewModalOptions,
        private readonly $uibModalInstance: Modal<RenameDataViewModalResult>
    ) {}  

    public heading: string;
    public name: string;
    public originalName: string;
    public existingViewNames: string[];
    public errorMessage: string;

    public save = () => {
        if (this.originalName == this.name) {
            this.$uibModalInstance.dismiss('cancel');
            return;
        }

        if (this.existingViewNames.indexOf(this.name) >= 0) {
            this.errorMessage = "A view already exists with this name";
            return;
        }

        this.$uibModalInstance.close({name: this.name});
    }

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    };

    public $onInit = () => {
        this.originalName = this.options.name;
        this.name = this.options.name;
        this.heading = this.options.heading;
        this.existingViewNames = this.options.existingViewNames;
    }
}

export interface RenameDataViewModalResult { 
    name: string;
}