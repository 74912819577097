export interface EProducerBrandingSettings {
    id: number;
    brandingEnabled: boolean;
    brandingCompanyName: string;
    brandingPhone: string;
    brandingEmail: string;
    brandingMailAddress: string;
    brandingMailCity: string;
    brandingMailState: string;
    brandingMailSuiteAptNumber: string;
    brandingMailZip: string;
    brandingPhysicalAddress: string;
    brandingPhysicalCity: string;
    brandingPhysicalState: string;
    brandingPhysicalSuiteAptNumber: string;
    brandingPhysicalZip: string;
    brandingLargeLogoFileId: number;
    brandingSmallLogoFileId: number;
    brandingLargeLogo: File;
    brandingSmallLogo: File;
}