import { EProducerService } from "../../api/eProducerService";
import { EProducerUser } from "../../api/types/model/eProducerUser";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class EProducerUserListController {

    public static $inject = [
        Injectables.ModalOpener, 
        Injectables.ToastMessageCreator, 
        Injectables.EProducerService
    ];

    constructor (
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly eProducerService: EProducerService) {
    }

    public busyIndicator: BusyIndicator;
    public users: EProducerUser[];

    public resetPassword = (email: string) => {
        this.busyIndicator.message = 'Resetting Password...';
        this.busyIndicator.promise = this.eProducerService.resetUserPassword(email)
        .then(() => {
            this.toastMessageCreator.createSuccessMessage('Password reset email was sent successfully');
        })
        .catch((err) =>  {
            if (err?.message) {
                this.toastMessageCreator.createErrorMessage(err.message);
            } else {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to reset this users password');
            }            
        });
    }

    public promptToDelete = (user: EProducerUser, $index: number) => {
        return this.modalOpener.confirmModal(
                    "Delete User",
                    `Are you sure you want to delete <b>${user.firstName} ${user.lastName}</b>?`,
                    'Delete',
                    'Cancel'
                )
                .result
                .then(() => {
                    this.eProducerService.deleteUser(user.id)
                        .then(() => {
                            this.users.splice($index, 1);
                            this.toastMessageCreator.createSuccessMessage('Broker login has been deleted');
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage('An error occurred trying to delete the broker login')
                        })
                });
    }
    
    public $onInit = () => {
        this.busyIndicator = {};
    };
}

const eproducerUserListComponent = {
    templateUrl: 'app/components/eProducerUserList/eProducerUserList.html',
    bindings: { users: '=' },
    controller: EProducerUserListController,
    controllerAs: 'vm'
}

app.component('eproducerUserList', eproducerUserListComponent);