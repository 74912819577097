import { IHttpService, IPromise } from "angular";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { FileDownloader } from "./fileDownloader";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { BillingEntryReportItem } from "../states/agentSpecific/billingEntry/billingEntryReportItem";
import { BillingReportDropdownFilterOptions } from "../states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingReportDropdownFilterOptions";
import { PageResponse } from "./types/pageResponse";
import BillingEntriesListItem from "../components/billingEntriesList/billingEntriesListItem";
import { EndOfMonthReportFilter } from "../states/agentSpecific/monthEndAccounting/endOfMonthReportFilter";
import { CreditBillingEntryForAssignment } from "../modals/billingEntryCreditAssignmentModal/creditBillingEntryForAssignment";
import { CreditBillingEntryAssignmentRequest } from "../modals/billingEntryCreditAssignmentModal/creditBillingEntryAssignmentRequest";

export class BillingEntryService {

    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader
    ) {
    }

    public getBillingEntriesReportData(tableQueryOptions: BillingReportDropdownFilterOptions): IPromise<PageResponse<BillingEntryReportItem>> {
        const queryString = this.buildBillingEntryReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesReport${queryString}`;
        
        return this.$http.get<A3ApiResponse<PageResponse<BillingEntryReportItem>>>(url)
            .then((response) => response.data.value);
    }

    public downloadBillingEntriesExcelReport(tableQueryOptions: BillingReportDropdownFilterOptions): IPromise<PageResponse<BillingEntryReportItem>> {
        const queryString = this.buildBillingEntryReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesExcelReport${queryString}`;

        return this.fileDownloader.downloadFile(url,null);
    }

    public saveBillingEntryReport(request): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/SaveBillingEntryReport`;

        return this.$http.post<void>(url, request)
            .then(() => {});
    }

    private buildBillingEntryReportQueryString(tableQueryOptions: BillingReportDropdownFilterOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as BillingReportDropdownFilterOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.invoiced) {
            queryString += `&invoiced=${tableQueryOptions.invoiced}`;
        }

        if (tableQueryOptions.productionCredit) {
            queryString += `&productionCredit=${tableQueryOptions.productionCredit}`;
        }

        if (tableQueryOptions.minimumAmountReceived) {
            queryString += `&minimumAmountReceived=${tableQueryOptions.minimumAmountReceived}`;
        }

        if (tableQueryOptions.maximumAmountReceived) {
            queryString += `&maximumAmountReceived=${tableQueryOptions.maximumAmountReceived}`;
        }

        if (tableQueryOptions.minimumAmountDue) {
            queryString += `&minimumAmountDue=${tableQueryOptions.minimumAmountDue}`;
        }

        if (tableQueryOptions.maximumAmountDue) {
            queryString += `&maximumAmountDue=${tableQueryOptions.maximumAmountDue}`;
        }

        if (tableQueryOptions.minimumAmountBilled) {
            queryString += `&minimumAmountBilled=${tableQueryOptions.minimumAmountBilled}`;
        }

        if (tableQueryOptions.maximumAmountBilled) {
            queryString += `&maximumAmountBilled=${tableQueryOptions.maximumAmountBilled}`;
        }

        if (tableQueryOptions.startDate) {
            queryString += `&startDate=${tableQueryOptions.startDate}`;
        }

        if (tableQueryOptions.endDate) {
            queryString += `&endDate=${tableQueryOptions.endDate}`;
        }

        if (tableQueryOptions.code) {
            queryString += `&code=${tableQueryOptions.code}`;
        }

        if (tableQueryOptions.producerUserId) {
            queryString += `&producerUserId=${tableQueryOptions.producerUserId}`;
        }

        if (tableQueryOptions.billingEntryType) {
            queryString += `&billingEntryType=${tableQueryOptions.billingEntryType}`;
        }

        if (tableQueryOptions.isOriginalEntry) {
            queryString += `&isOriginalEntry=${tableQueryOptions.isOriginalEntry}`;
        }

        if (tableQueryOptions.transactionType) {
            queryString += `&transactionType=${tableQueryOptions.transactionType}`;
        }

        return queryString;
    }

    public downloadExcelMonthEndReport(startDate: Date, endDate: Date): IPromise<void> {
        const filter: EndOfMonthReportFilter = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        } as EndOfMonthReportFilter;
        
        return this.fileDownloader.downloadFile(this.systemSettings.apiBaseUrl + 'BillingEntryActions/GetEndOfMonthEntriesExcelReport', filter);
    }
    
    public getBillingEntriesListDownload(tableQueryOptions: BillingReportDropdownFilterOptions) {
        const queryString = this.buildBillingEntryListQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesListDownload${queryString}`;

        return this.fileDownloader.downloadFile(url,null);
    }

    public getBillingEntryListItems(tableQueryOptions: BillingReportDropdownFilterOptions) {
        const queryString = this.buildBillingEntryListQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesList${queryString}`;
        
        return this.$http.get<A3ApiResponse<PageResponse<BillingEntriesListItem>>>(url)
            .then((response) => response.data.value);
    }

    private buildBillingEntryListQueryString(tableQueryOptions: BillingReportDropdownFilterOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as BillingReportDropdownFilterOptions
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.invoiced) {
            queryString += `&invoiced=${tableQueryOptions.invoiced}`;
        }

        if (tableQueryOptions.productionCredit) {
            queryString += `&productionCredit=${tableQueryOptions.productionCredit}`;
        }

        if (tableQueryOptions.minimumAmountReceived) {
            queryString += `&minimumAmountReceived=${tableQueryOptions.minimumAmountReceived}`;
        }

        if (tableQueryOptions.maximumAmountReceived) {
            queryString += `&maximumAmountReceived=${tableQueryOptions.maximumAmountReceived}`;
        }

        if (tableQueryOptions.minimumAmountDue) {
            queryString += `&minimumAmountDue=${tableQueryOptions.minimumAmountDue}`;
        }

        if (tableQueryOptions.maximumAmountDue) {
            queryString += `&maximumAmountDue=${tableQueryOptions.maximumAmountDue}`;
        }

        if (tableQueryOptions.startDate) {
            queryString += `&startDate=${tableQueryOptions.startDate}`;
        }

        if (tableQueryOptions.endDate) {
            queryString += `&endDate=${tableQueryOptions.endDate}`;
        }

        if (tableQueryOptions.code) {
            queryString += `&code=${tableQueryOptions.code}`;
        }

        if (tableQueryOptions.producerUserId) {
            queryString += `&producerUserId=${tableQueryOptions.producerUserId}`;
        }

        if (tableQueryOptions.billingEntryType) {
            queryString += `&billingEntryType=${tableQueryOptions.billingEntryType}`
        }

        if (tableQueryOptions.isOriginalEntry) {
            queryString += `&isOriginalEntry=${tableQueryOptions.isOriginalEntry}`
        }

        if (tableQueryOptions.customerId) {
            queryString += `&customerId=${tableQueryOptions.customerId}`
        }

        if (tableQueryOptions.eProducerAccountId) {
            queryString += `&eProducerAccountId=${tableQueryOptions.eProducerAccountId}`
        }

        return queryString;
    }

    public getCreditBillingEntryForAssignment(creditBillingEntryId: number): IPromise<CreditBillingEntryForAssignment> {
        return this.$http
            .get<A3ApiResponse<CreditBillingEntryForAssignment>>(this.systemSettings.apiBaseUrl + 'BillingEntryActions/GetCreditBillingEntryForAssignment?billingEntryId=' + creditBillingEntryId)
            .then((response) => response.data.value);
    }

    public assignCreditBillingEntry(creditBillingEntryAssignmentRequest: CreditBillingEntryAssignmentRequest): IPromise<void> {
        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'BillingEntryActions/AssignCredit', creditBillingEntryAssignmentRequest)
            .then(() => {});
    }
}

app.service(Injectables.BillingEntries, BillingEntryService);
