import QueryConditionPropertyTypes from "./queryConditionPropertyType";
import QueryBuilderOperator from "./queryBuilderOperator";
import { SelectOption } from "../../api/types/selectOption";

export const allOperatorOptions = {
    equals: { label: 'Equals', value: QueryBuilderOperator.Equals } as SelectOption<QueryBuilderOperator>,
    notEqual: { label: 'Not Equal', value: QueryBuilderOperator.NotEqual } as SelectOption<QueryBuilderOperator>,
    greaterThan: { label: 'Greater Than', value: QueryBuilderOperator.GreaterThan } as SelectOption<QueryBuilderOperator>,
    lessThan: { label: 'Less Than', value: QueryBuilderOperator.LessThan } as SelectOption<QueryBuilderOperator>,
    greaterOrEqual: { label: 'Greater or Equal', value: QueryBuilderOperator.GreaterOrEqual } as SelectOption<QueryBuilderOperator>,
    lessOrEqual: { label: 'Less or Equal', value: QueryBuilderOperator.LessOrEqual } as SelectOption<QueryBuilderOperator>,
    startsWith: { label: 'Starts with', value: QueryBuilderOperator.StartsWith } as SelectOption<QueryBuilderOperator>,
    endsWith: { label: 'Ends with', value: QueryBuilderOperator.EndsWith } as SelectOption<QueryBuilderOperator>,
    contains: { label: 'Contains', value: QueryBuilderOperator.Contains } as SelectOption<QueryBuilderOperator>,
    between: { label: 'Between', value: QueryBuilderOperator.Between } as SelectOption<QueryBuilderOperator>,
    in: { label: 'In', value: QueryBuilderOperator.In } as SelectOption<QueryBuilderOperator>,
    blank: { label: 'Blank', value: QueryBuilderOperator.Blank } as SelectOption<QueryBuilderOperator>,
    notStartsWith: { label: 'Not starts with', value: QueryBuilderOperator.NotStartsWith } as SelectOption<QueryBuilderOperator>,
    notEndsWith: { label: 'Not ends with', value: QueryBuilderOperator.NotEndsWith } as SelectOption<QueryBuilderOperator>,
    notContains: { label: 'Not contains', value: QueryBuilderOperator.NotContains } as SelectOption<QueryBuilderOperator>,
    notBetween: { label: 'Not between', value: QueryBuilderOperator.NotBetween } as SelectOption<QueryBuilderOperator>,
    notIn: { label: 'Not in', value: QueryBuilderOperator.NotIn } as SelectOption<QueryBuilderOperator>,
    notBlank: { label: 'Not Blank', value: QueryBuilderOperator.NotBlank} as SelectOption<QueryBuilderOperator>,
    isTrue: { label: 'Is True', value: QueryBuilderOperator.IsTrue} as SelectOption<QueryBuilderOperator>,
    notTrue: { label: 'Not True', value: QueryBuilderOperator.NotTrue} as SelectOption<QueryBuilderOperator>,
    isMe: { label: 'Is Me', value: QueryBuilderOperator.IsMe} as SelectOption<QueryBuilderOperator>,
    isNotMe: { label: 'Is Not Me', value: QueryBuilderOperator.IsNotMe} as SelectOption<QueryBuilderOperator>,
};

export const getOperatorsByPropertyType = (propertyType?: QueryConditionPropertyTypes) => {
    const operatorOptions: SelectOption<QueryBuilderOperator>[] = [];

    switch (propertyType) {
        case QueryConditionPropertyTypes.Phone:
        case QueryConditionPropertyTypes.String:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.contains);
            operatorOptions.push(allOperatorOptions.notContains);
            operatorOptions.push(allOperatorOptions.startsWith);
            operatorOptions.push(allOperatorOptions.notStartsWith);
            operatorOptions.push(allOperatorOptions.endsWith);     
            operatorOptions.push(allOperatorOptions.notEndsWith);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            break;
        case QueryConditionPropertyTypes.PhoneExists:
        case QueryConditionPropertyTypes.StringExists:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.contains);
            operatorOptions.push(allOperatorOptions.startsWith);
            operatorOptions.push(allOperatorOptions.endsWith);
            break;
        case QueryConditionPropertyTypes.Money:
        case QueryConditionPropertyTypes.Decimal:
        case QueryConditionPropertyTypes.Integer:
        case QueryConditionPropertyTypes.Percent:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.greaterThan);
            operatorOptions.push(allOperatorOptions.greaterOrEqual);
            operatorOptions.push(allOperatorOptions.lessThan);
            operatorOptions.push(allOperatorOptions.lessOrEqual);
            operatorOptions.push(allOperatorOptions.between);
            operatorOptions.push(allOperatorOptions.notBetween);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            break;
        case QueryConditionPropertyTypes.Boolean:
            operatorOptions.push(allOperatorOptions.isTrue);
            operatorOptions.push(allOperatorOptions.notTrue);
            break;
        case QueryConditionPropertyTypes.Date:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.greaterThan);
            operatorOptions.push(allOperatorOptions.greaterOrEqual);
            operatorOptions.push(allOperatorOptions.lessThan);
            operatorOptions.push(allOperatorOptions.lessOrEqual);            
            operatorOptions.push(allOperatorOptions.between);
            operatorOptions.push(allOperatorOptions.notBetween);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            break;
        case QueryConditionPropertyTypes.Producer:
        case QueryConditionPropertyTypes.ClientServiceExecutive:
        case QueryConditionPropertyTypes.ClientServiceManager:
        case QueryConditionPropertyTypes.ClientServiceAssociate:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            operatorOptions.push(allOperatorOptions.isMe);
            operatorOptions.push(allOperatorOptions.isNotMe);
            break;    
        case QueryConditionPropertyTypes.CommissionCalculationType:
        case QueryConditionPropertyTypes.BillToType:
        case QueryConditionPropertyTypes.BrokerCategory:
        case QueryConditionPropertyTypes.BondAccountStatus:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            break;    
    }

    return operatorOptions;
}
