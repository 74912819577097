import { PaymentService } from "../../api/paymentService";
import { PaymentForReassignment } from "../../api/types/payments/paymentAssignment/paymentForReassignment";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { PaymentAssignmentModalOptions } from "./paymentAssignmentModalOptions";
import { PaymentAssignmentModalResult } from "./paymentAssignmentModalResult";
import app from "../../main";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { OpenBillingEntries } from "../../api/types/payments/paymentAssignment/openBillingEntries";
import { BillingEntryPaymentAssignment } from "../../api/types/payments/paymentAssignment/billingEntryPaymentAssignment";
import isUndefinedOrNull from "../../utilities/angularUtilities/isUndefinedOrNull";
import PaymentAssignmentRequest from "./paymentAssignmentRequest";
import PaymentForReassignmentBillingEntry from "../../api/types/billingEntries/paymentForReassignmentBillingEntry";
import { CustomerOrEproducerBrokerSelectionOption } from "../../components/customerOrEproducerBrokerSelection/customerOrEproducerBrokerSelection";
import { PaymentToPaymentDisplayListItem}  from "../../api/types/payments/paymentAssignment/paymentToPaymentDisplayListItem";
import PaymentToPaymentForReassignment from "../../api/types/payments/paymentAssignment/paymentToPaymentForReassignment";
import { PaymentMethod } from "../../api/types/model/paymentTransaction";

type NewType = PaymentToPaymentDisplayListItem;

export class PaymentAssignmentModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.PaymentService,
        Injectables.ToastMessageCreator
    ];

    public isLoaded: boolean;
    public busyIndicator: BusyIndicator;
    public payment: PaymentForReassignment;
    public totalAmountApplied: number;
    public searchPhrase: string;

    constructor(
        private uibModalInstance: Modal<PaymentAssignmentModalResult>,
        private options: PaymentAssignmentModalOptions,
        private readonly paymentService: PaymentService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public get unappliedAmount(): number {
        return Math.abs(this.payment?.amount) - Math.abs(this.totalAmountApplied);
    }

    public get showOverAppliedMessage(): boolean {
        return (
            this.isLoaded &&
            this.payment.amount > 0 &&
            this.totalAmountApplied > this.payment.amount
        );
    }

    public get showOverAppliedReturnMessage(): boolean {
        return (
            this.isLoaded &&
            this.payment.amount < 0 &&
            this.totalAmountApplied < this.payment.amount
        );
    }

    public get showUnderAppliedMessage(): boolean {
        return (
            this.isLoaded &&
            this.payment.amount > 0 &&
            this.totalAmountApplied < this.payment.amount
        );
    }

    public get showUnderAppliedReturnMessage(): boolean {
        return (
            this.isLoaded &&
            this.payment.amount < 0 &&
            this.totalAmountApplied > this.payment.amount
        );
    }
    
    public get overAppliedAmount(): number {
        if (!this.isLoaded) {
            return 0;
        }

        return this.totalAmountApplied - this.payment.amount;
    }

    public get underAppliedAmount(): number {
        if (!this.isLoaded) {
            return 0;
        }

        return this.payment.amount - this.totalAmountApplied;
    }

    public get isSubmitButtonDisabled(): boolean {
        if (!this.isLoaded) {
            return true;
        }

        if (this.payment.amount > 0) {
            return (
                this.totalAmountApplied > this.payment.amount ||
                this.payment.openBillingEntries.some((billingEntry) => this.getOpenBillingEntryOverAppliedVisibility(billingEntry)) || 
                this.payment.billingEntryPaymentAssignments.some((billingEntry) => this.getBillingEntryOverAppliedVisibility(billingEntry))
            );
        } else {
            return (
                this.totalAmountApplied < this.payment.amount ||
                this.payment.openBillingEntries.some((billingEntry) => this.getOpenBillingEntryOverAppliedVisibility(billingEntry)) || 
                this.payment.billingEntryPaymentAssignments.some((billingEntry) => this.getBillingEntryOverAppliedVisibility(billingEntry))
            );
        }
    }

    public getBillingEntryOverAppliedVisibility(billingEntry: BillingEntryPaymentAssignment): boolean {
        if (billingEntry.amount == null) {
            return false;
        }

        if (billingEntry.originalAmount < 0) {
            return billingEntry.amount < billingEntry.originalAmount;
        }

        return billingEntry.amount > billingEntry.originalAmount;
    }

    public getOpenBillingEntryOverAppliedVisibility(billingEntry: OpenBillingEntries): boolean {
        if (billingEntry.amountToApply == null) {
            return false;
        }

        if (billingEntry.amountDue < 0) {
            return billingEntry.amountToApply < billingEntry.amountDue;
        }

        return billingEntry.amountToApply > billingEntry.amountDue;
    }

    public getOpenPaymentEntryOverAppliedVisibility(openPaymentEntry: NewType): boolean {
        if (openPaymentEntry.appliedAmount == null) {
            return false;
        }

        if (openPaymentEntry.appliedAmount < 0) {
            return openPaymentEntry.appliedAmount < openPaymentEntry.unappliedAmount;
        }

        return openPaymentEntry.appliedAmount > openPaymentEntry.unappliedAmount;
    }

    public cancel() {
        this.uibModalInstance.dismiss();
    }

    public clearCustomerBrokerAssignment() {
        this.payment.customerId = null;
        this.payment.customerLookupCode = null;
        this.payment.customerName = null;
        this.payment.eProducerAccountId = null;
        this.payment.brokerLookupCode = null;
        this.payment.brokerName = null;
    }

    public submitAssignment() {
        const request = {
            customerId: this.payment.customerId,
            eProducerAccountId: this.payment.eProducerAccountId,
            paymentTransactionId: this.payment.paymentTransactionId,
            paymentCreatedDateTime: this.payment.createdDateTime,
            paymentMethod: this.payment.paymentMethod,
            paymentAmount: this.payment.amount,
            billingEntryIdsToDissociate: this.getBillingEntryIdsToDisassociate(),
            billingEntryAssignmentsToCreate: this.getBillingEntryAssignmentsToCreate(),
            billingEntryAssignmentsToUpdate: this.getExstingAssignments(),
            paymentTransactionIdsToDissociate: this.getPaymentTransactionIdsToDisassociate(),
            paymentTransactionAssignmentsToCreate: this.getPaymentTransactionsToCreate(),
            paymentTransactionAssignmentsToUpdate: this.getPaymentTransactionsToUpdate()
        } as PaymentAssignmentRequest;

        this.busyIndicator.message = "Assigning Payment...";
        this.busyIndicator.promise = this.paymentService
            .assignPayment(request)
            .then(() => {
                this.uibModalInstance.close(new PaymentAssignmentModalResult());
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("An error occurred trying to assign this payment");
            });
    }

    public searchPhraseChanged = () => {
        this.setAppliedOpenBillingEntries();
        this.setFilteredOpenBillingEntries();
    }

    public appliedOpenBillingEntries: OpenBillingEntries[];
    public setAppliedOpenBillingEntries() {
        this.appliedOpenBillingEntries = this.payment.openBillingEntries.filter((billingEntry) => {
            return (
                !isUndefinedOrNull(billingEntry.amountToApply) && 
                billingEntry.amountToApply != 0 
            ) 
        });
    }

    public filteredOpenBillingEntries: OpenBillingEntries[];
    public setFilteredOpenBillingEntries() {

        if (!this.payment?.openBillingEntries) {
            this.filteredOpenBillingEntries = [];
        } else if (!this.searchPhrase) {
            this.filteredOpenBillingEntries = this.payment.openBillingEntries.filter((billingEntry) => !billingEntry.amountToApply);
        } else {
            this.filteredOpenBillingEntries = this.payment.openBillingEntries
                .filter((billingEntry) => { 

                    if (!isUndefinedOrNull(billingEntry.amountToApply) && billingEntry.amountToApply != 0) {
                        return false;
                    }
                    
                    if (billingEntry.invoiceNumber && billingEntry.invoiceNumber.toString() == this.searchPhrase) {
                        return true;
                    }
                    
                    if (billingEntry.bondNumber && billingEntry.bondNumber == this.searchPhrase) {
                        return true;
                    }

                    return false;
            });
        }
    }

    public assignAllSearchResultsClicked = () => {
        this.filteredOpenBillingEntries.forEach(billingEntry => {
            this.amountDueClicked(billingEntry)
        });
    }

    private getPaymentTransactionIdsToDisassociate(): number[] {
        return this.payment.paymentTransactionAssignments
            .filter((paymentToPaymentDisplayListItem) => !paymentToPaymentDisplayListItem.appliedAmount)
            .map((paymentToPaymentDisplayListItem) => paymentToPaymentDisplayListItem.paymentTransactionId);
    }

    private getBillingEntryIdsToDisassociate(): number[] {
        return this.payment.billingEntryPaymentAssignments
            .filter((billingEntry) => !billingEntry.amount)
            .map((billingEntry) => billingEntry.billingEntryId );
    }
    
    private getPaymentTransactionsToCreate(): PaymentToPaymentForReassignment[] {
        return this.payment.openPaymentEntries
            .filter((openPaymentEntry) => openPaymentEntry.amountToApply)
            .map((openPaymentEntry) => {
                return {
                    returnPaymentTransactionId: this.payment.paymentTransactionId,          
                    appliedPaymentTransactionId: openPaymentEntry.paymentTransactionId,
                    amount: openPaymentEntry.amountToApply
                }
            });
    }

    private getBillingEntryAssignmentsToCreate(): PaymentForReassignmentBillingEntry[] {
        return this.payment.openBillingEntries
            .filter((billingEntry) => billingEntry.amountToApply)
            .map((billingEntry) => {
                return {
                    billingEntryId: billingEntry.billingEntryId,
                    paymentTransactionId: this.payment.paymentTransactionId,
                    amountReceived: billingEntry.amountToApply
                }
            });
    }

    private getPaymentTransactionsToUpdate(): PaymentToPaymentForReassignment[]{
        return this.payment.paymentTransactionAssignments
        .filter((paymentTransaction) => !isUndefinedOrNull(paymentTransaction.appliedAmount) && paymentTransaction.appliedAmount != 0)
        .map((paymentTransaction) => {
            return {
                id: paymentTransaction.id,
                returnPaymentTransactionId: this.payment.paymentTransactionId,          
                appliedPaymentTransactionId: paymentTransaction.paymentTransactionId,
                amount:  paymentTransaction.appliedAmount
            }
        });
    }

    private getExstingAssignments(): PaymentForReassignmentBillingEntry[] {
        return this.payment.billingEntryPaymentAssignments
            .filter((billingEntry) => !isUndefinedOrNull(billingEntry.amount) && billingEntry.amount != 0)
            .map((billingEntry) => {
                return {
                    id: billingEntry.billingEntryPaymentTransactionId,
                    billingEntryId: billingEntry.billingEntryId,
                    paymentTransactionId: this.payment.paymentTransactionId,
                    amountReceived: billingEntry.amount,
                    paymentInvoiceLineItemId: billingEntry.paymentInvoiceLineItemId
                }
            });
    }

    public accountSelected = (selectedAccount: CustomerOrEproducerBrokerSelectionOption) => {
        if (selectedAccount.eProducerAccountId) {
            this.payment.brokerName = selectedAccount.name;
            this.payment.brokerLookupCode = selectedAccount.lookupCode;
            this.payment.eProducerAccountId = selectedAccount.eProducerAccountId;
            this.payment.customerId = null;
            this.payment.customerLookupCode = null;
            this.payment.customerName = null;
        } else {
            this.payment.brokerName = null;
            this.payment.brokerLookupCode = null;
            this.payment.eProducerAccountId = null;
            this.payment.customerId = selectedAccount.customerId;
            this.payment.customerLookupCode = selectedAccount.lookupCode;
            this.payment.customerName = selectedAccount.name;
        }

        this.loadBillingEntries();
    }

    private loadBillingEntries() {
        this.isLoaded = false;
        this.busyIndicator.message = "Loading Billing Entries...";
        this.busyIndicator.promise = this.paymentService
            .getBillingEntriesForPaymentReassignment(this.payment.customerId, this.payment.eProducerAccountId)
            .then((openBillingEntries) => {
                this.payment.openBillingEntries = openBillingEntries;
                this.payment.billingEntryPaymentAssignments = [];
                this.setTotalAmountApplied();
                this.isLoaded = true;
                this.setFilteredOpenBillingEntries();
            });
    }

    private loadPayment(paymentTransactionId: number) {
        this.busyIndicator.message = "Loading Payment...";
        this.busyIndicator.promise = this.paymentService
            .getPaymentForAssignment(paymentTransactionId)
            .then((payment) => {
                this.payment = payment;

                this.setTotalAmountApplied();
                this.setFilteredOpenBillingEntries();
                this.isLoaded = true;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("An error occurred trying to load the payment");
            });
    }

    public amountClicked(billingEntryPaymentAssignment: BillingEntryPaymentAssignment) {

        if (billingEntryPaymentAssignment.amount) {
            billingEntryPaymentAssignment.amount = null;
        } else {
            billingEntryPaymentAssignment.amount = billingEntryPaymentAssignment.amountDue;
        }
        
        this.setTotalAmountApplied();
    }

    public amountDueClicked(openBillingEntry: OpenBillingEntries) {

        if (openBillingEntry.amountToApply) {
            openBillingEntry.amountToApply = null;
        } else {
            openBillingEntry.amountToApply = openBillingEntry.amountDue;
        }
        
        this.setTotalAmountApplied();
    }

    public paymentAppliedAmountClicked(appliedPayment: PaymentToPaymentDisplayListItem) {
        if (appliedPayment.appliedAmount) {
            appliedPayment.appliedAmount = null;
        } else {
            appliedPayment.appliedAmount = appliedPayment.unappliedAmount;
        }
        
        this.setTotalAmountApplied();
    }

    public paymentAmountToApplyClicked(openPaymentEntry: PaymentToPaymentDisplayListItem) {
        if (openPaymentEntry.amountToApply) {
            openPaymentEntry.amountToApply = null;
        } else {
            openPaymentEntry.amountToApply = openPaymentEntry.unappliedAmount;
        }
        
        this.setTotalAmountApplied();
    }

    public setTotalAmountApplied() {
        this.totalAmountApplied = 0;

        for (let i = 0; i < this.payment.openBillingEntries.length; i++) {
            if (isUndefinedOrNull(this.payment.openBillingEntries[i].amountToApply)) {
                continue;
            }

            this.totalAmountApplied += this.payment.openBillingEntries[i].amountToApply;
        }

        for (let i = 0; i < this.payment.billingEntryPaymentAssignments.length; i++) {
            this.totalAmountApplied += this.payment.billingEntryPaymentAssignments[i].amount;
        }

        for (let i = 0; i < this.payment.openPaymentEntries.length; i++) {
            if (isUndefinedOrNull(this.payment.openPaymentEntries[i].amountToApply)) {
                continue;
            }

            this.totalAmountApplied += -this.payment.openPaymentEntries[i].amountToApply;
        }

        for (let i = 0; i < this.payment.paymentTransactionAssignments.length; i++) {
            if (this.payment.paymentMethod != PaymentMethod.Return) {
                this.totalAmountApplied += this.payment.paymentTransactionAssignments[i].appliedAmount;
            } else {
                this.totalAmountApplied += -this.payment.paymentTransactionAssignments[i].appliedAmount;
            }
        }

        this.totalAmountApplied = +this.totalAmountApplied.toFixed(2);
    }

    $onInit(): void {
        this.busyIndicator = { message: 'Loading...' };

        this.loadPayment(this.options.paymentTransactionId);
    }
}

app.controller("PaymentAssignmentModalController", PaymentAssignmentModalController);
