import { SystemAccountService } from "../../../api/systemAccountService";
import { Injectables } from "../../../configuration/injectables";
import { CarrierPageItem } from "./carrierPageItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { CarrierPageQueryOptions } from "./carrierPageQueryOptions";

export class CarrierPageController {

    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.IDebouceDelayer,
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        debouceDelayer: IDebounceDelayer,
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<CarrierPageItem, CarrierPageQueryOptions>;
    public busyIndicator: BusyIndicator;
    
    public loadData = () => {
        this.busyIndicator.message = "Loading...";
        this.busyIndicator.promise = this.systemAccountService.getAgencyCarriers(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            }); 
    }
    
    public $onInit(): void {
        this.busyIndicator = {};
        this.loadData();
    }
}