import * as angular from "angular";
import { IDirectiveFactory, IDirectiveLinkFn, IScope, IAttributes, IController } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { BondTransactionService } from "../../api/bondTransactionService";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { TransactionType } from "../../api/types/model/bondTransaction";

const exportToEpicDirective: IDirectiveFactory = (bondTransactionService: BondTransactionService, toastMessageCreator: ToastMessageCreator) => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        element: JQuery,
        attributes: IAttributes,
        controller: IController
    ) => {
        scope.$watch(attributes.exportToEpic, () => {
            const directiveArgs = scope.$eval(attributes.exportToEpic);
            const transaction = directiveArgs.transaction;
            const busyIndicator = directiveArgs.busyIndicator;
            const id = transaction.id;

            if (!angular.isNumber(id) || id <= 0) {
                return;
            }

            element.bind('click',  (e: Event) => {

                const handleError = (err) => {
                    if (typeof err.data == "string"){
                        toastMessageCreator.createErrorMessage('An error occurred trying to export the transaction to Epic');
                    } else {
                        toastMessageCreator.createErrorMessage(err.data.message);
                        
                        if (err.data.errorType == "EpicExportResultWasError" || err.data.errorType == "EpicPreviousFailedExport"){
                            transaction.exportToEpicStatus = "Error";
                        }
                    }
                }

                busyIndicator.message = "Exporting Transaction...";

                if (transaction.transactionType == TransactionType.NewBusiness) {
                    busyIndicator.promise = bondTransactionService.exportNewBusinessItem(id)
                        .then(() => {
                            toastMessageCreator.createSuccessMessage("Bond exported to Epic successfully");
                            transaction.exportToEpicStatus = "Success";
                        })
                        .catch(handleError);
                }

                if (transaction.transactionType == TransactionType.Renewal) {
                    busyIndicator.promise = bondTransactionService.exportRenewal(id)
                        .then(() => {
                            toastMessageCreator.createSuccessMessage("Renewal transaction exported to Epic successfully")
                            transaction.exportToEpicStatus = "Success";
                        })
                        .catch(handleError);
                }

                if (transaction.transactionType == TransactionType.Cancellation) {
                    busyIndicator.promise = bondTransactionService.exportCancellation(id)
                        .then(() => {
                            toastMessageCreator.createSuccessMessage("Cancellation transaction exported to Epic successfully")
                            transaction.exportToEpicStatus = "Success";
                        })
                        .catch(handleError);
                }

                if (transaction.transactionType == TransactionType.Reinstatement) {
                    busyIndicator.promise = bondTransactionService.exportReinstatement(id)
                        .then(() => {
                            toastMessageCreator.createSuccessMessage("Reinstatement transaction exported to Epic successfully")
                            transaction.exportToEpicStatus = "Success";
                        })
                        .catch(handleError);
                }

                if (transaction.transactionType == TransactionType.Rider) {
                    busyIndicator.promise = bondTransactionService.exportRider(id)
                        .then(() => {
                            toastMessageCreator.createSuccessMessage("Rider transaction exported to Epic successfully")
                            transaction.exportToEpicStatus = "Success";
                        })
                        .catch(handleError);
                }

                if (transaction.transactionType == TransactionType.Correction) {
                    busyIndicator.promise = bondTransactionService.exportCorrection(id)
                        .then(() => {
                            toastMessageCreator.createSuccessMessage("Correction transaction exported to Epic successfully")
                            transaction.exportToEpicStatus = "Success";
                        })
                        .catch(handleError);
                }

                e.stopPropagation();
            });
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}

exportToEpicDirective.$inject = [Injectables.BondTransactionService, Injectables.ToastMessageCreator];

app.directive('exportToEpic', exportToEpicDirective);
