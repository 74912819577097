import { Injectables } from "../configuration/injectables";
import { GrantCustomerEProducerAccessRequest } from "../modals/grantCustomerEproducerAccessModal/grantCustomerEProducerAccessRequest";
import { AssignCustomerToAccountDto } from "./types/assignCustomerToAccountDto";
import { EProducerAccountListItem } from "../states/agentSpecific/eProducerBrokers/eProducerAccountListItem";
import { EProducerBrandingSettings } from "./types/eProducerBrandingSettings" ;
import { EProducerAccount } from "./types/model/eProducerAccount";
import { EProducerUser } from "./types/model/eProducerUser";
import { EProducerAccountFavoriteBondTypeConfiguration } from "./types/model/favoriteBondTypeConfiguration";
import { PaymentTransaction } from "./types/model/paymentTransaction";
import { PaymentStatus } from "./types/payments/paymentStatus";
import { RequestPasswordResetDto } from "./types/requestPasswordResetDto";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IHttpService, IQService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { SelectOption } from "./types/selectOption";
import EProducerAccountBrokerCategory from "./types/eProducerAccountBrokerCategory";
import { Person } from "./types/model/person";
import { PageResponse } from "./types/pageResponse";
import { CacheStore } from "./cacheStore";
import { BrokerBalances } from "../components/customerBalanceCard/brokerBalances";
import { BrokerDetail } from "../states/agentSpecific/eProducerBrokerDetail/eProducerAccountsDetailController";
import { QueryBuilderTableQueryOptions } from "./types/queryBuilderTableOptions";

export class EProducerService {
    public static $inject = [
        Injectables.$http,
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.CacheStore
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore
    ) {}

    public getBrokerBalances(eproducerAccountId: number): IPromise<BrokerBalances> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetBrokerBalances?eProducerAccountId=${eproducerAccountId}`;

        return this.$http.get<A3ApiResponse<BrokerBalances>>(url)
            .then((response) => response.data.value);
    }

    public getPeopleContactsForEProducerAccountId(eProducerAccountId:number): IPromise<Person[]> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetPeopleContactsForEProducerAccountId?eProducerAccountId=${eProducerAccountId}`;
        
        return this.$http.get<A3ApiResponse<Person[]>>(url)
            .then((response) => response.data.value);
    }

    public saveEproducerAccountContact(contact: Person): IPromise<number> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/AddPeopleContactsForEProducerAccount`;

        return this.$http.post<A3ApiResponse<number>>(url,contact)
            .then((response) => response.data.value);
    }

    public getPaymentTransactionsByEProducerAccountId(eProducerAccountId: number, onlyApprovedPayments: boolean): IPromise<PaymentTransaction[]> {
        let paymentStatus: PaymentStatus = null;

        if (onlyApprovedPayments) {
            paymentStatus = PaymentStatus.Approved;
        }

        const url = `${this.systemSettings.apiBaseUrl}"EProducerActions/GetPaymentTransactionsByEProducerAccountId?eProducerAccountId=${eProducerAccountId}&paymentStatus=${paymentStatus}`;

        return this.$http.get<A3ApiResponse<PaymentTransaction[]>>(url)
            .then((response) => response.data.value);
    }

    public assignCustomerToAccount(customerId: number, accountId: number): IPromise<void> {
        const request = {
            customerId: customerId,
            accountId: accountId
        } as AssignCustomerToAccountDto;

        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/AssignCustomerToEProducerAccount`;

        return this.$http.post(url, request)
            .then(() => {});
    }

    public assignCustomerToIndividualAccount(request: GrantCustomerEProducerAccessRequest): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/AssignCustomerToIndividualAccount`;

        return this.$http.post(url, request)
            .then(() => {});
    }

    public deleteAccount(account: EProducerAccountListItem): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/DeleteAccount`;

        return this.$http.post(url, { accountId: account.id })
            .then(() => {});
    }

    public deleteContact(id: number): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/DeleteContact?id=${id}`;

        return this.$http.delete(url)
            .then(() => { });
    }

    public saveFavoriteBondTypeConfiguration(favoriteConfiguration: EProducerAccountFavoriteBondTypeConfiguration): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SaveFavoriteBondTypeConfiguration`;
        
        return this.$http.post(url, favoriteConfiguration)
            .then(() => {});
    }

    public getFavoriteBondTypeConfiguration(eProducerAccountId: number): IPromise<EProducerAccountFavoriteBondTypeConfiguration> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetFavoriteBondTypeConfiguration?eProducerAccountId=${eProducerAccountId}`;
        
        return this.$http.get<A3ApiResponse<EProducerAccountFavoriteBondTypeConfiguration>>(url)
            .then(response => response.data.value);
    }

    public getBranding(eProducerAccountId: number): IPromise<EProducerBrandingSettings> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetBranding?EProducerAccountId=${eProducerAccountId}`;

        return this.$http.get<A3ApiResponse<EProducerBrandingSettings>>(url)
            .then(response => response.data.value);
    }

    public createBrokerAccount(account: BrokerDetail): IPromise<number> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/CreateBrokerAccount`
        
        return this.$http.post<A3ApiResponse<number>>(url, account)
            .then((response) => response.data.value); 
    }

    public deleteUser(eProducerAccountId: number): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/DeleteUser`;

        return this.$http.post(url, eProducerAccountId)
            .then(() => {});
    }
    
    public getUsers(eProducerAccountId: number): IPromise<EProducerUser[]> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetUsers?eProducerAccountId=${eProducerAccountId}`;

        return this.$http.get<A3ApiResponse<EProducerUser[]>>(url)
            .then(response => response.data.value);
    }

    public getEProducerAccount(eProducerAccountId: number): IPromise<BrokerDetail> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEProducerAccountDetail?eProducerAccountId=${eProducerAccountId}`;
        
        return this.$http.get<A3ApiResponse<BrokerDetail>>(url)
            .then((response) => response.data.value);
    }

    public getBrokerCateogryTypeOptions(): IPromise<SelectOption<number>[]> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEProducerAccountBrokerCategories`;

        return this.$http.get<A3ApiResponse<EProducerAccountBrokerCategory[]>>(url)
            .then(response => {
                return response.data.value.map(category => {
                    return { 
                        label: category.name,
                        value: category.id
                    }
                });
            });
    }

    public getUser(userId: number): IPromise<EProducerUser> {
        const url = `${this.systemSettings.apiBaseUrl}/EProducerActions/GetUser?userId=${userId}`;

        return this.$http.get<A3ApiResponse<EProducerUser>>(url)
            .then(response => response.data.value);
    }

    public insertUser(user: EProducerUser): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/InsertUser`;

        return this.$http.post(url, user)
            .then(() => {});
    }

    public resetUserPassword(email: string): IPromise<void> {
        if (!email) {
            throw new Error("email is not valid");
        }

        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/RequestPasswordReset`;
        const request = { email: email } as RequestPasswordResetDto;

        return this.$http.post(url, request)
            .then(() => {})
            .catch((response) => {
                throw new Error(response.data.Message);
            });
    }

    public sendBrokerInvite(emailAddress: string): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SendBrokerInvite?emailAddress=${emailAddress}`;

        return this.$http.get(url)
            .then(() => {});
    }

    public getEproducerBrokerAccountsList(tableQueryOptions: QueryBuilderTableQueryOptions): IPromise<PageResponse<EProducerAccountListItem>> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEproducerBrokerAccountsList`;

        return this.$http.post<A3ApiResponse<PageResponse<EProducerAccountListItem>>>(url, tableQueryOptions)
            .then((response) => response.data.value);
    }

    public saveBranding(brandingSettings: EProducerBrandingSettings): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SaveBrandingSettings`;

        return this.$http.post(url, brandingSettings)
            .then(() => {
                this.cacheStore.clearCacheContainingKey('Download/EProducerAccountLogo');
            });
    }

    public searchBrokers(search: string): IPromise<EProducerAccount[]> {
        if (!search) {
            return this.$q.when([] as EProducerAccount[]);
        }

        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SearchBrokers?search=${search}`;

        return this.$http.get<A3ApiResponse<EProducerAccount[]>>(url)
            .then((response) => response.data.value);
    }

    public updateEProducerAccount(brokerDetail: BrokerDetail): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/UpdateBrokerDetail`;

        return this.$http.post(url, brokerDetail)
            .then(() => {});
    }

    public updateUser(user: EProducerUser): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/UpdateUser`;

        return this.$http.post(url, user)
            .then(() => {});
    }

    public getSmallLogo(id: number): IPromise<string> {
        return this.getLogo(id, EProducerAccountLogoSize.Small);
    }

    public getLargeLogo(id: number): IPromise<string> {
        return this.getLogo(id, EProducerAccountLogoSize.Large);
    }

    private getLogo(id: number, size: EProducerAccountLogoSize): IPromise<string> {
        const url = `${this.systemSettings.apiBaseUrl}Download/EProducerAccountLogo?size=${size}&eProducerAccountId=${id}`;
        
        return this.cacheStore.getData(url, () => {
            return this.$http
                .get(url)
                .then((img) => img.data as string);
        });
    }
}

enum EProducerAccountLogoSize {
    Small = "sm",
    Large = "lg"
}

app.service(Injectables.EProducerService, EProducerService);
