
import { SystemAccountService } from "../../../api/systemAccountService";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { IPromise, IQService } from "angular";
import { IStateService } from "../../../../typeDefinitions/uiRouter";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";

export class CarrierDetailController {

    public static $inject = [
        Injectables.$stateParams,
        Injectables.$q,
        Injectables.SystemAccountService,  
        Injectables.ToastMessageCreator,
        Injectables.$state
    ];

    constructor(
        private readonly $stateParams: { carrierSystemAccountId: number },
        private readonly $q: IQService,
        private readonly systemAccountService: SystemAccountService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly $state: IStateService) { }

    public carrier: AgencyCarrierDetail;
    public smLogoPicturePath: string;
    public busyIndicator: BusyIndicator;
 
    public setLogoPicture(): IPromise<void> {

        if (this.carrier.thumbnailLogoFileId) {
            return this.systemAccountService.getSmallLogo(this.carrier.id)
                .then((imgData) => {
                    this.smLogoPicturePath = imgData;
                });
        }

        return this.$q.when();
    }

    public submit(): void {
        this.busyIndicator.message = "Saving...";
        this.busyIndicator.promise = this.systemAccountService.updateAgencyCarrier(this.carrier)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your company information was saved successfully');
                this.$state.go('main.agencyCarriers');
            }).catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to save your company information');
            });
    }

    private loadCarrier(carrierSystemAccountId: number): IPromise<void> {
        return this.systemAccountService.loadAgencyCarrierDetail(carrierSystemAccountId) 
            .then((account) => {
                this.carrier = account;
            })
            .then(() => this.setLogoPicture());
    }
    
    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.loadCarrier(this.$stateParams.carrierSystemAccountId)
        }
    }
}

export type AgencyCarrierDetail = {
    id: number;
    companyName: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    createdDateTime: Date;
    websiteURL: string;
    thumbnailLogoFileId: number;
    writingCompanies: AgencyCarrierDetailWritingCompany[];
}

export type AgencyCarrierDetailWritingCompany = {
    id: number;
    name: string;
    epicPremiumPayableCode: string;
    epicIssuingCompanyCode: string;
	systemAccountId: number;
}
