import app from "../../main";
import { Injectables } from "../injectables";

export class SystemSettings {
    public readonly odataBaseUrl: string = "https://a3api-qa.azurewebsites.net/";
    public readonly apiBaseUrl: string = "https://a3api-qa.azurewebsites.net/api/";
    public readonly authBaseUrl: string = "https://a3api-qa.azurewebsites.net/";
    public readonly documentEditorBaseUrl: string = "https://docedit.agencymultiplied.com/";
    public readonly a3WebBaseUrl: string = "https://a3web-qa.azurewebsites.net";
    public readonly ajgSsoLoginUrl: string = 'https://a3web-qa.azurewebsites.net/auth/Ajg/Login';
    public readonly environment: string = "QA";
}

app.constant(Injectables.SystemSettings, new SystemSettings());
