import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { IQueryStringBuilder } from "../utilities/queryStringBuilder/iQueryStringBuilder";
import A3ApiResponse from "./types/a3ApiResponse";
import { CommissionOptionFetcherParameters } from "./types/commissionOptionFetcherParameters";
import { CommissionOptionFetcherResult } from "./types/commissionOptionFetcherResult";
import { CacheStore } from "./cacheStore";
import { CommissionType } from "./types/model/commissionType";

export class QuoteApi {
    public static $inject = [
        Injectables.$http,
        Injectables.QueryStringBuilder,
        Injectables.SystemSettings,
        Injectables.CacheStore
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly queryStringBuilder: IQueryStringBuilder,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore) {
    }

    public getCommissionOptions(parameters: CommissionOptionFetcherParameters): IPromise<CommissionOptionFetcherResult[]> {
        const queryString = this.queryStringBuilder.buildQueryString(parameters);
        const url = `${this.systemSettings.apiBaseUrl}QuoteActions/GetCommissionOptions${queryString}`;
    
        return this.cacheStore.getData(url, () => {
                return this.$http
                    .get<A3ApiResponse<CommissionOptionFetcherResult[]>>(url)
                    .then((response) => {
                        var options = response.data.value;

                        options.push({
                            label: 'Fixed Amount',
                            commissionType: CommissionType.Fixed,
                        } as CommissionOptionFetcherResult);
        
                        options.push({
                            label: 'Other Percent',
                            commissionType: CommissionType.Other,
                        } as CommissionOptionFetcherResult);

                        return options;
                    });
        });
    }
}

app.service(Injectables.QuoteApi, QuoteApi);