enum QueryBuilderOperator {
    Equals = 'Equals',
    NotEqual = 'NotEqual',
    GreaterThan = 'GreaterThan',
    LessThan = 'LessThan',
    GreaterOrEqual = 'GreaterOrEqual',
    LessOrEqual = 'LessOrEqual',
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    Contains = 'Contains',
    Between = 'Between',
    In = 'In',
    Blank = 'Blank',
    NotStartsWith = 'NotStartsWith',
    NotEndsWith = 'NotEndsWith',
    NotContains = 'NotContains',
    NotBetween = 'NotBetween',
    NotIn = 'NotIn',
    NotBlank = 'NotBlank',
    IsTrue = 'IsTrue',
    NotTrue = 'NotTrue',
    IsMe = 'IsMe',
    IsNotMe = 'IsNotMe'
} 

export default QueryBuilderOperator;