export interface EProducerUser {
    id: number; 
    eProducerAccountId: number;
    email: string;
    fax: string;
    firstName: string;
    jobTitle: string;
    lastLoginDate: Date;
    lastName: string;
    password: string;
    passwordHash: string;
    passwordResetKey: string;
    phone: string;
    phoneExtension: string;
    secondaryPhone: string;
    secondaryPhoneExtension: string;
    additionalComments: string;
    createdDateTime: Date;
}
