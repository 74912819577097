import { EProducerService } from "../../api/eProducerService";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export class BrokerCategoryDropdownController {

    public static $inject = [
        Injectables.EProducerService
    ];

    constructor(
        private readonly eproducerService: EProducerService
    ) {}

    public allowUnselected: string;
    public unselectedText: string;
    public ngRequired: boolean;
    public ngChange: () => void;
    public ngModel: number;
    public options: SelectOption<number>[];
    
    private loadOptions() {
        return this.eproducerService.getBrokerCateogryTypeOptions()
            .then((options) => {
                this.options = options;
            });
    }

    public changed = () => {
        if (!this.ngModel) {
            this.ngModel = null;
        }

        if (this.ngChange instanceof Function) {
            this.ngChange();
        }
    }

    public $onInit() {
        this.loadOptions();
    }
}

const component = {
    bindings: {
        label: '@',
        class: '@?',
        ngModel: '=',
        ngChange: '&?',
        allowUnselected: '@?',
        unselectedText: '@?',
        ngRequired: '<?'
    },
    controller: BrokerCategoryDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/brokerCategoryDropdown/brokerCategoryDropdown.html'
};

app.component('brokerCategoryDropdown', component);