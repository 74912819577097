import { IPromise, IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { CacheStoreOptions } from "./types/cacheStoreOptions";
import { LocalStorageService } from "../utilities/localStorage/localStorageService";

export class CacheStore {

    public static $inject = [
        Injectables.$q,
        Injectables.LocalStorageService,
    ];

    private readonly defaultOptions: CacheStoreOptions = {
        persist: false,
        timeToLive: 86400000 // 1 day
    }

    constructor(
            private readonly $q: IQService,
            private readonly localStorageService: LocalStorageService) {
        
        this.cache = {};

        const persistedCache = localStorageService.getAllCacheData();

        for (let i = 0; i < persistedCache.length; i++) {
            const item = persistedCache[i];
            const promise = $q.when(item.data);
            
            this.cache[item.key] = promise;
        }
    }

    private cache: object;

    public getData = (url: string, fetchFunction: () => IPromise<any>, options: CacheStoreOptions = null) => {
        if(!options) {
            options = this.defaultOptions;
        }

        if(this.cache[url]) {
            return this.cache[url];
        }

        let promise = fetchFunction()
            .catch(() => this.clearSingle(url));

        this.cache[url] = promise;

        if (options?.persist) {
            this.persistCache(url, promise, options);
        }

        return promise;
    }

    private persistCache(url: string, promise: IPromise<any>, options: CacheStoreOptions) {
        promise.then(response => {
            this.localStorageService.setCachedData(url, response, options.timeToLive);
        });
    }

    public getCacheKeys = (): string[] => {
        const keys: string[] = [];
        for (const key in this.cache) {
            if (this.cache.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        return keys;
    }

    public clearCacheContainingKey = (keySearch: string) => {
        const cacheKeys = this.getCacheKeys();

        for (let i = 0; i < cacheKeys.length; i++) {
            const cacheKey = cacheKeys[i];
            if (cacheKey.toLowerCase().includes(keySearch.toLowerCase())) {
                this.clearSingle(cacheKey);
            }
        }
    }

    public clearSingle = (url: string) => {
        delete this.cache[url];
    }

    public clearAll = () => {
        this.cache = {};
    }
}

app.service(Injectables.CacheStore, CacheStore);
