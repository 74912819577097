import { Injectables } from "../configuration/injectables";
import { CarrierApiConfiguration } from "../states/carrierSpecific/carrierApi/carrierApiConfiguration";
import { ODataFactory, ODataEndpoint } from "./odata";
import { BondNumberGroup } from "./types/model/bondNumberGroup";
import { CancellationReason } from "./types/model/cancellationReason";
import { CarrierPageItem} from "../states/agentSpecific/carriers/carrierPageItem";
import { EpicContract } from "./types/model/epicContract";
import { PaymentMethod } from "./types/model/paymentTransaction";
import { ReinstatementReason } from "./types/model/reinstatementReason";
import { RiderReason } from "./types/model/riderReason";
import { SystemAccount } from "./types/model/systemAccount";
import { User } from "./types/model/user";
import { WritingCompany } from "./types/model/writingCompany";
import { SelectOption, OptionGroup } from "./types/selectOption";
import { CacheFactory } from "./types/cacheFactory";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IHttpService, IFilterService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../utilities/currentUserResolver/currentUserResolver";
import SystemAccountOption from "./types/systemAccounts/systemAccountOption";
import { CacheStore } from "./cacheStore";
import { PageResponse } from "./types/pageResponse";
import { CarrierPageQueryOptions } from "../states/agentSpecific/carriers/carrierPageQueryOptions";
import { AgencyCarrierDetail } from "../states/agentSpecific/carrierDetail/carrierDetailController";

export class SystemAccountService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.CurrentUserResolver,
        Injectables.CacheFactory,
        Injectables.$filter,
        Injectables.SystemSettings,
        Injectables.CacheStore
    ];

    // create cache to be used for odata when specified
    // https://github.com/jmdobry/angular-cache
    public legacyCacheStore = this.CacheFactory("systemAccount", {});

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly CacheFactory: CacheFactory,
        private readonly $filter: IFilterService,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore
    ) {}

    public saveBondNumberGroup(
        bondNumberGroup: BondNumberGroup
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/SaveBondNumberGroup",
                bondNumberGroup
            )
            .then(() => {});
    }

    public getBondNumberGroupById(
        bondNumberGroupId: number
    ): IPromise<BondNumberGroup> {
        return this.$http
            .get<A3ApiResponse<BondNumberGroup>>(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/GetBondNumberGroupById?bondNumberGroupId=" +
                    bondNumberGroupId
            )
            .then((response) => response.data.value);
    }

    public getDefaultMinimumPremiumByWritingCompanyId(
            writingCompanyId: number
    ) : IPromise<number> {
        return this.$http
            .get<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/GetDefaultMinimumPremium?writingCompanyId="+
                    writingCompanyId
            )
            .then((response) => response.data.value ) ;
    }


    public patchCarrier(
        carrierId: number,
        carrier: SystemAccount
    ): IPromise<void> {
        const svc = this.odata.getService(ODataEndpoint.SystemAccount);

        return svc
            .patch(carrierId, carrier)
            .then(() => {});
    }

    public loadAgencyData(systemAccountId: number): IPromise<SystemAccount> {
        return this.$http
            .get<A3ApiResponse<SystemAccount>>(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/GetSystemAgencyDetail?systemAccountId=" +
                    systemAccountId
            )
            .then((response) => response.data.value);
    }

    public getAgencyCarriers(queryOptions: CarrierPageQueryOptions): IPromise<PageResponse<CarrierPageItem>> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetSystemAgencyCarriers${this.buildAgencCarrierListQueryString(queryOptions)}`;

        return this.$http.get<A3ApiResponse<PageResponse<CarrierPageItem>>>(url)
            .then((response) => response.data.value);
    }

    private buildAgencCarrierListQueryString(tableQueryOptions: CarrierPageQueryOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            };
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        } else {
            tableQueryOptions.searchPhrase =
                tableQueryOptions.searchPhrase.replace(/&/g, "%26");
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "CarrierName";
        }

        let queryString = "?";

        queryString += `pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        return queryString;
    }

    public loadCarrierData(systemAccountId: number): IPromise<SystemAccount> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );
        svc.query.filterById(systemAccountId);

        svc.query.expand(
            "writingCompanies($expand=powerOfAttorneyDocument),systemAccountContacts,cancellationReasons,riderReasons,reinstatementReasons,systemAccountDocuments($expand=document)"
        );

        return svc
            .get()
            .then((data) => data.data.value[0]);
    }

    public save(agencyId: number, agency: SystemAccount): IPromise<void> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        return svc
            .put(agencyId, agency)
            .then(() => {});
    }

    public isEpicIntegrationEnabled(): IPromise<boolean> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/IsEpicIntegrationEnabled`;

        return this.cacheStore.getData(url, () => {
            return this.$http
                .get<A3ApiResponse<boolean>>(url)
                .then((response) => response.data.value);
        });
    }

    public getBrokerCommissionContractOptions(brokerLookupCode: string, profitCenterCode: string): IPromise<SelectOption<number>[]> {
        const url = `${this.systemSettings.apiBaseUrl}EpicActions/GetBrokerCommissionContracts`;

        return this.$http
            .post<A3ApiResponse<EpicContract[]>>(url, {
                profitCenter: profitCenterCode,
                brokerLookupCode: brokerLookupCode
            })
            .then((response) => {
                const options: SelectOption<number>[] = [];

                for (let i = 0; i < response.data.value.length; i++) {
                    options.push({
                        value: response.data.value[i].contractId,
                        label: response.data.value[i].description
                    });
                }

                return options;
            });
    }

    public getPremiumPayableContracts(
        premiumPayableLookupCode: string,
        profitCenterCode: string
    ): IPromise<SelectOption<number>[]> {
        const url =
            this.systemSettings.apiBaseUrl +
            "EpicActions/GetPremiumPayableContracts";

        return this.$http
            .post<A3ApiResponse<EpicContract[]>>(url, {
                profitCenter: profitCenterCode,
                premiumPayableCode: premiumPayableLookupCode
            })
            .then((response) => {
                const options: SelectOption<number>[] = [];

                for (let i = 0; i < response.data.value.length; i++) {
                    options.push({
                        value: response.data.value[i].contractId,
                        label: response.data.value[i].description
                    });
                }

                return options;
            });
    }

    // todo remove
    public isAms360IntegrationActive(): IPromise<boolean> {
        const systemAccountId = this.currentUserResolver.getCurrentUser().user.systemAccountId;
        
        return this.getSystemAccount(systemAccountId)
            .then((systemAccount) => systemAccount.ams360IntegrationIsActive);
    }

    public getAppointedCarriers(): IPromise<SystemAccountOption[]> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetAppointedCarriers`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<SystemAccountOption[]>>(url)
                .then((response) => response.data.value);
        });
    }

    public getAppointedAgencies(): IPromise<SystemAccountOption[]> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetAppointedAgencies`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<SystemAccountOption[]>>(url)
                .then((response) => response.data.value);
        }); 
    }

    public getAppointedCarrierOptions(): IPromise<SelectOption<number>[]> {
        return this.getAppointedCarriers()
            .then((carriers) => carriers.map((carrier) => {
                    return {
                        label: carrier.companyName,
                        value: carrier.id
                    };
                })
            );
    }

    public getAppointedAgencyOptions(): IPromise<SelectOption<number>[]> {
        return this.getAppointedAgencies()
            .then((agencies) => agencies.map((agency) => {
                    return {
                        label: agency.companyName,
                        value: agency.id
                    };
                })
            );
    }

    public getSystemAccount(systemAccountId: number): IPromise<SystemAccount> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );
        svc.query.filterById(systemAccountId);

        return svc.get()
            .then((response) => response.data.value[0]);
    }

    public async getAttorneyInFactOptions(bondId?: number) : Promise<SelectOption<number>[]> {
        
        let aifOptions: SelectOption<number>[];
        
        if (this.currentUserResolver.getCurrentUser().systemAccount.isCarrier) {
            aifOptions = await this.getCarrierAttorneyInFactOptions(bondId);
        } else {
            aifOptions = await this.getAgencyAttorneyInFactOptions();
        }

        return aifOptions;
    }

    public getAgencyAttorneyInFactOptions(): IPromise<SelectOption<number>[]> {
        const svc = this.odata.getService<User>(ODataEndpoint.User,this.legacyCacheStore);

        svc.query.filter("isAttorneyInFact eq true");
        svc.query.select("id,firstName,lastName");
        svc.query.orderby("firstName,lastName") ;

        const aifOptions: SelectOption[] = [];

        return svc.get()
            .then((response) => {
                for (const aifOption of response.data.value) {
                    aifOptions.push({
                        label: aifOption.firstName + " " + aifOption.lastName,
                        value: aifOption.id
                    });
                }

                return aifOptions;
            });
    }

    public getProducerUserOptions(): IPromise<SelectOption<number>[]> {
        const svc = this.odata.getService<User>(ODataEndpoint.User,this.legacyCacheStore);

        svc.query.filter("isProducer eq true");
        svc.query.select("id,firstName,lastName");
        svc.query.orderby("firstName,lastName") ;

        const producerOptions: SelectOption[] = [];

        return svc.get()
            .then((response) => {
                for (const producerOption of response.data.value) {
                    producerOptions.push({
                        label:
                            producerOption.firstName +
                            " " +
                            producerOption.lastName,
                        value: producerOption.id
                    });
                }

                return producerOptions;
            });
    }

    public getClientServiceExecutiveUserOptions(): IPromise<
        SelectOption<number>[]
    > {
        const svc = this.odata.getService<User>(ODataEndpoint.User,this.legacyCacheStore);

        svc.query.filter("isClientServiceExecutive eq true");
        svc.query.select("id,firstName,lastName");
        svc.query.orderby("firstName,lastName") ;

        const options: SelectOption[] = [];

        return svc.get()
            .then((response) => {
                for (const option of response.data.value) {
                    options.push({
                        label: option.firstName + " " + option.lastName,
                        value: option.id
                    });
                }

                return options;
            });
    }

    public getClientServiceAgentUserOptions(): IPromise<
        SelectOption<number>[]
    > {
        const svc = this.odata.getService<User>(ODataEndpoint.User, this.legacyCacheStore);

        svc.query.filter("isClientServiceAgent eq true");
        svc.query.select("id,firstName,lastName");
        svc.query.orderby("firstName,lastName") ;

        const options: SelectOption[] = [];

        return svc.get()
            .then((response) => {
                for (const option of response.data.value) {
                    options.push({
                        label: option.firstName + " " + option.lastName,
                        value: option.id
                    });
                }

                return options;
            });
    }

    public getClientServiceManagerUserOptions(): IPromise<SelectOption<number>[]> {
        const svc = this.odata.getService<User>(ODataEndpoint.User, this.legacyCacheStore);

        svc.query.filter("isClientServiceManager eq true");
        svc.query.select("id,firstName,lastName");
        svc.query.orderby("firstName,lastName") ;

        const clientServiceManagerOptions: SelectOption[] = [];

        return svc.get()
            .then((response) => {
                for (const clientServiceManagerOption of response.data.value) {
                    clientServiceManagerOptions.push({
                        label:
                            clientServiceManagerOption.firstName +
                            " " +
                            clientServiceManagerOption.lastName,
                        value: clientServiceManagerOption.id
                    });
                }

                return clientServiceManagerOptions;
            });
    }

    public getCarrierAttorneyInFactOptions(
        bondId: number
    ): IPromise<SelectOption<number>[]> {
        return this.$http
            .get<A3ApiResponse<User[]>>(
                this.systemSettings.apiBaseUrl +
                    "UserActions/GetCarrierAifUsers?bondId=" +
                    bondId
            )
            .then((response) => {
                const aifOptions: SelectOption[] = [];
                for (const aifOption of response.data.value) {
                    aifOptions.push({
                        label:
                            aifOption.firstName +
                            " " +
                            aifOption.lastName +
                            " - " +
                            aifOption.systemAccount.companyName,
                        value: aifOption.id
                    });
                }

                return aifOptions;
            });
    }

    public getDefaultAttorneyInFactUserId(aifOptions: SelectOption[]): number {

        // if only one aif option
        if (aifOptions && aifOptions.length === 1) {
            return aifOptions[0].value;
        }

        for (const aifOption of aifOptions) {
            // if current user is AIF user then select it
            if (aifOption.value === this.currentUserResolver.getCurrentUser().user.userId) {
                return aifOption.value;
            }
        }

        return this.currentUserResolver.getCurrentUser().systemAccount.defaultAttorneyInFactUserId;
    }

    public getHasBondNumberIntegration(id: number): IPromise<boolean> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        svc.query.filterById(id);
        svc.query.select("bondNumberIntegrationType");

        return svc
            .get()
            .then(
                (response) =>
                    response.data.value[0].bondNumberIntegrationType != null
            );
    }

    public saveCarrierSystemAccountSystemSettings(
        systemAccount: SystemAccount
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.odataBaseUrl +
                    "SystemAccountActions/SaveCarrierSystemSettings",
                systemAccount
            )
            .then(() => {});
    }

    public saveCarrierDocuments(systemAccount: SystemAccount): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.odataBaseUrl +
                    "SystemAccountActions/SaveCarrierDocuments",
                systemAccount
            )
            .then(() => {});
    }

    public getExpeditedProcessingFee(
        systemAccountId: number
    ): IPromise<number> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        svc.query.filterById(systemAccountId);
        svc.query.select("expeditedProcessingFee");

        return svc
            .get()
            .then((response) => response.data.value[0].expeditedProcessingFee);
    }

    public getCancellationReasons(
        systemAccountId: number
    ): IPromise<CancellationReason[]> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        svc.query.filterById(systemAccountId);
        svc.query.select("cancellationReasons");
        svc.query.expand("cancellationReasons");

        return svc
            .get()
            .then((response) => response.data.value[0].cancellationReasons);
    }

    public getPaymentMethods(
        systemAccountId: number
    ): IPromise<SelectOption<PaymentMethod>[]> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        svc.query.filterById(systemAccountId);
        svc.query.select(
            "allowCCPaymentsThroughA3,allowACHPaymentsThroughA3,allowOtherPaymentsThroughA3"
        );

        return svc.get().then((response) => {
            const paymentMethods: SelectOption<PaymentMethod>[] = [];

            if (response.data.value[0].allowCCPaymentsThroughA3) {
                paymentMethods.push({
                    label: "Credit Card",
                    value: PaymentMethod.CreditCard
                });
            }
            
            if (response.data.value[0].allowACHPaymentsThroughA3) {
                paymentMethods.push({
                    label: "Electonic Check (ACH)",
                    value: PaymentMethod.ACH
                });
            }
            
            paymentMethods.push({
                label: "Check",
                value: "Check" as PaymentMethod //PaymentMethod.Check
            });

            paymentMethods.push({
                label: "Wire Transfer",
                value: "WireTransfer" as PaymentMethod//PaymentMethod.WireTransfer
            });

            if (response.data.value[0].allowOtherPaymentsThroughA3) {
                paymentMethods.push({
                    label: "Other",
                    value: PaymentMethod.Other
                });
            }

            if (this.currentUserResolver.getCurrentUser().permissions.canReturnPayments) {
                paymentMethods.push({
                    label: "Return",
                    value: PaymentMethod.Return
                });
            }

            return paymentMethods;
        });
    }

    public getRiderReasons(systemAccountId: number): IPromise<RiderReason[]> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        svc.query.filterById(systemAccountId);
        svc.query.select("riderReasons");
        svc.query.expand("riderReasons");

        return svc
            .get()
            .then((response) => response.data.value[0].riderReasons);
    }

    public getReinstatementReasons(systemAccountId: number): IPromise<ReinstatementReason[]> {
    
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        svc.query.filterById(systemAccountId);
        svc.query.select("reinstatementReasons");
        svc.query.expand("reinstatementReasons");

        return svc
            .get()
            .then((response) => response.data.value[0].reinstatementReasons);
    }

    public getSmallLogo(systemAccountId: number): IPromise<string> {
        return this.getLogo(systemAccountId, SystemAccountLogoSize.Small);
    }

    public getLargeLogo(systemAccountId: number): IPromise<string> {
        return this.getLogo(systemAccountId, SystemAccountLogoSize.Large);
    }

    public updateAgencyCarrier(carrier: AgencyCarrierDetail): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/UpdateAgencyCarrier`;

        return this.$http.post(url, carrier)
            .then(() => {});
    }

    public loadAgencyCarrierDetail(carrierSystemAccountId: number): IPromise<AgencyCarrierDetail> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetAgencyCarrierDetail?carrierSystemAccountId=${carrierSystemAccountId}`;

        return this.cacheStore.getData(url, () => { 
            return this.$http.get<A3ApiResponse<AgencyCarrierDetail>>(url)
                .then((response) => response.data.value);
        });
    }

    public getWritingCompanies(systemAccountId: number): IPromise<WritingCompany[]> {
            const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetWritingCompanies?systemAccountId=${systemAccountId}`;

            return this.cacheStore.getData(url, () => { 
                return this.$http
                    .get<A3ApiResponse<WritingCompany[]>>(url)
                    .then((response) => {
                        const writingCompanies = response.data.value;

                        for (const writingCompany of writingCompanies) {
                            writingCompany.$minimumPremiumInheritedText = this.$filter("currency")(writingCompany.defaultMinimumPremium, "$") + " (Inherited from writing company)";
                        }

                        return writingCompanies;
                    });
            });
    }

    public getWritingCompanyOptions(): IPromise<SelectOption<number>[]> {
        const svc = this.odata.getService<WritingCompany>(ODataEndpoint.WritingCompany, this.legacyCacheStore);

        svc.query.select("id,name");
        svc.query.orderby("name");

        return svc.get()
            .then((response) => {
                const writingCompanyOptions: SelectOption[] = [];

                for (const writingCompany of response.data.value) {
                    writingCompanyOptions.push({
                        label: writingCompany.name,
                        value: writingCompany.id
                    });
                }

                return writingCompanyOptions;
            });
    }

    public getWritingCompanyOptionGroups(): IPromise<OptionGroup<number>[]> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetCarriersWithWritingCompanies`;

        return this.cacheStore.getData(url, () => {
            return this.$http
                .get(url)
                .then((response) => {
                    const groups: OptionGroup<number>[] = [];
                    const carriers = (response.data as any).value;

                    for (let i = 0; i < carriers.length; i++) {
                        const newGroup = {
                            label: carriers[i].name,
                            value: carriers[i].id,
                            options: []
                        } as OptionGroup<number>;

                        for (
                            let j = 0;
                            j < carriers[i].writingCompanies.length;
                            j++
                        ) {
                            newGroup.options.push({
                                label: carriers[i].writingCompanies[j].name,
                                value: carriers[i].writingCompanies[j].id
                            });
                        }

                        groups.push(newGroup);
                    }

                    return groups;
                });
        });
    }

    public getWritingCompanyOptionGroupsByCarrierId(carrerId: number): IPromise<OptionGroup<number>[]> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetCarriersWithWritingCompaniesByCarrierId?carrierId=${carrerId}`;

        return this.cacheStore.getData(url, () => {
            return this.$http
                .get(url)
                .then((response) => {
                    const groups: OptionGroup<number>[] = [];
                    const carriers = (response.data as any).value;

                    for (let i = 0; i < carriers.length; i++) {
                        const newGroup = {
                            label: carriers[i].name,
                            value: carriers[i].id,
                            options: []
                        } as OptionGroup<number>;

                        for (
                            let j = 0;
                            j < carriers[i].writingCompanies.length;
                            j++
                        ) {
                            newGroup.options.push({
                                label: carriers[i].writingCompanies[j].name,
                                value: carriers[i].writingCompanies[j].id
                            });
                        }

                        groups.push(newGroup);
                    }

                    return groups;
                });
            });
    }

    public getBondNumberGroups(systemAccountId: number): IPromise<BondNumberGroup[]> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetBondNumberGroups?systemAccountId=${systemAccountId}`;

        return this.cacheStore.getData(url, () => {
            return this.$http
                .get<A3ApiResponse<BondNumberGroup[]>>(url)
                .then((response) => response.data.value);
            });
    }

    public getCarriersThatAllowEditingQuotes(): IPromise<SelectOption[]> {
        const url = `${this.systemSettings.apiBaseUrl}QuoteActions/GetCarrierOptionsForAddingQuotes`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<SelectOption[]>>(url)
            .then((response) => response.data.value);
        });
    }

    private getLogo(systemAccountId: number,size: SystemAccountLogoSize): IPromise<string> {
        const url = `${this.systemSettings.apiBaseUrl}Download/SystemAccountLogo?size=${size}&systemAccountId=${systemAccountId}`;

        return this.cacheStore.getData(
            url, 
            () => this.$http.get(url).then((img) => img.data as string),
            { persist: true }
        );
    }

    public loadCarrierApiConfiguration(): IPromise<CarrierApiConfiguration> {
        return this.$http
            .get<A3ApiResponse<CarrierApiConfiguration>>(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/GetCarrierApiConfiguration"
            )
            .then((response) => response.data.value);
    }

    public saveCarrierApiConfiguration(
        configuration: CarrierApiConfiguration
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/SaveCarrierApiConfiguration",
                configuration
            )
            .then(() => {});
    }

    public createApiAuthenticationToken(): IPromise<string> {
        return this.$http
            .post<A3ApiResponse<string>>(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/CreateApiAuthenticationToken",
                null
            )
            .then((response) => response.data.value);
    }

    public deactivateCarrierApiAuthenticationToken(): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountActions/DeactivateCarrierApiAuthenticationToken",
                null
            )
            .then(() => {});
    }
}

enum SystemAccountLogoSize {
    Small = "sm",
    Large = "lg"
}

app.service(Injectables.SystemAccountService, SystemAccountService);