import { QuoteApi } from "../../api/quoteApi";
import { CommissionOptionFetcherParameters } from "../../api/types/commissionOptionFetcherParameters";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CommissionType } from "../../api/types/model/commissionType";
import { IPromise, IQService } from "angular";
import { CommissionOptionFetcherResult } from "../../api/types/commissionOptionFetcherResult";

export class CommissionDropdownController {
    public static $inject = [
        Injectables.QuoteApi,
        Injectables.$q
    ];

    constructor(
        private readonly quoteApi: QuoteApi,
        private readonly $q: IQService
    ) { }

    public controller: CommissionDropdownController;

    public bondTypeId: number;
    public writingCompanyId: number;
    public bondId: number;
    public agencySystemAccountId: number;
    public carrierSystemAccountId: number;

    public commissionDropdownOptions: SelectOption<number>[];
    public commissionOptions: CommissionOptionFetcherResult[];
    public selectedCommissionOption: CommissionOptionFetcherResult;
    public selectedCommissionOptionIndex: number;
    public commissionPercent: number;
    public commissionFixedAmount: number;
    public commissionType: CommissionType;
    public ngDisabled: boolean;

    public commissionPercentOptionChanged() {
        this.setCommissionOptionDropdown(this.selectedCommissionOptionIndex);
        this.setValues();
    }

    public getCommissionOptions(updatedParameters?: CommissionOptionFetcherParameters): IPromise<void> {
        let proceed = true;

        if (updatedParameters) {
            proceed = false;

            if (this.bondId != updatedParameters.bondId) {
                this.bondId = updatedParameters.bondId;
                proceed = true;
            }
            
            if (this.writingCompanyId != updatedParameters.writingCompanyId) {
                this.writingCompanyId = updatedParameters.writingCompanyId;
                proceed = true;
            }

            if (this.bondTypeId != updatedParameters.bondTypeId) {
                this.bondTypeId = updatedParameters.bondTypeId;
                proceed = true;
            }
            
            if (this.carrierSystemAccountId != updatedParameters.carrierSystemAccountId) {
                this.carrierSystemAccountId = updatedParameters.carrierSystemAccountId;
                proceed = true;
            }

            if (this.agencySystemAccountId != updatedParameters.agencySystemAccountId) {
                this.agencySystemAccountId = updatedParameters.agencySystemAccountId;
                proceed = true;
            }
        }

        if (!proceed) {
            return this.$q.when();
        }

        return this.quoteApi
            .getCommissionOptions({
                bondId: this.bondId,
                writingCompanyId: this.writingCompanyId,
                bondTypeId: this.bondTypeId,
                carrierSystemAccountId: this.carrierSystemAccountId,
                agencySystemAccountId: this.agencySystemAccountId
            })
            .then((commissionOptions) => {
                this.commissionOptions = commissionOptions;
                
                this.commissionDropdownOptions = commissionOptions.map((option, index) => ({
                    label: option.label,
                    value: index
                }));

                if (this.commissionType == CommissionType.Fixed) {
                    // first where fixed and amount matches
                    for (let i = 0; i < this.commissionOptions.length; i++) {
                        if (this.commissionOptions[i].commissionType === CommissionType.Fixed && this.commissionOptions[i].commission == this.commissionFixedAmount) {
                            this.setCommissionOptionDropdown(i);
                            break;
                        }
                    }

                    if (!this.selectedCommissionOption) {
                        // last fixed option in dropdown
                        for (let i = this.commissionOptions.length - 1; i >= 0; i--) {
                            if (this.commissionOptions[i].commissionType === CommissionType.Fixed) {
                                this.setCommissionOptionDropdown(i);
                                break;
                            }
                        }
                    }
                } else if (this.commissionType == CommissionType.Other) {
                    // first where not fixed and percent matches
                    for (let i = 0; i < this.commissionOptions.length; i++) {
                        if (this.commissionOptions[i].commissionType !== CommissionType.Fixed && this.commissionOptions[i].commissionPercent == this.commissionPercent) {
                            this.setCommissionOptionDropdown(i);
                            break;
                        }
                    }

                    if (!this.selectedCommissionOption) {
                        // last other option in dropdown
                        for (let i = this.commissionOptions.length - 1; i >= 0; i--) {
                            if (this.commissionOptions[i].commissionType === CommissionType.Other) {
                                this.setCommissionOptionDropdown(i);
                                break;
                            }
                        }
                    }
                } else {
                    // first matching percent option
                    for (let i = 0; i < this.commissionOptions.length; i++) {
                        if (this.commissionPercent && this.commissionOptions[i].commissionPercent == this.commissionPercent) {
                            
                            this.setCommissionOptionDropdown(i);
                            break;
                        }
                    }
                }

                if (this.selectedCommissionOption?.commissionType == CommissionType.Fixed) {
                    this.selectedCommissionOption.commission = this.commissionFixedAmount;
                } else if (this.selectedCommissionOption?.commissionType == CommissionType.Other) {
                    this.selectedCommissionOption.commissionPercent = this.commissionPercent;
                }
            });
    }

    private setCommissionOptionDropdown(index: number) {
        this.selectedCommissionOptionIndex = index;
        this.selectedCommissionOption = {...this.commissionOptions[index]};
    }

    private standardizePercent(percentAmount: number) {
        if (!percentAmount) {
            return;
        }

        if (percentAmount < 1) {
            return percentAmount * 100;
        }

        return percentAmount;
    }

    public setValues = () => {
        this.commissionType = this.selectedCommissionOption.commissionType;
        this.commissionFixedAmount = this.selectedCommissionOption.commission;
        this.commissionPercent = this.selectedCommissionOption.commissionPercent;

        if (this.commissionType === CommissionType.Fixed) {
            this.commissionPercent = null;    
        } else {
            this.commissionFixedAmount = null;
        }
    }

    public $onInit() {
        this.controller = this;
        this.getCommissionOptions();
    }
}

const commissionDropdownComponent = {
    bindings: {
        controller: "=?",
        bondTypeId: "<?",
        writingCompanyId: "<?",
        bondId: "<?",
        carrierSystemAccountId: "<?",
        agencySystemAccountId: "<?",
        commissionPercent: "=",
        commissionFixedAmount: "=",
        commissionType: "=",
        aaLabelStrategy: "@?",
        dropdownClass: "@?",
        otherClass: "@?",
        ngDisabled: "<"
    },
    templateUrl: "app/components/commissionDropdown/commissionDropdown.html",
    controller: CommissionDropdownController,
    controllerAs: "vm"
};

app.component("commissionDropdown", commissionDropdownComponent);