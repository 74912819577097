import { UiApplication } from "./uiApplication";
import { Attachment } from "./attachment";
import { BillToType } from "./billToType";
import { BondAccount } from "./model/bondAccount";
import { Company } from "./model/company";
import { CustomerType } from "./model/customer";
import { EProducerAccount } from "./model/eProducerAccount";
import { Person } from "./model/person";

export interface CustomerDetail {
    id: number;
    name: string;
    customerType: CustomerType;
    isActive: boolean;
    balance: number;
    unappliedPaymentTotal: number;
    invoicedBalance: number;
    notInvoicedTotal: number;
    debitTotal: number;
    creditTotal: number;
    epicReconciliationDateTime?: Date;
    hasRelatedCustomer: boolean;
    subsidiaryCompanyCount: number;
    employeeCustomerCount: number;
    isParentCompany: boolean;
    isEmployer: boolean;
    sisterCompanyCount: number;
    company: Company;
    people: Person[];
    attachments: Attachment[];
    applications: UiApplication[];
    eProducerAccount: EProducerAccount;
    bondAccounts: BondAccount[];
    lookupCode: string;
    comments: string;
    producerUserId: number;
    clientServiceManagerUserId: number;
    clientServiceExecutiveUserId: number;
    clientServiceAgentUserId: number;
    exportToEpicStatus: ExportToEpicStatus;
    billToType: BillToType;
    eProducerAccountId?: number;
}

export enum ExportToEpicStatus {
    Hold = 'Hold',
    Ready = 'Ready',
    Error = 'Error',
    Success = 'Success'
}
