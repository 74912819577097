import { ActivityService } from "../../api/activityService";
import { AttachmentService } from "../../api/attachmentService";
import { BondCorrectionBond } from "../../api/types/bondCorrectionBond";
import { BondDetail } from "../../api/types/bondDetail";
import { BondService } from "../../api/bondService";
import { CancellationRequestBond } from "../../api/types/cancellationRequestBond";
import { CreditReportService } from "../../api/creditReportService";
import { ReinstatementRequestBond } from "../../api/types/reinstatementRequestBond";
import { RiderRequestBond } from "../../api/types/riderRequestBond";
import { SystemAccountService } from "../../api/systemAccountService";
import { BondAccountService } from "../../api/bondAccountService";
import { BondStatus, RenewalStatus } from "../../api/types/model/bond";
import { BondAdditionalQuestionResponse } from "../../api/types/model/bondAdditionalQuestionResponse";
import { BondChange, BondChangeType } from "../../api/types/model/bondChange";
import { BondTransaction } from "../../api/types/model/bondTransaction";
import { CancellationStatus } from "../../api/types/model/cancellationRequest";
import { Company } from "../../api/types/model/company";
import { EProducerAccountType } from "../../api/types/model/eProducerAccount";
import { Memo } from "../../api/types/model/memo";
import { Obligee } from "../../api/types/model/obligee";
import { Person } from "../../api/types/model/person";
import { ReinstatementStatus } from "../../api/types/model/reinstatementRequest";
import { RiderStatus } from "../../api/types/model/riderRequest";
import { ActivityListItem } from "../../components/activityList/activityListItem";
import { ActivityTableQueryOptions } from "../../components/activityList/activityTableQueryOptions";
import { AttachmentTableListItem } from "../../components/attachmentList/attachmentTableListItem";
import { AttachmentTableQueryOptions } from "../../components/attachmentList/attachmentTableQueryOptions";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IEmployerAddressFilter } from "../../filters/employerAddressFilter/employerAddressFilterType";
import { IMailingAddressFilter } from "../../filters/mailingAddressFilter/mailingAddressFilterType";
import { IPersonNameFilter } from "../../filters/personName/personNameFilterType";
import { IPhysicalAddressFilter } from "../../filters/physicalAddressFilter/physicalAddressFilterType";
import { ISpouseNameFilter } from "../../filters/spouseName/spouseNameFilterType";
import { State } from "../../states/state";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { nameOf } from "../../utilities/nameOf";
import { Table } from "../../utilities/tables/table";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BondCorrectionResultAction } from "../bondCorrectionModal/BondCorrectionResultAction";
import { RequestCancellationResultAction } from "../cancellationRequestModal/RequestCancellationResultAction";
import { ModalOpener } from "../modalOpener";
import { RequestReinstatementResultAction } from "../reinstatementRequestModal/RequestReinstatementResultAction";
import { RequestRiderResultAction } from "../riderRequestModal/RequestRiderResultAction";
import { BondDetailOptions } from "./bondDetailModalOptions";
import { BondDetailResult } from "./BondDetailResult";
import { MemoService } from "../../api/memoService";
import app from "../../main";
import { IFilterService, ITimeoutService, IPromise } from "angular";
import { FormatType } from "../../filters/physicalAddressFilter/formatType";
import toPascal from "../../utilities/stringUtilities/toPascal";
import toCamel from "../../utilities/stringUtilities/toCamel";
import { StateChangeListener } from "../../states/stateChangeListener";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import DropdownOption from "../../api/types/dropdownOption";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { RequestRiderResult } from "../riderRequestModal/RequestRiderResult";
import { RequestReinstatementResult } from "../reinstatementRequestModal/RequestReinstatementResult";
import { RequestCancellationResult } from "../cancellationRequestModal/RequestCancellationResult";
import { ApplicationService } from "../../api/applicationService";
import QuoteListItem from "../../components/quoteList/quoteListItem";
import MemoPageRequest from "../../api/types/memoPageRequest";

export class BondDetailModalController {

    public static $inject = [
        Injectables.$state,
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.BondService,
        Injectables.$filter,
        Injectables.CurrentUserResolver,
        Injectables.SystemAccountService,
        Injectables.ModalOpener,
        Injectables.MemoService,
        Injectables.CreditReportService,
        Injectables.ActivityService,
        Injectables.$timeout,
        Injectables.BondAccountService,
        Injectables.IDebouceDelayer,
        Injectables.AttachmentService,
        Injectables.ToastMessageCreator,
        Injectables.StateChangeListener,
        Injectables.ApplicationService
    ];

    constructor(
        private readonly $state: State,
        private readonly $uibModalInstance: Modal<BondDetailResult>,
        private readonly modalOptions: BondDetailOptions,
        private readonly bondService: BondService,
        private readonly $filter: IFilterService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemAccountService: SystemAccountService,
        private readonly modalOpener: ModalOpener,
        private readonly memoService: MemoService,
        private readonly creditReportService: CreditReportService,
        private readonly activityService: ActivityService,
        private readonly $timeout: ITimeoutService,
        private readonly bondAccountService: BondAccountService,
        debounceDelayer: IDebounceDelayer,
        private readonly attachmentService: AttachmentService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly stateChangeListener: StateChangeListener,
        private readonly applicationService: ApplicationService
    ) {
        this.memosTable = new Table(debounceDelayer);
        this.memosTable.onChange = this.loadMemos;

        this.activitiesTable = new Table(debounceDelayer);
        this.activitiesTable.onChange = this.loadActivities;

        this.attachmentsTable = new Table(debounceDelayer);
        this.attachmentsTable.onChange = this.loadAttachments;
    }

    public bondId: number;
    public customerId: number;
    public changes: BondChange[] = [];
    public originalBond: BondDetail;
    public originalPeople: Person[];
    public people: Person[];
    public companies: Company[];
    public originalCompanies: Company[];
    public principalLoaded: boolean;
    public obligee: Obligee;
    public originalObligee: Obligee;
    public obligeeLoaded: boolean;
    public obligeePromise: IPromise<void>;
    public bondAdditionalQuestionResponses: BondAdditionalQuestionResponse[];
    public transactionsLoaded: boolean;
    public transactionPromise: IPromise<void>;
    public transactions: BondTransaction[];
    public bond: BondDetail;
    public logoImg: string;
    public invoiceLoadingPromise: IPromise<void>;
    public attachmentsPromise: IPromise<void>;
    public attachmentsLoaded: boolean;
    public memoLoadingPromise: IPromise<void>;
    public busyIndicator: BusyIndicator;
    public cancelBondWatch: () => void;
    public disabled = true;
    public showRenewButton: boolean;
    public showRiderButton: boolean;
    public showCancellationButton: boolean;
    public showReinstatementButton: boolean;
    public creditReportsPromise: IPromise<void>;
    public creditReportPeople: Person[];
    public renewalQuotesPromise: IPromise<void>;
    public renewalApplicationId: number;
    public renewalQuotes: QuoteListItem[];
    public readonly memosTable: Table<Memo, MemoPageRequest>;
    public readonly activitiesTable: Table<ActivityListItem, ActivityTableQueryOptions>;
    public readonly attachmentsTable: Table<AttachmentTableListItem, AttachmentTableQueryOptions>;
    private memosLoaded: boolean;
    public activeTab: number;
    public activityPromise: IPromise<any>;
    public activitiesLoaded: boolean;
    public principalPromise: IPromise<void>;
    public actionDropdownOptions: DropdownOption[];
    public espTooltip: string;

    public get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public get isAgency(): boolean {
        return !this.isCarrier;
    }

    public get showBroker(): boolean {
        return this.bond !== undefined &&
                this.bond.eProducerAccountId &&
                this.bond.eProducerAccountType !== EProducerAccountType.Applicant &&
                this.isAgency;
    }

    public get broker(): string {
        if (this.showBroker) {
            let brokerCodeString = '';
            if (this.bond.eProducerAccountBrokerCode) {
                brokerCodeString = ' | ' + this.bond.eProducerAccountBrokerCode;
            }
            
            return `${this.bond.eProducerAccountName}${brokerCodeString}`;
        } else {
            return '';
        }
    }

    public get hasChanges(): boolean {
        return this.changes.length > 0;
    }

    public get canRenewBond(): boolean {
        return this.isAgency
            && this.bond !== undefined
            && !this.hasPendingTransaction
            && this.bond.isRenewable
            && this.bond.status === BondStatus.Active;
    }

    public get canRequestRider(): boolean {
        return this.isAgency
            && this.bond !== undefined
            && this.bond.status === BondStatus.Active
            && !this.hasPendingTransaction;
    }

    public get canCreateRider(): boolean {
        return this.isCarrier
            && this.bond !== undefined
            && this.bond.status === BondStatus.Active
            && !this.hasPendingTransaction;
    }

    public get canEditRiderRequest(): boolean {
        return this.isAgency
            && this.bond !== undefined
            && this.bond.riderStatus === RiderStatus.Requested;
    }

    public get canViewRiderRequest(): boolean {
        return this.isCarrier
            && this.bond !== undefined
            && this.bond.riderStatus === RiderStatus.Requested;
    }

    public get canCorrectBond(): boolean {
        return this.bond !== undefined
            && this.bond.status === BondStatus.Active
            && !this.hasPendingTransaction;
    }

    public get canRequestCancellation(): boolean {
        return this.isAgency
            && this.bond !== undefined
            && this.bond.status === BondStatus.Active
            && !this.hasPendingTransaction;
    }

    public get canEditCancellationRequest(): boolean {
        return this.isAgency
            && this.bond !== undefined
            && this.bond.cancellationStatus === CancellationStatus.Requested;
    }

    public get canViewCancellationRequest(): boolean {
        return this.isCarrier
            && this.bond !== undefined
            && this.bond.cancellationStatus === CancellationStatus.Requested;
    }

    public get canCancelBond(): boolean {
        return this.isCarrier
            && this.bond !== undefined
            && this.bond.status === BondStatus.Active
            && !this.hasPendingTransaction;
    }

    public get canRequestReinstatement(): boolean {
        return this.isAgency
            && this.bond !== undefined
            && (this.bond.status === BondStatus.Cancelled || this.bond.status === BondStatus.PendingCancellation)
            && !this.hasPendingTransaction;
    }

    public get canReinstateBond(): boolean {
        return this.isCarrier
            && this.bond !== undefined
            && (this.bond.status === BondStatus.Cancelled || this.bond.status === BondStatus.PendingCancellation)
            && !this.hasPendingTransaction;
    }

    public get canEditReinstatementRequest(): boolean {
        return this.isAgency
            && this.bond !== undefined
            && this.bond.reinstatementStatus === ReinstatementStatus.Requested;
    }

    public get canViewReinstatementRequest(): boolean {
        return this.isCarrier
            && this.bond !== undefined
            && this.bond.reinstatementStatus === ReinstatementStatus.Requested;
    }

    public get hasPendingTransaction(): boolean {
        return this.isPendingRider
            || this.isPendingCancellation
            || this.isPendingReinstatement;
    }

    public get isPendingRider(): boolean {
        return this.bond !== undefined && this.bond.riderStatus === RiderStatus.Requested;
    }

    public get isPendingCancellation(): boolean {
        return this.bond !== undefined && this.bond.cancellationStatus === CancellationStatus.Requested;
    }

    public get isPendingReinstatement(): boolean {
        return this.bond !== undefined && this.bond.reinstatementStatus === ReinstatementStatus.Requested;
    }

    public get canAssignPrincipal(): boolean {
        return this.bond && this.bond.allowPrincipalAssignment;
    }

    public get showActions(): boolean {
        return this.canCancelBond
            || this.canRequestCancellation
            || this.canCorrectBond
            || this.canRenewBond
            || this.canReinstateBond
            || this.canRequestReinstatement
            || this.canCreateRider
            || this.canRequestRider;
    }

    public bondRenewed = (): void => {
        this.refresh();
    }

    public loadCreditReports = (): void => {
        this.creditReportsPromise = this.creditReportService.getCreditReportsByBondId(this.bondId)
            .then((creditReportPeople) => {
                this.creditReportPeople = creditReportPeople;
            });
    }

    public transactionBillingEntriesSaved(): void {
        this.refresh();
    }

    public checkboxChangedESB = (): void => {
        this.busyIndicator = {
            message: 'Saving...',
            promise: this.bondService.updateIsElectronicSuretyBond(this.bond.isElectronic, this.bond.id)
        };

        this.setElectronicTooltip();
    };

    public bondChanged = (): void => {
        const watching = ['BondAmount', 'NameOnBond', 'ExpirationDate', 'EffectiveDate', 'BondNumber'];

        for (let i = this.changes.length - 1; i >= 0; i--) {
            // if not person, company or bond form info
            if (this.changes[i].changeType.toString().indexOf('BondFormInformation') < 0 &&
                this.changes[i].changeType.toString().indexOf('Individual') < 0 &&
                this.changes[i].changeType.toString().indexOf('Company') < 0 &&
                this.changes[i].changeType.toString().indexOf('Obligee') < 0) {
                for (const watch of watching) {
                    if (this.changes[i].changeType === watch) {
                        this.changes.splice(i, 1);
                        break;
                    }
                }
            }
        }

        // add bond changes
        for (const property in this.originalBond) {
            if (Object.prototype.hasOwnProperty.call(this.originalBond, property) &&
                watching.indexOf(toPascal(property)) >= 0 &&
                this.originalBond[property] !== this.bond[property]) {
                
                this.changes.push(
                    this.createChangeItem(
                        toPascal(property), 
                        this.originalBond[property], 
                        this.bond[property], 
                        null, 
                        null, 
                        null
                    )
                );
            }
        }
    }

    public approveReview = (): void => {
        this.busyIndicator = {
            message: 'Processing...',
            promise: this.bondService.approveReview(this.bond.id)
                .then(() => {
                    this.bond.reviewRequired = false;
                    this.createActionMenuOptions();
                })
        };
    }

    public showReviewModal = (): void => {
        this.modalOpener.showMarkBondForReviewModal(this.bondId)
            .result
            .then((result) => {
                this.bond.reviewRequired = true;
                this.bond.reviewComments = result.reviewComments;
                this.createActionMenuOptions();
            })
            .catch(() => {});
    }

    public attachmentTabSelected = (): void => {
        if (this.attachmentsLoaded) {
            return;
        }

        this.loadAttachments();
    }

    public loadAttachments = (): void => {
        
        this.attachmentsPromise = this.attachmentService.getBondAttachments(
                this.bond.id,
                this.attachmentsTable.queryOptions
            )
            .then((response) => {
                this.attachmentsTable.setData(response.items, response.totalRecordCount);
                this.attachmentsLoaded = true;
            });
    }

    public showAssignBondAccountModal = () => {
        this.modalOpener.showBondAccountAssignmentModal([this.bondId])
            .result
            .then(() => {
                this.refresh();
            })
            .catch(() => {});
    }

    public showBondAccountModal = () => {
        this.modalOpener.showBondAccountModal(this.bond.customerId, this.bond.bondAccountId)
            .result
            .then(() => {
                this.refresh();
            })
            .catch(() => {});
    }

    public showBondLineRemovalConfirmation = () => {
        this.modalOpener.confirmModal(
                'Remove From Bond Line', 
                'Are you sure you want to remove this bond from the surety bond line?', 
                'Confirm', 
                'Cancel'
            )
            .result
            .then(() => {
                this.bondAccountService.removeBondFromBondAccount(this.bondId)
                    .then(() => {
                        this.refresh();
                    });
            })
            .catch(() => {});                
    }

    public saveServicingRoles = () => {
        this.busyIndicator.message = 'Saving Servicing Roles...';
        this.busyIndicator.promise = this.bondService.saveServicingRoles(this.bond)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Servicing roles have been updated for this bond');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save servicing roles');
            });
    }

    public companyChanged = (company: Company): void => {
        let physicalAddressChangedCreated = false;
        let mailAddressChangedCreated = false;

        this.removeChangesByChangeTypePrefix('Company');

        for (let i = 0; i < this.companies.length; i++) {
            if (company.id === this.companies[i].id) {

                // look through each property
                for (const property in company) {

                    // if change was detected
                    if (property !== 'events' && 
                        property !== 'companyContacts' && 
                        property !== 'companyLicenses' && 
                        property !== 'indemnitySignatures' && 
                        property !== '$$hashKey' && 
                        Object.prototype.hasOwnProperty.call(company, property) &&
                        this.companies[i][property] !== this.originalCompanies[i][property]) {
                        
                            // if physical address changed
                        if (property === 'physicalAddress' || property === 'physicalSuiteAptNumber' || property === 'physicalZip' || property === 'physicalCity' || property === 'physicalState' || property === 'physicalZip' || property === 'physicalCounty') {

                            // only create on change even if multiple address parts change
                            if (physicalAddressChangedCreated) {
                                continue;
                            }

                            this.changes.push(
                                this.createChangeItem(
                                    BondChangeType.CompanyPhysicalAddress,
                                    this.$filter<IPhysicalAddressFilter>('physicalAddress')(this.originalCompanies[i], FormatType.TwoLine),
                                    this.$filter<IPhysicalAddressFilter>('physicalAddress')(this.companies[i], FormatType.Pipe),
                                    null,
                                    null,
                                    company.id
                                )
                            );

                            physicalAddressChangedCreated = true;
                        }
                        // if mail address changed
                        else if (property === 'mailAddress' || property === 'mailSuiteAptNumber' || property === 'mailZip' || property === 'mailCity' || property === 'mailState' || property === 'mailZip' || property === 'mailCounty') {
                            // only create on change even if multiple address parts change
                            if (mailAddressChangedCreated) {
                                continue;
                            }

                            this.changes.push(
                                this.createChangeItem(
                                    BondChangeType.CompanyMailingAddress,
                                    this.$filter<IMailingAddressFilter>('mailingAddress')(this.originalCompanies[i], FormatType.TwoLine),
                                    this.$filter<IMailingAddressFilter>('mailingAddress')(this.companies[i], FormatType.Pipe),
                                    null,
                                    null,
                                    company.id
                                )
                            );

                            mailAddressChangedCreated = true;
                        } else if (property === 'companyType') {
                            this.changes.push(this.createChangeItem(`${property.charAt(0).toUpperCase()}${property.slice(1)}`, this.originalCompanies[i][property], this.companies[i][property], null, null, company.id));
                        } else {
                            this.changes.push(this.createChangeItem(`Company${property.charAt(0).toUpperCase()}${property.slice(1)}`, this.originalCompanies[i][property], this.companies[i][property], null, null, company.id));
                        }
                    }
                }
            }
        }
    }

    public activityTabSelected = () => {
        if (this.activitiesLoaded) {
            return;
        }

        this.loadActivities();
    }

    public loadActivities = (): void => {
        this.activityPromise = this.activityService.getBondActivities(
                this.bondId, 
                this.activitiesTable.queryOptions)
            .then((response) => {
                this.activitiesTable.setData(response.items, response.totalRecordCount);
                this.activitiesLoaded = true;
            });
    }

    public renewalQuotePurchased = () => {
        this.activeTab = 0;
        this.refresh();
    }

    public promptToDeleteRenewalApplication = () => {
        this.modalOpener.confirmModal(
                'Delete Renewal Application',
                'Are you sure you want to delete the renewal application for this bond? Doing so will delete any existing renewal quotes. This action cannot be undone.',
                'Delete',
                'Back'
            )
            .result
            .then(() => {
                this.bondService.deleteRenewalApplication(this.bondId)
                    .then(() => {
                        this.toastMessageCreator.createSuccessMessage('Renewal application was deleted successfully');
                        this.activeTab = 0;
                        this.refresh();
                    })
                    .catch(() => {
                        this.toastMessageCreator.createErrorMessage('An error occurred while trying to delete the renewal application');
                    });
            })
            .catch(() => {});
    }

    private setElectronicTooltip() {
        if (this.bond.isElectronic) {
            this.espTooltip = 'Electronic Surety Bond';
        } else {
            this.espTooltip = 'Not Electronic';
        }
    }

    public createChangeItem = (
        changeType: string, 
        originalValue: any, 
        newValue: any, 
        personId?: number, 
        bondAdditionalQuestionResponseId?: number, 
        companyId?: number
    ): BondChange => {
    
        return {
            changeType: changeType,
            originalValue: originalValue,
            newValue: newValue,
            personId: personId,
            bondAdditionalQuestionResponseId: bondAdditionalQuestionResponseId,
            companyId: companyId
        } as BondChange;
    }

    public loadBond(bondId: number): IPromise<void> {
        return this.bondService
            .getBondDetail(bondId)
            .then((bond) => {
                this.bond = bond;
                this.originalBond = ({...bond});
                this.disabled = this.bond.riderStatus === RiderStatus.Requested;
                
                this.setElectronicTooltip();

                if (this.isCarrier) {
                    this.systemAccountService
                        .getSmallLogo(this.bond.agencyId)
                        .then((img) => {
                            this.logoImg = img;
                        });
                } else {
                    this.systemAccountService
                        .getSmallLogo(this.bond.carrierId)
                        .then((img) => {
                            this.logoImg = img;
                        });
                }

                this.createActionMenuOptions();
            });
    }

    private createActionMenuOptions = () => {
        this.actionDropdownOptions = [
            {
                label: 'Mark for Review',
                onClick: this.showReviewModal,
                isDisabled: this.bond.reviewRequired,
                isHidden: this.isCarrier,
            },
            {
                label: 'Create Rider',
                onClick: this.showRiderRequestModal,
                isDisabled: !this.canRequestRider,
                id: 'bond-detail-actions-request-rider-button',
            },
            {
                label: 'Create Rider',
                onClick: this.showRiderRequestModal,
                isDisabled: !this.canCreateRider,
                id: 'bond-detail-actions-create-rider-button',
                isHidden: !this.isCarrier
            },
            {
                label: 'Correct Bond',
                onClick: this.showBondCorrectionModal,
                isDisabled: !this.canCorrectBond,
                isHidden: this.isCarrier
            },
            {
                label: 'Create Renewal Application',
                onClick: this.createRenewalApplication,
                isHidden: !this.canRenewBond || this.bond.renewalStatus !== RenewalStatus.None, 
            },
            {
                label: 'Delete Renewal Application',
                onClick: this.promptToDeleteRenewalApplication,
                isHidden: this.bond.renewalStatus === RenewalStatus.None || this.isCarrier
            },
            {
                label: 'Bond type is non-renewable',
                isDisabled: true,
                isHidden: this.isCarrier || this.canRenewBond
            },
            {
                label: 'Cancel Bond',
                onClick: this.showCancellationRequestModal,
                isDisabled: !this.canRequestCancellation,
                isHidden: this.isCarrier
            },
            {
                label: 'Cancel Bond',
                onClick: this.showCancellationRequestModal,
                isDisabled: !this.canCancelBond,
                isHidden: !this.isCarrier
            },
            {
                label: 'Reinstate',
                onClick: this.showReinstatementRequestModal,
                isDisabled: !this.canRequestReinstatement,
                isHidden: this.isCarrier
            },
            {
                label: 'Reinstate',
                onClick: this.showReinstatementRequestModal,
                isDisabled: !this.canReinstateBond,
                isHidden: !this.isCarrier
            },
            {
                label: 'Customer Reassignment',
                onClick: this.showCustomerReassignmentModal,
                isHidden: this.isCarrier
            },
            {
                label: 'Assign Principal',
                onClick: this.showAssignPrincipalModal,
                isDisabled: !this.canAssignPrincipal,
                isHidden: this.isCarrier
            },
            {
                label: 'Assign to Bond Line',
                onClick: this.showAssignBondAccountModal,
                isHidden: this.isCarrier
            }
        ];
    }

    public showCustomerReassignmentModal = () => {
        this.modalOpener.showReassignBondModal(this.bondId)
            .result
            .then(() => {})
            .catch(() => {});
    }

    public memosTabSelected = () => {
        if (this.memosLoaded) {
            return;
        }

        this.loadMemos();
    }

    public loadMemos = (): IPromise<void> => {
        this.memosTable.queryOptions.bondId = this.modalOptions.bondId;

        return this.memoLoadingPromise = this.memoService
            .getMemos(this.memosTable.queryOptions)
            .then((response) => {
                this.memosTable.setData(response.items, response.totalRecordCount);
            });
    }

    public obligeeChanged = (obligee: Obligee, changedProperty: string): void => {
        const propertyNameOnBond = toCamel(`${toPascal(changedProperty)}`);

        if (Object.prototype.hasOwnProperty.call(obligee, propertyNameOnBond) &&
            this.originalObligee[changedProperty] === this.obligee[changedProperty]) {
            
                return;
        }

        const changeType = this.getObligeeChangeType(changedProperty);

        this.removeChange(changeType);
        this.addObligeeChange(obligee, changedProperty, changeType);
    }

    public showAssignPrincipalModal = () => {
        
        if (!this.canAssignPrincipal) {
            return;
        }

        const customerId = this.bond.customerId;
        const bondIds = [this.bond.id];

        this.modalOpener.showAssignPrincipalModal(customerId, bondIds)
            .result
            .then(() => {
                this.refresh();
            })
            .catch(() => {});
    }

    private getObligeeChangeType = (changedProperty: string): BondChangeType => {
        let changeType: BondChangeType;

        if (this.isPhysicalAddressPropertyChange(changedProperty)) {
            changeType = BondChangeType.ObligeePhysicalAddress;
        } else if (this.isMailAddressPropertyChange(changedProperty)) {
            changeType = BondChangeType.ObligeeMailAddress;
        } else if (changedProperty === nameOf<Obligee>('contact1')) {
            changeType = BondChangeType.ObligeeContact1;
        } else if (changedProperty === nameOf<Obligee>('contact2')) {
            changeType = BondChangeType.ObligeeContact2;
        } else if (changedProperty === nameOf<Obligee>('contact3')) {
            changeType = BondChangeType.ObligeeContact3;
        } else if (changedProperty === nameOf<Obligee>('email')) {
            changeType = BondChangeType.ObligeeEmail;
        } else if (changedProperty === nameOf<Obligee>('fax')) {
            changeType = BondChangeType.ObligeeFax;
        } else if (changedProperty === nameOf<Obligee>('name')) {
            changeType = BondChangeType.ObligeeName;
        } else if (changedProperty === nameOf<Obligee>('phone')) {
            changeType = BondChangeType.ObligeePhone;
        } else if (changedProperty === nameOf<Obligee>('websiteURL')) {
            changeType = BondChangeType.ObligeeWebsiteURL;
        } else {
            throw Error(`No rider change type mapping defined for property on obligee named '${changedProperty}'.`);
        }

        return changeType;
    }

    private isPhysicalAddressPropertyChange = (changedProperty: string): boolean => {
        // TODO: Implement an IPhysicalAddress interface that can be used to enforce strong typing on models other than Obligee.
        // Will need to stop using TypeWriter.
        return changedProperty === nameOf<Obligee>('physicalAddress')
            || changedProperty === nameOf<Obligee>('physicalSuiteAptNumber')
            || changedProperty === nameOf<Obligee>('physicalCity')
            || changedProperty === nameOf<Obligee>('physicalZip')
            || changedProperty === nameOf<Obligee>('physicalState')
            || changedProperty === nameOf<Obligee>('physicalCounty');
    }

    private isMailAddressPropertyChange = (changedProperty: string): boolean => {
        // TODO: Implement an IMailAddress interface that can be used to enforce strong typing on models other than Obligee.
        // Will need to stop using TypeWriter.
        return changedProperty === nameOf<Obligee>('mailAddress')
            || changedProperty === nameOf<Obligee>('mailSuiteAptNumber')
            || changedProperty === nameOf<Obligee>('mailCity')
            || changedProperty === nameOf<Obligee>('mailZip')
            || changedProperty === nameOf<Obligee>('mailState')
            || changedProperty === nameOf<Obligee>('mailCounty');
    }

    private removeChange = (changeType: BondChangeType): void => {
        const changeIndex = this.changes.findIndex((c) => c.changeType === changeType);

        if (changeIndex !== -1) {
            this.changes.splice(changeIndex, 1);
        }
    }

    private addObligeeChange = (obligee: Obligee, changedProperty: string, changeType: BondChangeType): void => {
        let change: BondChange;

        if (changeType === BondChangeType.ObligeePhysicalAddress) {
            const originalAddress = this.$filter<IPhysicalAddressFilter>('physicalAddress')(this.originalObligee, FormatType.TwoLine);
            const newAddress = this.$filter<IPhysicalAddressFilter>('physicalAddress')(obligee, FormatType.Pipe);

            change = this.createChangeItem(changeType, originalAddress, newAddress);
        } else if (changeType === BondChangeType.ObligeeMailAddress) {
            const originalAddress = this.$filter<IMailingAddressFilter>('mailingAddress')(this.originalObligee, FormatType.TwoLine);
            const newAddress = this.$filter<IMailingAddressFilter>('mailingAddress')(obligee, FormatType.Pipe);

            change = this.createChangeItem(changeType, originalAddress, newAddress);
        } else {
            change = this.createChangeItem(changeType, this.originalObligee[changedProperty], obligee[changedProperty]);
        }

        this.changes.push(change);
    }

    private removeChangesByChangeTypePrefix = (changeTypePrefix: string): void => {
        for (let i = this.changes.length - 1; i >= 0; i--) {
            if (this.changes[i].changeType.toString().indexOf(changeTypePrefix) === 0) {
                this.changes.splice(i, 1);
            }
        }
    }

    public personChanged = (person: Person): void => {
        let nameChangeCreated = false;
        let physicalAddressChangedCreated = false;
        let mailAddressChangedCreated = false;
        let employerAddressChangedCreated = false;
        let spouseNameChangeCreated = false;

        for (let i = this.changes.length - 1; i >= 0; i--) {
            if (this.changes[i].changeType.toString().indexOf('Individual') === 0 && this.changes[i].personId === person.id) {
                this.changes.splice(i, 1);
            }
        }

        // match person to element in bond.people
        for (let i = 0; i < this.people.length; i++) {
            if (person.id === this.people[i].id) {

                // look through each property
                for (const property in person) {

                    // if change was detected
                    if (property !== 'events' && 
                        property !== 'indemnitySignatures' && 
                        property !== 'peopleLicenses' && 
                        property !== 'creditReports' && 
                        property !== '$$hashKey' && 
                        Object.prototype.hasOwnProperty.call(person, property) && 
                        this.people[i][property] !== this.originalPeople[i][property]) {

                        // if name was changed
                        if (property === 'firstName' || property === 'lastName' || property === 'middleName' || property === 'prefix' || property === 'suffix') {

                            // only create one change even if multiple name parts have changed (first, last, middle, etc.)
                            if (nameChangeCreated) {
                                continue;
                            }

                            this.changes.push(
                                this.createChangeItem(
                                    BondChangeType.IndividualName,
                                    this.$filter<IPersonNameFilter>('personName')(this.originalPeople[i]),
                                    this.$filter<IPersonNameFilter>('personName')(this.people[i], '{|}'),
                                    person.id,
                                    null,
                                    null
                                )
                            );

                            nameChangeCreated = true;
                        }
                        // if physical address changed
                        else if (property === 'physicalAddress' || property === 'physicalSuiteAptNumber' || property === 'physicalZip' || property === 'physicalCity' || property === 'physicalState' || property === 'physicalZip' || property === 'physicalCounty') {

                            // only create on change even if multiple address parts change
                            if (physicalAddressChangedCreated) {
                                continue;
                            }

                            this.changes.push(
                                this.createChangeItem(
                                    BondChangeType.IndividualPhysicalAddress,
                                    this.$filter<IPhysicalAddressFilter>('physicalAddress')(this.originalPeople[i], FormatType.TwoLine),
                                    this.$filter<IPhysicalAddressFilter>('physicalAddress')(this.people[i], FormatType.Pipe),
                                    person.id,
                                    null,
                                    null
                                )
                            );

                            physicalAddressChangedCreated = true;
                        }
                        // if employer address changed
                        else if (property === 'employerAddress' || property === 'employerSuiteAptNumber' || property === 'employerZip' || property === 'employerCity' || property === 'employerState' || property === 'employerCounty') {

                            // only create on change even if multiple address parts change
                            if (employerAddressChangedCreated) {
                                continue;
                            }

                            this.changes.push(
                                this.createChangeItem(
                                    BondChangeType.IndividualEmployerAddress,
                                    this.$filter<IEmployerAddressFilter>('employerAddress')(this.originalPeople[i], FormatType.TwoLine),
                                    this.$filter<IEmployerAddressFilter>('employerAddress')(this.people[i], FormatType.Pipe),
                                    person.id,
                                    null,
                                    null
                                )
                            );

                            employerAddressChangedCreated = true;
                        }
                        // if mail address changed
                        else if (property === 'mailAddress' || property === 'mailSuiteAptNumber' || property === 'mailZip' || property === 'mailCity' || property === 'mailState' || property === 'mailZip' || property === 'mailCounty') {
                            // only create on change even if multiple address parts change
                            if (mailAddressChangedCreated) {
                                continue;
                            }

                            this.changes.push(
                                this.createChangeItem(
                                    BondChangeType.IndividualMailingAddress,
                                    this.$filter<IMailingAddressFilter>('mailingAddress')(this.originalPeople[i], FormatType.TwoLine),
                                    this.$filter<IMailingAddressFilter>('mailingAddress')(this.people[i], FormatType.Pipe),
                                    person.id,
                                    null,
                                    null
                                )
                            );

                            mailAddressChangedCreated = true;
                        }
                        // if spouse name changed
                        else if (property === 'spouseFirstName' || property === 'spouseMiddleName' || property === 'spouseLastName') {
                            // only create on change even if multiple name parts change
                            if (spouseNameChangeCreated) {
                                continue;
                            }

                            this.changes.push(
                                this.createChangeItem(
                                    BondChangeType.IndividualSpouseName,
                                    this.$filter<ISpouseNameFilter>('spouseName')(this.originalPeople[i]),
                                    this.$filter<ISpouseNameFilter>('spouseName')(this.people[i], '{|}'),
                                    person.id,
                                    null,
                                    null
                                )
                            );

                            spouseNameChangeCreated = true;
                        }
                        // any other change
                        else {
                            this.changes.push(
                                this.createChangeItem(`Individual${property.charAt(0).toUpperCase()}${property.slice(1)}`, this.originalPeople[i][property], this.people[i][property], person.id, null, null));
                        }
                    }
                }
            }
        }
    }

    public questionResponseChanged = (response: BondAdditionalQuestionResponse): void => {
        this.removeChangesByChangeTypePrefix('BondFormInformation');

        for (let i = 0; i < this.originalBond.bondAdditionalQuestionResponses.length; i++) {
            if (this.originalBond.bondAdditionalQuestionResponses[i].id === response.id && this.originalBond.bondAdditionalQuestionResponses[i].responseText !== response.responseText) {
                this.changes.push(this.createChangeItem(
                    BondChangeType.BondFormInformation,
                    this.originalBond.bondAdditionalQuestionResponses[i].responseText,
                    response.responseText,
                    null,
                    response.id
                ));
            }
        }
    }

    public showCancellationRequestModal = (): void => {
        const bondForRequest = {
            id: this.bond.id,
            cancellationStatus: this.bond.cancellationStatus,
            cancellationDate: this.bond.cancellationDate,
            status: this.bond.status,
            carrierSystemAccountId: this.bond.carrierId 
        } as CancellationRequestBond ;

        this.modalOpener.showCancellationRequestModal(bondForRequest)
            .result
            .then((result: RequestCancellationResult) => {

                switch (result.resultAction) {
                    case RequestCancellationResultAction.Rescinded:
                        this.bond.cancellationStatus = CancellationStatus.None;
                        break;
                    case RequestCancellationResultAction.Submitted:
                        this.bond.cancellationStatus = CancellationStatus.Requested;
                        break;
                    case RequestCancellationResultAction.BondCanceled:
                        this.bond.cancellationStatus = CancellationStatus.Cancelled;
                        break;
                }

                this.refresh();
            })
            .catch(() => {});
    }

    public showReinstatementRequestModal = (): void => {
        const bondForReinstatement = {
            carrierSystemAccountId: this.bond.carrierId,
            id: this.bond.id,
            reinstatementStatus: this.bond.reinstatementStatus,
            cancellationDate: this.bond.cancellationDate
        } as ReinstatementRequestBond ;

        this.modalOpener.showReinstatementRequestModal(bondForReinstatement)
            .result
            .then((result: RequestReinstatementResult) => {
                
                // action is a TypeScript type ModalRequestCancellationResult
                if (result.resultAction === RequestReinstatementResultAction.Rescinded) {
                    this.bond.reinstatementStatus = ReinstatementStatus.None;
                } else if (result.resultAction === RequestReinstatementResultAction.Submitted) {
                    this.bond.reinstatementStatus = ReinstatementStatus.Requested;
                }
                else if (result.resultAction === RequestReinstatementResultAction.BondReinstated) {
                    this.bond.reinstatementStatus = ReinstatementStatus.Approved;
                }

                this.refresh();
            })
            .catch(() => {});
    }

    public refresh = (): void => {
        this.transactionsLoaded = false;
        this.activitiesLoaded = false;
        this.principalLoaded = false;
        this.memosLoaded = false;
        this.attachmentsLoaded = false;
        this.obligeeLoaded = false;
        
        this.changes = [];
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.loadBond(this.bondId)
        };

         switch(this.activeTab) {
            case 1:
                this.loadPrincipal();
                break;
            case 2:
                this.loadTransactions();
                break;
            case 3:
                this.loadMemos();
                break;
            case 4:
                this.loadAttachments();
                break;
            case 5:
                this.loadObligee();
                break;
            case 7:
                this.loadActivities();
                break;
        }
    }

    public showBondCorrectionModal = (): void => {
        if (!this.originalPeople || !this.originalCompanies) {
            this.busyIndicator = {
                message: 'Loading...',
                promise: this.loadPrincipalData()
                .then(() => {
                    this.showBondCorrectionModal();
                })
            };

            return;
        }

        const bond: BondCorrectionBond = {
            id: this.bondId,
            bondAmount: this.originalBond.bondAmount,
            nameOnBond: this.originalBond.nameOnBond,
            effectiveDate: this.originalBond.effectiveDate,
            expirationDate: this.originalBond.expirationDate,
            bondTypeId: this.originalBond.bondTypeId,
            bondTypeName: this.originalBond.bondTypeName,
            people: this.originalPeople,
            companies: this.originalCompanies,
            bondAdditionalQuestionResponses: this.originalBond.bondAdditionalQuestionResponses,
            renewalStatus: this.bond.renewalStatus,
            carrierId: this.bond.carrierId,
            writingCompanyId: this.bond.writingCompanyId,
            writingCompanyName: this.bond.writingCompanyName
        };

        this.modalOpener.showBondCorrectionModal(bond, this.changes)
            .result
            .then((result) => {
                if (result.resultAction === BondCorrectionResultAction.Submitted) {
                    this.refresh();
                }
            })
            .catch(() => {});
    }

    public showRiderRequestModal = (): void => {

        if (!this.originalPeople || 
            !this.originalCompanies || 
            !this.obligee
        ){
            this.busyIndicator = {
                message: 'Loading...',
                promise: this.loadPrincipalData()
                    .then(() => this.loadObligeeData())
                    .then(() => {
                        this.showRiderRequestModal();
                    })
            };

            return;
        }

        const riderBond: RiderRequestBond = {
            id: this.bond.id,
            bondAmount: this.originalBond.bondAmount,
            nameOnBond: this.originalBond.nameOnBond,
            effectiveDate: this.originalBond.effectiveDate,
            expirationDate: this.originalBond.expirationDate,
            bondTypeId: this.originalBond.bondTypeId,
            bondTypeName: this.originalBond.bondTypeName,
            bondNumber: this.bond.bondNumber,
            riderStatus: this.originalBond.riderStatus,
            originalEffectiveDate: this.originalBond.effectiveDate,
            people: this.originalPeople,
            companies: this.originalCompanies,
            bondAdditionalQuestionResponses: this.originalBond.bondAdditionalQuestionResponses,
            obligee: this.originalObligee,
            renewalStatus: this.bond.renewalStatus,
            carrierId: this.bond.carrierId,
            writingCompanyId: this.bond.writingCompanyId,
            writingCompanyName: this.bond.writingCompanyName
        };

        this.modalOpener
            .showRiderRequestModal(riderBond, [...this.changes] )
            .result
            .then((result: RequestRiderResult) => {
                this.principalPromise = this.loadPrincipalData();

                if (result.resultAction === RequestRiderResultAction.Rescinded) {
                    this.bond.riderStatus = RiderStatus.None;
                    this.originalBond.riderStatus = RiderStatus.None;
                    this.disabled = false;

                } else if (result.resultAction === RequestRiderResultAction.Submitted) {
                    this.bond.riderStatus = RiderStatus.Requested;
                    this.originalBond.riderStatus = RiderStatus.Requested;
                    this.changes = [];
                    this.disabled = true;

                    if (this.cancelBondWatch instanceof Function) {
                        this.cancelBondWatch();
                    }

                } else if (result.resultAction === RequestRiderResultAction.Approved ||
                            result.resultAction === RequestRiderResultAction.Declined
                ){
                    this.bond.riderStatus = RiderStatus.None;
                    this.originalBond.riderStatus = RiderStatus.None;
                    this.disabled = false;

                    this.refresh();
                }

                this.createActionMenuOptions();
            })
            .catch(() => {});
    }

    public cancel = (): void => {
        this.$uibModalInstance.dismiss('cancel');
    }
    
    public goToCustomerDetail = () => {
        this.$state.go(
            'main.customerDetail',
            {
                customerId: this.bond.customerId,
                sectionView: 'bonds'
            }
        );

        this.$uibModalInstance.close(new BondDetailResult());
    }

    public createRenewalApplication = () => {
        this.busyIndicator = {
            message: 'Processing...',
            promise: this.bondService.createRenewalApplication(this.bondId)
                .then((applicationId) => {
                    this.renewalApplicationId = applicationId;
                    this.bond.renewalStatus = RenewalStatus.ApplicationSubmitted;

                    this.createActionMenuOptions();

                    return this.$timeout(() => {
                        this.activeTab = 8;
                    });
                })
            };
    }

    public loadRenewalQuotes = (): void => {
        this.renewalQuotesPromise = this.bondService.getRenewalApplicationId(this.bondId)
            .then((applicationId) => {
                this.renewalApplicationId = applicationId;
                return this.applicationService.getQuotes(applicationId);
            })
            .then((quotes) => {
                this.renewalQuotes = quotes;
            });
    }

    public loadPrincipal = (): void => {
        if (this.principalLoaded) {
            return;
        }

        this.principalPromise = this.loadPrincipalData();
    }

    public loadPrincipalData = async (): Promise<void> => {
        
        try {
            
            const principal = await this.bondService.getPrincipal(this.bondId);

            if (principal.company) {
                this.originalCompanies = [{...principal.company}];
                this.companies = [principal.company];
            } else {
                this.originalCompanies = [];
                this.companies = [];
            }

            this.originalPeople = [];
            
            for (let i = 0; i < principal.people.length; i++) {
                this.originalPeople.push({...principal.people[i]});
            }

            this.people = principal.people;
            this.principalLoaded = true;
        } catch(err) {
            this.toastMessageCreator.createErrorMessage('An error occurred while trying to the load principal information');
        }
    }

    public loadObligee = (): void => {
        if (this.obligeeLoaded) {
            return;
        }

        this.obligeePromise = this.loadObligeeData();
    }

    public loadObligeeData = async (): Promise<void> => {
    
        try {
            const obligee = await this.bondService.getObligee(this.bondId)
                
            this.originalObligee = {...obligee};
            this.obligee = obligee;
            this.obligeeLoaded = true;
        } catch(err) {
            this.toastMessageCreator.createErrorMessage('An error occurred while trying to load obligee information');
        }
    }

    public loadTransactions = (): void => {
        if (this.transactionsLoaded) {
            return;
        }

        this.transactionPromise = this.bondService.getTransactions(this.bondId)
            .then((transactions) => {
                this.transactions = transactions;
                this.transactionsLoaded = true;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to load transaction information');
            });
    }

    public showAddAttachmentModal = (): void => {
        this.modalOpener.showAttachmentModal(
            null, 
            null, 
            this.bond.customerId, 
            this.bond.id, 
            this.bond.eProducerAccountId
        )
        .result
        .then(this.refresh)
        .catch(() => {});
    }

    public submit = (): void => {
    }

    public $onInit(): void {
        this.bondId = this.modalOptions.bondId;

        this.busyIndicator = {
            message: 'Loading...',
            promise: this.loadBond(this.bondId)
        };

        this.stateChangeListener.onStateChanged(() => {
            this.$uibModalInstance.close(new BondDetailResult());
        });

        
    }
}

app.controller('BondDetailModalController', BondDetailModalController);