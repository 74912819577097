import { Injectables } from "../configuration/injectables";
import { FileDownloader } from "./fileDownloader";
import A3ApiResponse from "./types/a3ApiResponse";
import { BondAccountForDropdown } from "./types/activities/bondAccountForActivityModal";
import { SelectOption } from "./types/selectOption";
import { BondAccount } from "./types/bondAccounts/bondAccount";
import { BondAccountListItem } from "./types/bondAccounts/bondAccountListItem";
import { BondAccountStatus } from "./types/bondAccounts/bondAccountStatus";
import { BondForBondAccountAssignment } from "./types/bondAccounts/bondForBondAccountAssignment";
import app from "../main";
import { IHttpService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { BondLineReportItem } from "../states/agentSpecific/bondLines/bondLineReportItem";
import { PageResponse } from "./types/pageResponse";
import { BondLineReportFilterOptions } from "../states/agentSpecific/bondLines/bondLinesReportFilterOptions";
import { QueryBuilderTableQueryOptions } from "./types/queryBuilderTableOptions";

export class BondAccountService {
    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader
    ) {}

    public createNewBondAccount(bondAccount: BondAccount): IPromise<void> {
        return this.$http
            .post<void>(
                this.systemSettings.apiBaseUrl +
                    "BondAccountActions/CreateBondAccount",
                bondAccount
            )
            .then(() => {});
    }

    public getBondAccountListItemsByCustomerId(
        customerId: number
    ): IPromise<BondAccountListItem[]> {
        return this.$http
            .get<A3ApiResponse<BondAccountListItem[]>>(
                this.systemSettings.apiBaseUrl +
                    "BondAccountActions/GetByCustomerId?customerId=" +
                    customerId
            )
            .then((response) => response.data.value);
    }

    public getBondAccountById(bondAccountId: number): IPromise<BondAccount> {
        return this.$http
            .get<A3ApiResponse<BondAccount>>(
                this.systemSettings.apiBaseUrl +
                    "BondAccountActions/GetById?bondAccountId=" +
                    bondAccountId
            )
            .then((response) => response.data.value);
    }

    public updateBondAccount(bondAccount: BondAccount): IPromise<void> {
        return this.$http
            .put<void>(
                this.systemSettings.apiBaseUrl +
                    "BondAccountActions/UpdateBondAccount",
                bondAccount
            )
            .then((response) => {});
    }

    public getBondAccountOptionsByCustomerId(
        customerId: number,
        carrierSystemAccountId?: number
    ): IPromise<SelectOption[]> {
        return this.$http
            .get<A3ApiResponse<BondAccountForDropdown[]>>(
                this.systemSettings.apiBaseUrl +
                    "BondAccountActions/GetForDropdown?customerId=" +
                    customerId
            )
            .then((response) => {
                const bondAccounts = response.data.value.filter(
                    (bondAccount) =>
                        !carrierSystemAccountId ||
                        bondAccount.carrierSystemAccountId ===
                            carrierSystemAccountId
                );

                return bondAccounts.map((bondAccount) => {
                    let label = bondAccount.carrierName;

                    if (bondAccount.carrierAccountNumber) {
                        label += " - " + bondAccount.carrierAccountNumber;
                    }

                    if (bondAccount.status === BondAccountStatus.Cancelled) {
                        label += " - CANCELLED";
                    } else if (
                        bondAccount.status === BondAccountStatus.Suspended
                    ) {
                        label += " - SUSPENDED";
                    }

                    return {
                        label: label,
                        value: bondAccount.id,
                        isDisabled:
                            bondAccount.status !== BondAccountStatus.Active
                    };
                });
            });
    }

    public getBondsForBondAccountAssignment(
        bondIds: number[]
    ): IPromise<BondForBondAccountAssignment[]> {
        let queryString = "bondIds=";

        for (let i = 0; i < bondIds.length; i++) {
            queryString += bondIds[i];

            if (i + 1 < bondIds.length) {
                queryString += "&bondIds=";
            }
        }

        return this.$http
            .get<A3ApiResponse<BondForBondAccountAssignment[]>>(
                this.systemSettings.apiBaseUrl + "BondAccountActions/GetBondsForBondAccountAssignment?" + queryString
            )
            .then((response) => response.data.value);
    }

    public assignBondsToBondAccount(
        bondAccountId: number,
        bondIds: number[]
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "BondAccountActions/AssignBondsToBondAccount",
                { bondAccountId: bondAccountId, bondIds: bondIds }
            )
            .then(() => {});
    }

    public removeBondFromBondAccount(bondId: number): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "BondAccountActions/RemoveBondFromBondAccount?bondId=" +
                    bondId,
                {}
            )
            .then(() => {});
    }

    public getBondLineReportData(tableQueryOptions: QueryBuilderTableQueryOptions): IPromise<PageResponse<BondLineReportItem>> {
        const url = `${this.systemSettings.apiBaseUrl}BondAccountActions/GetBondLineReport`;

        return this.$http.post<A3ApiResponse<PageResponse<BondLineReportItem>>>(url, tableQueryOptions)
            .then((response) => response.data.value);
    }

    public getBondLineExcelReport(tableQueryOptions: QueryBuilderTableQueryOptions, columnNames: string[]): IPromise<PageResponse<BondLineReportItem>> {
        const url = `${this.systemSettings.apiBaseUrl}BondAccountActions/GetBondLineExcelReport`;

        return this.fileDownloader.downloadFile(url, { ...tableQueryOptions, columnNames: columnNames });
    }
}

app.service(Injectables.BondAccountService, BondAccountService);
