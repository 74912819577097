import { IWindowService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { JQueryService } from "../../utilities/jquery/jQueryService";

export type AmSelectOption<T = number> = {
    value: T,
    text?: string,
    label?: string,
    selectedText: string
}

class AmSelectController {

    public static $inject = [
        Injectables.JQueryService,
        Injectables.$window
    ];
        
    constructor(
        private readonly jQueryService: JQueryService,
        private readonly $window: IWindowService
    ) {}

    public isDropUp = false;
    public options: AmSelectOption<any>[];
    public get selectedOption(): AmSelectOption {
        return this.options.find(option => option.value == this.selectedValue);
    }

    public selectedValue: any;
    public optionChanged: (selectedOption: {selectedOption: AmSelectOption<any>}) => void;

    public selectOption = (option: AmSelectOption) => {
        if (option.value === this.selectedValue) {
            return;
        }

        this.selectedValue = option.value;
        this.optionChanged({selectedOption : option });
    }

    public toggleDropUp = ($event: any) => {

        let dropdownContainer = $event.currentTarget;
        
        let position = dropdownContainer
            .getBoundingClientRect()
            .top;
        
        let buttonHeight = dropdownContainer
            .getBoundingClientRect()
            .height;

        let dropdownMenu = this.jQueryService.getElement(dropdownContainer).find('.dropdown-menu');
        let menuHeight = dropdownMenu.outerHeight();

        let $footer = this.jQueryService.getElement('#footer');

        if (position > menuHeight && (this.$window.innerHeight - $footer.height())  - position < buttonHeight + menuHeight) {
            this.isDropUp = true;
        } else {
            this.isDropUp = false;
        }
    }

    public $onInit = () => {
    }
}

const amSelectComponent = {
    templateUrl: 'app/components/amSelectDropdown/amSelect.html',
    bindings: {
        optionChanged: '&',
        options: '=',
        selectedValue: '='
    },
    controllerAs: 'vm',
    controller: AmSelectController
};

app.component('amSelect', amSelectComponent);
