import { SystemAccountService } from "../../../api/systemAccountService";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { SearchType } from "../../../api/types/searchType";
import { BondLineReportFilterOptions } from "./bondLinesReportFilterOptions";
import { IPromise } from "angular";
import { BondAccount } from "../../../api/types/bondAccounts/bondAccount";
import { QueryBuilderTableQueryOptions } from "../../../api/types/queryBuilderTableOptions";
import { QueryBuilderTable } from "../../../utilities/tables/queryBuilderTable";
import { BondLineReportItem } from "./bondLineReportItem";

class BondLineReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(private readonly systemAccountService: SystemAccountService) {
        this.modifiedFilters = {} as QueryBuilderTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount: number;
    public modifiedFilters:  QueryBuilderTableQueryOptions;

    public processingPromise: IPromise<any>;
    public processingMessage: string;
    public carrierOptions: SelectOption<number>[];
    public bondAccount: BondAccount;

    public table: QueryBuilderTable<BondLineReportItem>;
   

    public setSearchType = (searchType: SearchType) => {
        this.modifiedFilters.searchType = searchType;
    }

    public clearFilters() {
        this.table.clearFilters();
        this.isOpen = false;
    }

    private loadCarrierOptions() {
        return this.systemAccountService
            .getAppointedCarrierOptions()
            .then((carrierOptions) => {
                this.carrierOptions = carrierOptions;
            });
    }

    public cancel() {
        this.isOpen = false;
        this.setModifiedFilters();
    }


    public applyFilters() {
        this.isOpen = false;

        this.table.applyFilters(this.modifiedFilters.searchType, this.modifiedFilters.basicFilters, this.modifiedFilters.conditionSet);
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = this.table.getFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters = this.table.getQueryOptions();
        this.setFilterCount();
    }

    public $onInit() {
        this.setModifiedFilters();
        this.table.onFilterChange = this.setModifiedFilters;
        this.loadCarrierOptions();        
    }
}

const bondLineReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: BondLineReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/states/agentSpecific/bondLines/bondLinesReportFilterDropdown.html"
};

app.component(
    "bondLineReportFilterDropdown",
    bondLineReportFilterDropdownComponent
);
