import { EProducerService } from "../../api/eProducerService";
import { EProducerAccount } from "../../api/types/model/eProducerAccount";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { State } from "../../states/state";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { BrokerDetail } from "../../states/agentSpecific/eProducerBrokerDetail/eProducerAccountsDetailController";

export class EProducerBrokerModalController {

    public static $inject = [
        Injectables.EProducerService,
        Injectables.$uibModalInstance,
        Injectables.ToastMessageCreator, 
        Injectables.$state
    ];

    constructor (
        private readonly eProducerService: EProducerService,
        private readonly $uibModalInstance: Modal<void>,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly $state: State
    ) {}

    public busyIndicator: BusyIndicator;
    public account: BrokerDetail;

    public save = () => {
        this.busyIndicator.promise = this.eProducerService.createBrokerAccount(this.account)
            .then((id) => {
                this.toastMessageCreator.createSuccessMessage('Broker account created successfully');
                this.$uibModalInstance.close();
                this.$state.go('main.eProducerAccountDetail', { id: id });
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('A problem occurred trying to save the broker account');
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
    
    public $onInit = () => {
        this.busyIndicator = {
            message: 'Saving...'
        };
    }
}

app.controller('EProducerBrokerModalController', EProducerBrokerModalController);
