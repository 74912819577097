import { IQService } from "angular";
import { BondTransactionService } from "../../api/bondTransactionService";
import { InvoiceService } from "../../api/invoiceService";
import { SystemAccountService } from "../../api/systemAccountService";
import { BillToType } from "../../api/types/billToType";
import BillingEntryType from "../../api/types/billingEntries/billingEntryType";
import BrokerCommissionCalulationParameters from "../../api/types/brokerCommissionCalulationParameters";
import { TransactionType } from "../../api/types/model/bondTransaction";
import { EProducerAccountType } from "../../api/types/model/eProducerAccount";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import isUndefinedOrNull from "../../utilities/angularUtilities/isUndefinedOrNull";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import BondTransactionBillingEntryListItem from "./bondTransactionBillingEntryListItem";
import { ModalOpener } from "../../modals/modalOpener";

class BondTransactionBillingEntryListController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.SystemAccountService,
        Injectables.InvoiceService,
        Injectables.BondTransactionService,
        Injectables.$q,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemAccountService: SystemAccountService,
        private readonly invoiceService: InvoiceService,
        private readonly bondTransactionService: BondTransactionService,
        private readonly $q: IQService,
        private readonly modalOpener: ModalOpener
    ) { 
    }

    public isCarrier: boolean;
    public eproducerAccountType: EProducerAccountType;
    public billingEntries: BondTransactionBillingEntryListItem[];
    public billingEntryTypeOptions: SelectOption<BillingEntryType>[];
    public producerUserOptions: SelectOption<number>[];  
    public defaultProducerUserId: number;
    public defaultBrokerBillToType: BillToType;
    public transactionType: TransactionType;
    public busyIndicator: BusyIndicator;
    public eproducerAccountId?: number;
    public bondTransactionId: number;

    public addBillingEntry() {
        this.billingEntries.push({
            producerUserId: this.defaultProducerUserId,
            billToType: this.defaultBrokerBillToType
        } as BondTransactionBillingEntryListItem);
    }

    public get showBrokerCommission(): boolean {
        return this.eproducerAccountType === EProducerAccountType.Broker;
    }

    public removeBillingEntry(index: number) {
        if (this.billingEntries[index].id) {
            return;
        }

        this.billingEntries.splice(index, 1);
    }

    public originalAmountInputBlurred(billingEntry: BondTransactionBillingEntryListItem) {
        this.calculateCommission(billingEntry);
        
        (this.busyIndicator.promise as Promise<void>)
            .then(() => {
                this.calculateBrokerCommission(billingEntry);
            });
    }

    public calculateBrokerCommission(billingEntry: BondTransactionBillingEntryListItem) {
        if (this.eproducerAccountType != EProducerAccountType.Broker ||
            !this.eproducerAccountId ||
            billingEntry.id || 
            billingEntry.billingEntryType != BillingEntryType.Premium ||
            !billingEntry.originalAmount || 
            !billingEntry.commission || 
            billingEntry.billToType == null ||
            billingEntry.brokerCommission
        ) {
            this.busyIndicator = { promise: this.$q.when() }
            return;
        }

        const brokerCommissionCalulationParameters: BrokerCommissionCalulationParameters = {
            premium: billingEntry.originalAmount,
            commission: billingEntry.commission,
            eProducerAccountId: this.eproducerAccountId
        };

        this.busyIndicator = {
            message: 'Calculating Broker Commission...',
            promise: this.bondTransactionService.calculateBrokerCommission(brokerCommissionCalulationParameters)
            .then((brokerCommission) => {
                billingEntry.brokerCommission = brokerCommission;
            })
        }
    }

    public calculateCommission(billingEntry: BondTransactionBillingEntryListItem)
    {
        if (billingEntry.id || 
            billingEntry.billingEntryType != BillingEntryType.Premium ||
            !billingEntry.originalAmount ||
            billingEntry.commission
        ) {
            this.busyIndicator = { promise: this.$q.when() } 
            return;
        }

        this.busyIndicator = {
            message: 'Calculating Commission...',
            promise: this.bondTransactionService.calculateNewBillingEntryCommission(this.bondTransactionId, billingEntry.originalAmount)
                .then((commission) => {
                    billingEntry.commission = commission;
                })
        };
    }

    public billintEntryTypeChanged(billingEntry: BondTransactionBillingEntryListItem) {
        billingEntry.productionCredit = billingEntry.billingEntryType == BillingEntryType.Premium;

        if (isUndefinedOrNull(billingEntry.code) && billingEntry.billingEntryType == BillingEntryType.Premium) {
            switch (this.transactionType) {
                case TransactionType.NewBusiness:
                    billingEntry.code = 'NEWB';
                    break;
                case TransactionType.Renewal:
                    billingEntry.code = 'RENB';
                    break;
                case TransactionType.Cancellation:
                    billingEntry.code = 'CANC';
                    break;
                case TransactionType.Reinstatement:
                    billingEntry.code = 'REIN';
                    break;
                case TransactionType.Rider:
                    billingEntry.code = 'ENDT';
                    break;
                default: 
                    billingEntry.code = null;
            }
        }

        if (isUndefinedOrNull(billingEntry.description) && billingEntry.billingEntryType == BillingEntryType.Premium) {
            billingEntry.description = 'Premium';
        }
    }

    public getInvoiceDownloadUrl(invoiceId: number): string {
        return this.invoiceService.getInvoiceDocumentUrl(invoiceId);
    }

    public showCreditBillingEntryAssignmentModal(billingEntryId: number) {
        this.modalOpener.showCreditBillingEntryAssignmentModal(billingEntryId);
    }

    public $onInit(): void {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;

        this.billingEntryTypeOptions = [
            { label: BillingEntryType.Premium, value: BillingEntryType.Premium },
            { label: BillingEntryType.Fee, value: BillingEntryType.Fee }
        ];

        if (!this.isCarrier) {
            this.systemAccountService.getProducerUserOptions()
                .then((producerUserOptions) => {
                    this.producerUserOptions = producerUserOptions;
                });
        }
    }
}

const bondTransactionBillingEntryListComponent = {
    bindings: {
        billingEntries: '=',
        bondTransactionId: '<',
        eproducerAccountId: '<?',
        eproducerAccountType: '<?',
        defaultProducerUserId: '<?',
        defaultBrokerBillToType: '<?',
        transactionType: '<?'
    },
    controller: BondTransactionBillingEntryListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/bondTransactionBillingEntryList/bondTransactionBillingEntryList.html',
};

app.component('bondTransactionBillingEntryList', bondTransactionBillingEntryListComponent);