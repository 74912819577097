enum QueryConditionPropertyType {
    String = 'String',
    StringExists = 'StringExists',
    PhoneExists = 'PhoneExists',
    Phone = 'Phone',
    Money = 'Money',
    Integer = 'Integer',
    Decimal = 'Decimal',
    Boolean = 'Boolean',
    Date = 'Date',
    Producer = 'Producer',
    ClientServiceExecutive = 'ClientServiceExecutive',
    ClientServiceManager = 'ClientServiceManager',
    ClientServiceAssociate = 'ClientServiceAssociate',
    Percent = 'Percent',
    BillToType = 'BillToType',
    CommissionCalculationType = 'CommissionCalculationType',
    BrokerCategory = 'BrokerCategory',
    BondAccountStatus = 'BondAccountStatus'
}

export default QueryConditionPropertyType;