import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { OpenBillingEntries } from "../../api/types/payments/paymentAssignment/openBillingEntries";
import { BillingEntryPaymentAssignment } from "../../api/types/payments/paymentAssignment/billingEntryPaymentAssignment";
import isUndefinedOrNull from "../../utilities/angularUtilities/isUndefinedOrNull";
import { BillingEntryCreditAssignmentModalResult } from "./billingEntryCreditAssignmentModalResult";
import { BillingEntryCreditAssignmentModalOptions } from "./billingEntryCreditAssignmentModalOptions";
import { BillingEntryService } from "../../api/billingEntryService";
import { CreditBillingEntryForAssignment } from "./creditBillingEntryForAssignment";
import { CreditBillingEntryAssignmentRequest } from "./creditBillingEntryAssignmentRequest";
import { BillingEntryCreditAssignment } from "./billingEntryCreditAssignment";
import { CreditBillingEntryPaymentAssignment } from "./creditBillingEntryPaymentAssignment";
import { BillToType } from "../../api/types/billToType";

export class BillingEntryCreditAssignmentModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.BillingEntries,
        Injectables.ToastMessageCreator
    ];

    public isLoaded: boolean;
    public busyIndicator: BusyIndicator;
    public creditBillingEntry: CreditBillingEntryForAssignment;
    public totalAmountApplied: number;
    public searchPhrase: string;

    constructor(
        private uibModalInstance: Modal<BillingEntryCreditAssignmentModalResult>,
        private options: BillingEntryCreditAssignmentModalOptions,
        private readonly billingEntryService: BillingEntryService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public get remainingCredit(): number {
        let brokerCommissionCreditReduction = 0;

        if (this.creditBillingEntry?.billToType == BillToType.BillBrokerNet) {
            brokerCommissionCreditReduction = this.creditBillingEntry.brokerCommission;
        }

        return Math.abs(this.creditBillingEntry?.originalAmount) - this.totalAmountApplied + brokerCommissionCreditReduction;
    }

    public get isSubmitButtonDisabled(): boolean {
        if (!this.isLoaded) {
            return true;
        }

        return this.overAppliedAmount > 0;
    }
    
    public get overAppliedAmount(): number {
        if (!this.isLoaded) {
            return 0;
        }

        return Math.abs(Math.min(this.remainingCredit, 0));
    }

    public cancel() {
        this.uibModalInstance.dismiss();
    }

    public submit() {
        const request = {
            creditBillingEntryId: this.creditBillingEntry.id,
            creditBillingEntryDescription: this.creditBillingEntry.description,
            customerId: this.creditBillingEntry.customerId,
            eProducerAccountId: this.creditBillingEntry.eProducerAccountId,
            bondTransactionId: this.creditBillingEntry.bondTransactionId,
            billingEntryIdsToDissociate: this.getBillingEntryIdsToDisassociate(),
            billingEntryCreditAssignmentsToCreate: this.getBillingEntryAssignmentsToCreate(),
            billingEntryCreditAssignmentsToUpdate: this.getExstingAssignments(),
            paymentTransactionIdsToDisassociate: this.getPaymentTransactionIdsToDisassociate(),
            paymentTransactionsAssignmentsToCreate: this.getReturnedPaymentsToCreate(),
            paymentTransactionsAssignmentsToUpdate: this.getExistingReturnedPayments()
        } as CreditBillingEntryAssignmentRequest;

        this.busyIndicator.message = "Assigning Credit...";
        this.busyIndicator.promise = this.billingEntryService
            .assignCreditBillingEntry(request)
            .then(() => {
                this.uibModalInstance.close(new BillingEntryCreditAssignmentModalResult());
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("An error occurred trying to assign this credit");
            });
    }

    public searchPhraseChanged = () => {
        this.setAppliedOpenBillingEntries();
        this.setFilteredOpenBillingEntries();
    }

    public appliedOpenBillingEntries: OpenBillingEntries[];
    public setAppliedOpenBillingEntries() {
        this.appliedOpenBillingEntries = this.creditBillingEntry.openBillingEntries.filter((billingEntry) => {
            return (
                !isUndefinedOrNull(billingEntry.amountToApply) && 
                billingEntry.amountToApply != 0 
            ) 
        });
    }

    public filteredOpenBillingEntries: OpenBillingEntries[];
    public setFilteredOpenBillingEntries() {

        if (!this.creditBillingEntry) {
            this.filteredOpenBillingEntries = [];
        } else if (!this.searchPhrase) {
            this.filteredOpenBillingEntries = this.creditBillingEntry.openBillingEntries.filter((billingEntry) => !billingEntry.amountToApply);
        } else {
            this.filteredOpenBillingEntries = this.creditBillingEntry.openBillingEntries
                .filter((billingEntry) => { 

                    if (!isUndefinedOrNull(billingEntry.amountToApply) && billingEntry.amountToApply != 0) {
                        return false;
                    }
                    
                    if (billingEntry.invoiceNumber && billingEntry.invoiceNumber.toString() == this.searchPhrase) {
                        return true;
                    }
                    
                    if (billingEntry.bondNumber && billingEntry.bondNumber == this.searchPhrase) {
                        return true;
                    }

                    return false;
            });
        }
    }

    public assignAllSearchResultsClicked = () => {
        this.filteredOpenBillingEntries.forEach(billingEntry => {
            this.amountDueClicked(billingEntry)
        });
    }

    private getBillingEntryIdsToDisassociate(): number[] {
        return this.creditBillingEntry.billingEntryCreditAssignments
            .filter((billingEntry) => !billingEntry.amount)
            .map((billingEntry) => billingEntry.billingEntryId );
    }

    private getPaymentTransactionIdsToDisassociate(): number[] {
        return this.creditBillingEntry.returnPaymentAssignments
            .filter((returnPayment) => !returnPayment.amountToApply)
            .map((returnedPayment) => returnedPayment.paymentTransactionId);
    }

    private getBillingEntryAssignmentsToCreate(): BillingEntryCreditAssignment[] {
        return this.creditBillingEntry.openBillingEntries
            .filter((billingEntry) => billingEntry.amountToApply)
            .map((billingEntry) => {
                return {
                    billingEntryId: billingEntry.billingEntryId,
                    creditBillingEntryId: this.creditBillingEntry.id,
                    amount: billingEntry.amountToApply
                } as BillingEntryCreditAssignment
            });
    }

    private getReturnedPaymentsToCreate(): CreditBillingEntryPaymentAssignment[] {
        return this.creditBillingEntry.unappliedReturnPayments
            .filter((returnPayment) => returnPayment.amountToApply);
    }

    private getExstingAssignments(): BillingEntryCreditAssignment[] {
        return this.creditBillingEntry.billingEntryCreditAssignments
            .filter((billingEntry) => !isUndefinedOrNull(billingEntry.amount) && billingEntry.amount != 0)
            .map((billingEntry) => {
                return {
                    id: billingEntry.id,
                    billingEntryId: billingEntry.billingEntryId,
                    creditBillingEntryId: this.creditBillingEntry.id,
                    amount: billingEntry.amount,
                    invoiceLineItemId: billingEntry.invoiceLineItemId
                } as BillingEntryCreditAssignment
            });
    }

    private getExistingReturnedPayments(): CreditBillingEntryPaymentAssignment[] {
        return this.creditBillingEntry.returnPaymentAssignments
            .filter((returnedPayment) => !isUndefinedOrNull(returnedPayment.amountToApply) && returnedPayment.amountToApply != 0);
    }

    private loadCreditBillingEntry(creditBillingEntryId: number) {
        this.busyIndicator.message = "Loading Credit...";
        this.busyIndicator.promise = this.billingEntryService
            .getCreditBillingEntryForAssignment(creditBillingEntryId)
            .then((creditBillingEntry) => {
                this.creditBillingEntry = creditBillingEntry;

                this.setTotalAmountApplied();
                this.setFilteredOpenBillingEntries();
                this.isLoaded = true;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("An error occurred trying to load the credit billing entry");
            });
    }

    public amountClicked(billingEntryPaymentAssignment: BillingEntryPaymentAssignment) {

        if (billingEntryPaymentAssignment.amount) {
            billingEntryPaymentAssignment.amount = null;
        } else {
            billingEntryPaymentAssignment.amount = billingEntryPaymentAssignment.amountDue;
        }
        
        this.setTotalAmountApplied();
    }

    public amountDueClicked(openBillingEntry: OpenBillingEntries) {

        if (openBillingEntry.amountToApply) {
            openBillingEntry.amountToApply = null;
        } else {
            openBillingEntry.amountToApply = openBillingEntry.amountDue;
        }
        
        this.setTotalAmountApplied();
    }

    public paymentAmountDueClicked(returnPayment: CreditBillingEntryPaymentAssignment) {

        if (returnPayment.amountToApply) {
            returnPayment.amountToApply = null;
        } else {
            returnPayment.amountToApply = returnPayment.unappliedAmount;
        }

        this.setTotalAmountApplied();
    }

    public setTotalAmountApplied() {
        this.totalAmountApplied = 0;

        for (let i = 0; i < this.creditBillingEntry.openBillingEntries.length; i++) {
            if (isUndefinedOrNull(this.creditBillingEntry.openBillingEntries[i].amountToApply)) {
                continue;
            }

            this.totalAmountApplied += Math.abs(this.creditBillingEntry.openBillingEntries[i].amountToApply);
        }

        for (let i = 0; i < this.creditBillingEntry.billingEntryCreditAssignments.length; i++) {
            this.totalAmountApplied += Math.abs(this.creditBillingEntry.billingEntryCreditAssignments[i].amount);
        }

        for (let i = 0; i < this.creditBillingEntry.unappliedReturnPayments.length; i++) {
            if (isUndefinedOrNull(this.creditBillingEntry.unappliedReturnPayments[i].amountToApply)) {
                continue;
            }

            this.totalAmountApplied += Math.abs(this.creditBillingEntry.unappliedReturnPayments[i].amountToApply);
        }

        for (let i = 0; i < this.creditBillingEntry.returnPaymentAssignments.length; i++) {
            this.totalAmountApplied += Math.abs(this.creditBillingEntry.returnPaymentAssignments[i].amountToApply);
        }

        this.totalAmountApplied = +this.totalAmountApplied.toFixed(2);
    }

    $onInit(): void {
        this.busyIndicator = { message: 'Loading...' };

        this.loadCreditBillingEntry(this.options.creditBillingEntryId);
    }
}

app.controller("BillingEntryCreditAssignmentModalController", BillingEntryCreditAssignmentModalController);
